import instance from "../api/axios";
import { ApiRequest } from "../types/requests/ApiRequest";
import { ApiResponse } from "../types/responses/ApiResponse";
import { ChangePasswordRequest } from "../types/requests/ChangePasswordRequest";
import { ChangePasswordResponse } from "../types/responses/ChangePasswordResponse";
import { CheckPasswordRequest } from "../types/requests/CheckPasswordRequest";
import { CheckPasswordResponse } from "../types/responses/CheckPasswordResponse";
import { UpdateProfileRequest } from "../types/requests/UpdateProfileRequest";
import { UpdateProfileResponse } from "../types/responses/UpdateProfileResponse";
import { UpdateFirstNameResponse } from "../types/responses/UpdateFirstNameResponse";
import { UpdateFirstNameRequest } from "../types/requests/UpdateFirstNameRequest";
import { UpdateLastNameRequest } from "../types/requests/UpdateLastNameRequest";
import { UpdateLastNameResponse } from "../types/responses/UpdateLastNameResponse";
import { GetAccountInfoRequest } from "../types/requests/GetAccountInfoRequest";
import { GetAccountInfoResponse } from "../types/responses/GetAccountInfoResponse";
import { AddCardRequest } from "../types/requests/AddCardRequest";
import { AddCardResponse } from "../types/responses/AddCardResponse";
import { CancelAutoPaymentRequest } from "../types/requests/CancelAutoPaymentRequest";
import { CancelAutoPaymentResponse } from "../types/responses/CancelAutoPaymentResponse";
import { EnableAutoPaymentRequest } from "../types/requests/EnableAutoPaymentRequest";
import { EnableAutoPaymentResponse } from "../types/responses/EnableAutoPaymentResponse";
import { RemoveCardRequest } from "../types/requests/RemoveCardRequest";
import { RemoveCardResponse } from "../types/responses/RemoveCardResponse";
import { DeleteAccountRequest } from "../types/requests/DeleteAccountRequest";
import { DeleteAccountResponse } from "../types/responses/DeleteAccountResponse";
import { GetActivationDateRequest } from "../types/requests/GetActivationDateRequest";
import { GetActivationDateResponse } from "../types/responses/GetActivationDateResponse";

export const profileService = {
    getAccountInfo: async (request: ApiRequest<GetAccountInfoRequest>): Promise<ApiResponse<GetAccountInfoResponse>> => await instance.post<ApiRequest<GetAccountInfoRequest>, ApiResponse<GetAccountInfoResponse>>('/profile/get_account_info', request),
    checkPassword: async (request: ApiRequest<CheckPasswordRequest>): Promise<ApiResponse<CheckPasswordResponse>> => await instance.post<ApiRequest<CheckPasswordRequest>, ApiResponse<CheckPasswordResponse>>('/profile/check_password', request),
    changePassword: async (request: ApiRequest<ChangePasswordRequest>): Promise<ApiResponse<ChangePasswordResponse>> => await instance.post<ApiRequest<ChangePasswordRequest>, ApiResponse<ChangePasswordResponse>>('/profile/change_password', request),
    updateProfile: async (request: ApiRequest<UpdateProfileRequest>): Promise<ApiResponse<UpdateProfileResponse>> => await instance.post<ApiRequest<UpdateProfileRequest>, ApiResponse<UpdateProfileResponse>>('/profile/update_profile', request),
    updateFirstName: async (request: ApiRequest<UpdateFirstNameRequest>): Promise<ApiResponse<UpdateFirstNameResponse>> => await instance.post<ApiRequest<UpdateFirstNameRequest>, ApiResponse<UpdateFirstNameResponse>>('/profile/update_first_name', request),
    updateLastName: async (request: ApiRequest<UpdateLastNameRequest>): Promise<ApiResponse<UpdateLastNameResponse>> => await instance.post<ApiRequest<UpdateLastNameRequest>, ApiResponse<UpdateLastNameResponse>>('/profile/update_last_name', request),
    addCard: async (request: ApiRequest<AddCardRequest>): Promise<ApiResponse<AddCardResponse>> => await instance.post<ApiRequest<AddCardRequest>, ApiResponse<AddCardResponse>>('/profile/add_card', request),
    removeCard: async (request: ApiRequest<RemoveCardRequest>): Promise<ApiResponse<RemoveCardResponse>> => await instance.post<ApiRequest<RemoveCardRequest>, ApiResponse<RemoveCardResponse>>('/profile/remove_card', request),
    cancelAutoPayment: async (request: ApiRequest<CancelAutoPaymentRequest>): Promise<ApiResponse<CancelAutoPaymentResponse>> => await instance.post<ApiRequest<CancelAutoPaymentResponse>, ApiResponse<CancelAutoPaymentResponse>>('/profile/cancel_auto_payment', request),
    enableAutoPayment: async (request: ApiRequest<EnableAutoPaymentRequest>): Promise<ApiResponse<EnableAutoPaymentResponse>> => await instance.post<ApiRequest<EnableAutoPaymentRequest>, ApiResponse<EnableAutoPaymentResponse>>('/profile/enable_auto_payment', request),
    deleteAccount: async (request: ApiRequest<DeleteAccountRequest>): Promise<ApiResponse<DeleteAccountResponse>> => await instance.post<ApiRequest<DeleteAccountRequest>, ApiResponse<DeleteAccountResponse>>('/profile/delete_account', request),
    getActivationDate: async (request: ApiRequest<GetActivationDateRequest>): Promise<ApiResponse<GetActivationDateResponse>> => await instance.post<ApiRequest<GetActivationDateRequest>, ApiResponse<GetActivationDateResponse>>('/profile/get_activation_date', request),
};