import { Text, View } from 'react-native';
import Colors from '../constants/Colors';
import CommonStyles from '../constants/CommonStyles';
import Sizes from '../constants/Sizes';
import FontFamilys from '../constants/FontFamilys';
import { AppButton } from './AppButton';
import { AppCard } from './AppCard';
import { AppGap } from './AppGap';
import { AppAlert } from './AppAlert';
import { profileService } from '../services/ProfileService';
import { useDispatch, useSelector } from 'react-redux';
import { setAutoPayment, setPaymentMethodInfo } from '../store/user';
import { RootState } from '../store';
import { AppCreditCard } from './AppCreditCard';
import { useState } from 'react';

type AccountSavedCreditCardProps = {
}

export function AccountSavedCreditCard(props: AccountSavedCreditCardProps) {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  const [autoPaymentError, setAutoPaymentError] = useState('');
  const [savingAutoPayment, setSavingAutoPayment] = useState(false);

  const [removeCardError, setRemoveCardError] = useState('');
  const [savingRemoveCard, setSavingRemoveCard] = useState(false);

  const removeCard = async () => {
    setRemoveCardError('');
    setSavingRemoveCard(true);
    var response = await profileService.removeCard({ data: { customer_id: user.selectedAccount.customer_info.customer_id } });
    if (!response || !response.success) {
      setRemoveCardError(response.error.message);
      setSavingRemoveCard(false);
      console.error(response.error);
      return;
    }
    dispatch(setAutoPayment(false));
    dispatch(setPaymentMethodInfo(undefined))
    setSavingRemoveCard(false);
    return;
  }
  // const toggleAutoPayment = async () => {
  //   setAutoPaymentError('');
  //   setSavingAutoPayment(true);
  //   if (user.selectedAccount.customer_info.auto_payment) {
  //     var response = await profileService.cancelAutoPayment({ data: { customer_id: user.selectedAccount.customer_info.customer_id } });
  //     if (!response || !response.success) {
  //       setAutoPaymentError(response.error.message);
  //       setSavingAutoPayment(false);
  //       console.error(response.error);
  //       return;
  //     }
  //     dispatch(setAutoPayment(false));
  //     setSavingAutoPayment(false);
  //     return;
  //   }

  //   if (!user.selectedAccount.customer_info.auto_payment) {
  //     var response = await profileService.enableAutoPayment({ data: { customer_id: user.selectedAccount.customer_info.customer_id } });
  //     if (!response || !response.success) {
  //       setAutoPaymentError(response.error.message);
  //       setSavingAutoPayment(false);
  //       console.error(response.error);
  //       return;
  //     }
  //     dispatch(setAutoPayment(true));
  //     setSavingAutoPayment(false);
  //     return;
  //   }
  // }
  return (
    <>
      {user.selectedAccount.customer_info.payment_method_info &&
        <AppCard>
          <View style={CommonStyles.cardBody}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Text style={{ fontFamily: FontFamilys.bold }}>Saved Card</Text>
              <View style={{ justifyContent: 'flex-end' }}>
                <Text style={{ fontFamily: FontFamilys.bold }}>Auto-Payment</Text>
                <Text style={{ fontFamily: FontFamilys.regular, color: user.selectedAccount.customer_info.auto_payment ? Colors.green : Colors.red, textAlign: 'right' }}>{user.selectedAccount.customer_info.auto_payment ? 'enabled' : 'disabled'}</Text>
              </View>
            </View>
            <AppGap direction="vertical" size={20}></AppGap>
            <AppCreditCard cardHolderName={user.selectedAccount.customer_info.payment_method_info.card_holder_name} expiryMonth={user.selectedAccount.customer_info.payment_method_info.expiry_month} expiryYear={user.selectedAccount.customer_info.payment_method_info.expiry_year} maskedCardNumber={user.selectedAccount.customer_info.payment_method_info.masked_card_number} type={user.selectedAccount.customer_info.payment_method_info.card_type}></AppCreditCard>
            <AppGap direction="vertical" size={20}></AppGap>
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
              <AppButton text="Remove Card" color={Colors.red} onPress={removeCard} loading={savingRemoveCard}></AppButton>
              {/* <AppButton text={user.selectedAccount.customer_info.auto_payment ? 'Cancel Auto Payment' : 'Enable Auto Payment'} color={user.selectedAccount.customer_info.auto_payment ? Colors.red : Colors.blue} onPress={toggleAutoPayment} loading={savingAutoPayment}></AppButton> */}
            </View>
            {removeCardError.length > 0 &&
              <View style={{ marginTop: Sizes.medium }}>
                <AppAlert text={removeCardError}></AppAlert>
              </View>
            }
            {/* {autoPaymentError.length > 0 &&
              <View style={{ marginTop: Sizes.medium }}>
                <AppAlert text={autoPaymentError}></AppAlert>
              </View>
            } */}
          </View>
        </AppCard>
      }
    </>
  );
}
