import instance from "../api/axios";
import { ApiRequest } from "../types/requests/ApiRequest";
import { ApiResponse } from "../types/responses/ApiResponse";
import { GetDefaultPlansRequest } from "../types/requests/GetDefaultPlansRequest";
import { GetDefaultPlansResponse } from "../types/responses/GetDefaultPlansResponse";
import { IntlCallingAddOn } from "../types/models/IntlCallingAddOn";

export const planService = {
  getDefaultPlans: async (
    request: ApiRequest<GetDefaultPlansRequest>
  ): Promise<ApiResponse<GetDefaultPlansResponse>> =>
    await instance.post<
      ApiRequest<GetDefaultPlansRequest>,
      ApiResponse<GetDefaultPlansResponse>
    >("/Plans/get_default_plans", request),
  getIntlCallingAddons: async (): Promise<
    ApiResponse<{
      has_default_plans: boolean;
      main_i_product: any;
      product_list: IntlCallingAddOn[];
    }>
  > => await instance.post("/Plans/get_intCall_addons"),
};
