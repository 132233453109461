import { useState } from 'react';
import { ScrollView, View, Text } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { AppAlert } from '../components/AppAlert';
import { AppButton } from '../components/AppButton';
import { AppTextInput } from '../components/AppTextInput';
import Colors from '../constants/Colors';
import FlexStyles from '../constants/FlexStyles';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import { profileService } from '../services/ProfileService';
import { RootState, AppDispatch } from '../store';
import { setFirstName } from '../store/user';
import { RootStackScreenProps } from '../types';

export default function ProfileUpdateFirstNameScreen({ navigation }: RootStackScreenProps<'ProfileUpdateFirstName'>) {

  const user = useSelector((state: RootState) => state.user);
  const [first, setFirst] = useState(user.selectedAccount.first_name);
  const [firstNameError, setFirstNameError] = useState('');
  const [savingFirstName, setSavingFirstName] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const updateFirstName = async () => {
    setFirstNameError('');
    setSavingFirstName(true);
    var response = await profileService.updateFirstName({ data: { first_name: first } });
    if (!response || !response.success) {
      setFirstNameError(response.error.message);
      setSavingFirstName(false);
      console.error(response.error);
      return;
    }
    setFirstNameError('');
    setSavingFirstName(false);
    dispatch(setFirstName(first));
    navigation.goBack();
  }

  return (
    <ScrollView>
      <View style={{ ...PageLayoutStyles.container, paddingTop: Sizes.none }}>
        <Text style={{ ...TextStyles.title, color: Colors.white }}>Profile</Text>
        <Text style={{ ...TextStyles.subtitle, color: Colors.white, marginTop: Sizes.large, marginBottom: Sizes.small }}>First Name</Text>
        <AppTextInput value={first} placeholder="First Name" onChangeText={(text) => setFirst(text.trim())}></AppTextInput>
        <View style={{ ...FlexStyles.rowCenter, marginTop: Sizes.large }}>
          <AppButton text="Update First Name" onPress={updateFirstName} color={Colors.white} outline loading={savingFirstName}></AppButton>
        </View>
        {firstNameError.length > 0 &&
          <View style={{ marginTop: Sizes.medium }}>
            <AppAlert text={firstNameError}></AppAlert>
          </View>
        }
      </View>
    </ScrollView>
  );
}
