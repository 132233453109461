import axios from "axios";

//@ts-ignore
// TODO: Figure out a way to type this from the dotenv library
import { BASE_API_URL } from "@env";
import { Platform } from "react-native";

let baseUrl = BASE_API_URL;
if (Platform.OS === "web") {
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "icy-flower-0aadeb51e.2.azurestaticapps.net" ||
    /^icy-flower.*azurestaticapps\.net$/.test(window.location.hostname)
  ) {
    baseUrl = "https://phoneboxproxy.azurewebsites.net";
  }
} else {
  if (__DEV__) {
    baseUrl = "https://phoneboxproxy.azurewebsites.net";
  }
}

const instance = axios.create({ baseURL: baseUrl });

export default instance;
