import instance from "../api/axios";
import { ApiRequest } from "../types/requests/ApiRequest";
import { ApiResponse } from "../types/responses/ApiResponse";
import { ValidateEmailRequest } from "../types/requests/ValidateEmailRequest";
import { ValidateEmailResponse } from "../types/responses/ValidateEmailResponse";
import { SignUpRequest } from "../types/requests/SignUpRequest";
import { SignUpResponse } from "../types/responses/SignUpResponse";
import { VerifyAccountRequest } from "../types/requests/VerifyAccountRequest";
import { VerifyAccountResponse } from "../types/responses/VerifyAccountResponse";

export const signUpService = {
    validateEmail: async (request: ApiRequest<ValidateEmailRequest>): Promise<ApiResponse<ValidateEmailResponse>> => await instance.post<ApiRequest<ValidateEmailRequest>, ApiResponse<ValidateEmailResponse>>('/signup/validate_email', request),
    signUp: async (request: ApiRequest<SignUpRequest>): Promise<ApiResponse<SignUpResponse>> => await instance.post<ApiRequest<SignUpRequest>, ApiResponse<SignUpResponse>>('/signup', request),
    verifyAccount: async (request: ApiRequest<VerifyAccountRequest>): Promise<ApiResponse<VerifyAccountResponse>> => await instance.post<ApiRequest<VerifyAccountRequest>, ApiResponse<VerifyAccountResponse>>('/signup/verify_account', request),
};