import { FontAwesome } from '@expo/vector-icons';
import { useEffect, useState } from 'react';
import { ScrollView, View, Text, Pressable, SafeAreaView } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { AccountAddCreditCard } from '../components/AccountAddCreditCard';
import { AccountSavedCreditCard } from '../components/AccountSavedCreditCard';
import { AppAlert } from '../components/AppAlert';
import { AppButton } from '../components/AppButton';
import { AppFooter } from '../components/AppFooter';
import { AppGap } from '../components/AppGap';
import { AppHeader } from '../components/AppHeader';
import { AppModal } from '../components/AppModal';
import { AppSwitch } from '../components/AppSwitch';
import { AppTextInput } from '../components/AppTextInput';
import { ConnectWithUsCard } from '../components/ConnectWithUsCard';
import Colors from '../constants/Colors';
import CommonStyles from '../constants/CommonStyles';
import FontFamilys from '../constants/FontFamilys';
import Heights from '../constants/Heights';
import IconSizes from '../constants/IconSizes';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import useHorizontalContainerMargin from '../hooks/useHorizontalContainerMargin';
import useIsMobile from '../hooks/useIsMobile';
import { profileService } from '../services/ProfileService';
import { tokenService } from '../services/TokenService';
import { AppDispatch, RootState } from '../store';
import { setFirstName, setLastName, setLoggedIn } from '../store/user';
import { RootTabScreenProps } from '../types';

export default function ProfileScreen({ navigation }: RootTabScreenProps<'Profile'>) {
  const user = useSelector((state: RootState) => state.user);
  const isMobile = useIsMobile();
  const marginHorizontal = useHorizontalContainerMargin();

  const [editingProfile, setEditingProfile] = useState(false);
  const [profile, setProfile] = useState({ firstName: user.selectedAccount.first_name, lastName: user.selectedAccount.last_name });
  const [profileError, setProfileError] = useState('');
  const [savingProfile, setSavingProfile] = useState(false);

  const [editingPassword, setEditingPassword] = useState(false);
  const [password, setPassword] = useState({ currentPassword: '', newPassword: '', newConfirmPassword: '', showPassword: false });
  const [passwordError, setPasswordError] = useState('');
  const [savingPassword, setSavingPassword] = useState(false);

  const [deleteAccountModalVisible, setDeleteAccountModalVisible] = useState(false);
  const [deleteAccountError, setDeleteAccountError] = useState('');
  const [deletingAccount, setDeletingAccount] = useState(false);

  const is7daysPlan = user.selectedAccount.plan.term_days === 7;
  const planSuspended = user.selectedAccount.plan.end_date ? false : true;
  console.log(planSuspended, " = plan suspended: ")

  // TODO: Remove this, it is only for testing.
  const [allowExtension, setAllowExtension] = useState(false);
  const now = new Date();
  let planEndDate = new Date(user.selectedAccount.expiry_date);
  const planExpired = now > planEndDate;

  // If it's more than 30 days before the term's end date,
  // we can assume that extension is either not allowed or the user has set-up autopay.
  // TODO: Remove this, it is only for testing.
  const checkAllowExtension = () => {
    let thirtyDaysBeforeEndDate = new Date(planEndDate.toLocaleDateString('en-US'));
    thirtyDaysBeforeEndDate.setDate(thirtyDaysBeforeEndDate.getDate() - 30);
    let now = new Date();
    if (thirtyDaysBeforeEndDate < now) {
      setAllowExtension(true);
    }
  }

  // TODO: Remove this, it is only for testing.
  useEffect(() => {
    checkAllowExtension();
  }, []);

  const handleEditProfile = async () => {
    if (!editingProfile) {
      setEditingProfile(true);
      return;
    }

    setProfileError('');
    setSavingProfile(true);
    var response = await profileService.updateProfile({ data: { first_name: profile.firstName, last_name: profile.lastName } });
    if (!response || !response.success) {
      setProfileError(response.error.message);
      setSavingProfile(false);
      console.error(response.error);
      return;
    }
    dispatch(setFirstName(profile.firstName));
    dispatch(setLastName(profile.lastName));
    setEditingProfile(false);
    setSavingProfile(false);
  }


  const handleEditPassword = async () => {
    if (!editingPassword) {
      setEditingPassword(true);
      return;
    }

    setPasswordError('');
    setSavingPassword(true);
    var response = await profileService.changePassword({ data: { current_password: password.currentPassword, new_password: password.newPassword, new_confirm_password: password.newConfirmPassword } });
    if (!response || !response.success) {
      setPasswordError(response.error.message);
      setSavingPassword(false);
      console.error(response.error);
      return;
    }

    setEditingPassword(false);
    setSavingPassword(false);
  }

  const dispatch = useDispatch<AppDispatch>();
  const handleLogout = () => {
    const logout = async () => {
      await tokenService.deleteAccessTokenAsync();
      await tokenService.deleteAccessTokenExpiryAsync();
      await tokenService.deleteRefreshTokenAsync();
      await tokenService.deleteRefreshTokenExpiryAsync();
      dispatch(setLoggedIn(false));
    }
    logout().catch(err => console.error(err));
  };

  const deleteAccount = async () => {
    setDeleteAccountError('');
    setDeletingAccount(true);
    var response = await profileService.deleteAccount({ data: { customer_id: user.selectedAccount.customer_info.customer_id } });
    if (!response || !response.success) {
      setDeleteAccountError(response.error.message);
      setDeletingAccount(false);
      console.error(response.error);
      return;
    }
    handleLogout();
    setDeleteAccountError('');
    setDeletingAccount(false);
  };

  return (
    <>
      {!isMobile &&
        <>
          <AppHeader></AppHeader>
          <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.mainPagePaddingTop }}>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginHorizontal: marginHorizontal }}>
              <View style={{ flex: 1 }}>
                <View style={{ marginRight: 20 }}>
                  <Text style={{ ...TextStyles.heading, color: Colors.black, marginTop: Sizes.large, marginBottom: Sizes.medium }}>My Profile</Text>
                  <View style={{ ...CommonStyles.card, borderColor: Colors.green, borderWidth: 1, borderRadius: 10, backgroundColor: Colors.white }}>
                    {editingProfile &&
                      <>
                        <Pressable onPress={() => { setEditingProfile(false); setProfileError(''); }} style={{ position: 'absolute', right: 0, top: 0, marginTop: 10, marginRight: 10 }}>
                          <View style={{ width: 20, height: 20, backgroundColor: Colors.white, borderRadius: 20, borderColor: Colors.grey, borderWidth: 1, justifyContent: 'center', alignItems: 'center' }} >
                            <FontAwesome name="close" size={12} color={Colors.grey}></FontAwesome>
                          </View>
                        </Pressable>
                        <Text style={{ ...TextStyles.paragraph, color: Colors.black, marginBottom: Sizes.small }}>First Name</Text>
                        <AppTextInput placeholder="First Name" value={profile.firstName} onChangeText={(text) => setProfile({ ...profile, firstName: text.trim() })}></AppTextInput>
                        <Text style={{ ...TextStyles.paragraph, color: Colors.black, marginTop: Sizes.large, marginBottom: Sizes.small }}>Last Name</Text>
                        <AppTextInput placeholder="Last Name" value={profile.lastName} onChangeText={(text) => setProfile({ ...profile, lastName: text.trim() })}></AppTextInput>
                      </>
                    }
                    {!editingProfile &&
                      <>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <Text style={{ ...TextStyles.paragraph, color: Colors.black, fontFamily: FontFamilys.bold, marginRight: 30 }}>Name</Text>
                          <Text style={{ ...TextStyles.paragraph, color: Colors.black }}>{`${user.selectedAccount.first_name} ${user.selectedAccount.last_name}`}</Text>
                        </View>
                        <AppGap direction='vertical' size={15}></AppGap>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <Text style={{ ...TextStyles.paragraph, color: Colors.black, fontFamily: FontFamilys.bold, marginRight: 30 }}>Phone</Text>
                          <Text style={{ ...TextStyles.paragraph, color: Colors.black }}>{user.selectedAccount.phone_number}</Text>
                        </View>
                        <AppGap direction='vertical' size={15}></AppGap>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <Text style={{ ...TextStyles.paragraph, color: Colors.black, fontFamily: FontFamilys.bold, marginRight: 30 }}>Email</Text>
                          <Text style={{ ...TextStyles.paragraph, color: Colors.black }}>{user.selectedAccount.email}</Text>
                        </View>
                      </>
                    }
                    <AppGap direction='vertical' size={15}></AppGap>
                    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                      <AppButton text={editingProfile ? 'Save' : 'Edit'} color={Colors.blue} onPress={handleEditProfile} loading={savingProfile}></AppButton>
                    </View>
                    {profileError.length > 0 &&
                      <View style={{ marginTop: Sizes.medium }}>
                        <AppAlert text={profileError}></AppAlert>
                      </View>
                    }
                    {!editingProfile &&
                      <>
                        <Pressable onPress={() => setDeleteAccountModalVisible(true)} style={{ marginTop: Sizes.medium }}>
                          <Text style={{ textAlign: 'center', color: Colors.red, fontFamily: FontFamilys.regular }}>Delete My Account</Text>
                        </Pressable>
                        <AppModal onClose={() => setDeleteAccountModalVisible(false)} visible={deleteAccountModalVisible} headerTitle="Delete My Account" width={isMobile ? '' : '40%'}>
                          <Text style={{ ...TextStyles.paragraph }}>Are you sure you want to delete your account?</Text>
                          <Text style={{ ...TextStyles.paragraph, marginTop: Sizes.medium }}>This action will also cancel any auto payment you have set up and remove any saved credit card information. You will need to set up a new account if you wish to use our services in the future.</Text>
                          <Text style={{ ...TextStyles.paragraph, marginTop: Sizes.medium }}>Please note that removing your account will not refund any unused balances or payments. If you have any concerns or questions, please contact our customer support team for assistance.</Text>
                          <Text style={{ ...TextStyles.paragraph, color: Colors.red, marginTop: Sizes.medium }}>This action is irreversible and all of your data will be deleted from our system.</Text>
                          <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: Sizes.large }}>
                            <AppButton text="Confirm" color={Colors.red} onPress={deleteAccount} loading={deletingAccount}></AppButton>
                          </View>
                          {deleteAccountError.length > 0 &&
                            <View style={{ marginTop: Sizes.medium }}>
                              <AppAlert text={deleteAccountError}></AppAlert>
                            </View>
                          }
                        </AppModal>
                      </>
                    }
                  </View>
                </View>
                <View style={{ marginRight: 20 }}>
                  <Text style={{ ...TextStyles.heading, color: Colors.black, marginTop: Sizes.large, marginBottom: Sizes.medium }}>Connect With Us</Text>
                  <ConnectWithUsCard />
                </View>
              </View>
              <View style={{ flex: 1 }}>
                <View style={{ marginLeft: 20 }}>
                  <Text style={{ ...TextStyles.heading, color: Colors.black, marginTop: Sizes.large, marginBottom: Sizes.medium }}>Password</Text>
                  <View style={{ ...CommonStyles.card, borderColor: Colors.green, borderWidth: 1, borderRadius: 10, backgroundColor: Colors.white }}>
                    {editingPassword &&
                      <>
                        <Pressable onPress={() => { setEditingPassword(false); setPasswordError(''); }} style={{ position: 'absolute', right: 0, top: 0, marginTop: 10, marginRight: 10 }}>
                          <View style={{ width: 20, height: 20, backgroundColor: Colors.white, borderRadius: 20, borderColor: Colors.grey, borderWidth: 1, justifyContent: 'center', alignItems: 'center' }} >
                            <FontAwesome name="close" size={12} color={Colors.grey}></FontAwesome>
                          </View>
                        </Pressable>
                        <Text style={{ ...TextStyles.paragraph, color: Colors.black, marginBottom: Sizes.small }}>Current Password</Text>
                        <AppTextInput placeholder="Current Password" onChangeText={(text) => setPassword({ ...password, currentPassword: text })} secureTextEntry={!password.showPassword}></AppTextInput>
                        <Text style={{ ...TextStyles.paragraph, color: Colors.black, marginTop: Sizes.large, marginBottom: Sizes.small }}>New Password</Text>
                        <AppTextInput placeholder="New Password" onChangeText={(text) => setPassword({ ...password, newPassword: text })} secureTextEntry={!password.showPassword}></AppTextInput>
                        <Text style={{ ...TextStyles.paragraph, color: Colors.black, marginTop: Sizes.large, marginBottom: Sizes.small }}>Confirm New Password</Text>
                        <AppTextInput placeholder="Confirm New Password" onChangeText={(text) => setPassword({ ...password, newConfirmPassword: text })} secureTextEntry={!password.showPassword}></AppTextInput>
                        <AppGap direction='vertical' size={15}></AppGap>
                        <AppSwitch text="Show Password" onToggle={(toggled) => setPassword({ ...password, showPassword: toggled })}></AppSwitch>
                      </>
                    }
                    {!editingPassword &&
                      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={{ ...TextStyles.paragraph, color: Colors.black, fontFamily: FontFamilys.bold, marginRight: 30 }}>Password</Text>
                        <Text style={{ ...TextStyles.paragraph, color: Colors.black }}>**********</Text>
                      </View>
                    }
                    <AppGap direction='vertical' size={15}></AppGap>
                    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                      <AppButton text={editingPassword ? 'Confirm' : 'Edit'} color={Colors.blue} onPress={handleEditPassword} loading={savingPassword}></AppButton>
                    </View>
                    {passwordError.length > 0 &&
                      <View style={{ marginTop: Sizes.medium }}>
                        <AppAlert text={passwordError}></AppAlert>
                      </View>
                    }
                  </View>
                </View>
                {(!planExpired && !is7daysPlan && !planSuspended) &&
                  <View style={{ marginLeft: 20 }}>
                    <Text style={{ ...TextStyles.heading, color: Colors.black, marginTop: Sizes.large, marginBottom: Sizes.medium }}>Auto Extension</Text>
                    {!user.selectedAccount.customer_info.payment_method_info && <AccountAddCreditCard />}
                    {user.selectedAccount.customer_info.payment_method_info && <AccountSavedCreditCard />}
                  </View>
                }
              </View>
            </View>
          </ScrollView>
          <AppFooter></AppFooter>
        </>
      }
      {isMobile &&
        <SafeAreaView style={{ backgroundColor: isMobile ? Colors.green : Colors.offwhite, flex: 1 }}>
          <ScrollView style={{ backgroundColor: isMobile ? Colors.green : Colors.offwhite }}>
            <View style={{ ...PageLayoutStyles.container, backgroundColor: Colors.green }}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: Sizes.large }}>
                <Text style={{ ...TextStyles.title, color: Colors.white }}>Profile</Text>
                <Pressable onPress={handleLogout}>
                  <View style={{ justifyContent: 'center', flexDirection: 'column', alignItems: "center" }}>
                    <FontAwesome name="sign-out" size={IconSizes.large} color={Colors.white}></FontAwesome>
                    <Text style={{ fontFamily: FontFamilys.regular, color: Colors.white }}>Log Out</Text>
                  </View>
                </Pressable>
              </View>
              <View style={{ ...CommonStyles.card, padding: 0 }}>
                <Pressable onPress={() => navigation.navigate('ProfileUpdateFirstName')}>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: Sizes.large, paddingVertical: 15 }}>
                    <View>
                      <Text style={{ ...TextStyles.paragraph, color: Colors.grey }}>First Name</Text>
                      <Text style={{ fontFamily: FontFamilys.regular, color: Colors.black }}>{user.selectedAccount.first_name}</Text>
                    </View>
                    <FontAwesome name="angle-right" size={IconSizes.large} color={Colors.green}></FontAwesome>
                  </View>
                </Pressable>
                <Pressable onPress={() => navigation.navigate('ProfileUpdateLastName')}>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: Sizes.large, paddingVertical: 15, borderTopColor: 'lightgrey', borderTopWidth: 1 }}>
                    <View>
                      <Text style={{ ...TextStyles.paragraph, color: Colors.grey }}>Last Name</Text>
                      <Text style={{ fontFamily: FontFamilys.regular, color: Colors.black }}>{user.selectedAccount.last_name}</Text>
                    </View>
                    <FontAwesome name="angle-right" size={IconSizes.large} color={Colors.green}></FontAwesome>
                  </View>
                </Pressable>
                {/* <Pressable onPress={() => navigation.navigate('ProfileUpdateEmail')}> */}
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: Sizes.large, paddingVertical: 15, borderTopColor: 'lightgrey', borderTopWidth: 1 }}>
                  <View>
                    <Text style={{ ...TextStyles.paragraph, color: Colors.grey }}>Email</Text>
                    <Text style={{ fontFamily: FontFamilys.regular, color: Colors.black }}>{user.selectedAccount.email}</Text>
                  </View>
                  {/* <FontAwesome name="angle-right" size={IconSizes.large} color={Colors.green}></FontAwesome> */}
                </View>
                {/* </Pressable> */}
                <Pressable onPress={() => navigation.navigate('ProfileUpdatePassword')}>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: Sizes.large, paddingVertical: 15, borderTopColor: 'lightgrey', borderTopWidth: 1 }}>
                    <View>
                      <Text style={{ ...TextStyles.paragraph, color: Colors.grey }}>Password</Text>
                      <View style={{ flexDirection: 'row', marginTop: 5, marginBottom: 10 }}>
                        <FontAwesome name="circle" size={8} color={Colors.black}></FontAwesome>
                        <FontAwesome name="circle" size={8} color={Colors.black} style={{ marginLeft: 4 }}></FontAwesome>
                        <FontAwesome name="circle" size={8} color={Colors.black} style={{ marginLeft: 4 }}></FontAwesome>
                        <FontAwesome name="circle" size={8} color={Colors.black} style={{ marginLeft: 4 }}></FontAwesome>
                        <FontAwesome name="circle" size={8} color={Colors.black} style={{ marginLeft: 4 }}></FontAwesome>
                        <FontAwesome name="circle" size={8} color={Colors.black} style={{ marginLeft: 4 }}></FontAwesome>
                        <FontAwesome name="circle" size={8} color={Colors.black} style={{ marginLeft: 4 }}></FontAwesome>
                        <FontAwesome name="circle" size={8} color={Colors.black} style={{ marginLeft: 4 }}></FontAwesome>
                      </View>
                    </View>
                    <FontAwesome name="angle-right" size={IconSizes.large} color={Colors.green}></FontAwesome>
                  </View>
                </Pressable>
                <Pressable onPress={() => navigation.navigate('DeleteMyAccount')}>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: Sizes.large, paddingVertical: 15, borderTopColor: 'lightgrey', borderTopWidth: 1 }}>
                    <Text style={{ fontFamily: FontFamilys.regular, color: Colors.red }}>Delete My Account</Text>
                    <FontAwesome name="angle-right" size={IconSizes.large} color={Colors.green}></FontAwesome>
                  </View>
                </Pressable>
              </View>
              {(!planExpired && !is7daysPlan && !planSuspended) &&
                <>
                  <Text style={{ ...TextStyles.subtitle, color: Colors.white, marginTop: Sizes.large, marginBottom: Sizes.medium }}>Auto Extension</Text>
                  {!user.selectedAccount.customer_info.payment_method_info && <AccountAddCreditCard />}
                  {user.selectedAccount.customer_info.payment_method_info && <AccountSavedCreditCard />}              
                </>
              }
              <Text style={{ ...TextStyles.subtitle, color: Colors.white, marginTop: Sizes.large, marginBottom: Sizes.medium }}>Connect With Us</Text>
              <ConnectWithUsCard />
            </View>
          </ScrollView>
        </SafeAreaView>
      }
    </>
  );
}