import { GestureResponderEvent, Text, View, Image, Linking, Pressable } from 'react-native';
import Colors from '../constants/Colors';
import TextStyles from '../constants/TextStyles';
import Sizes from '../constants/Sizes';
import FontFamilys from '../constants/FontFamilys';
import { AppPhoneSvgIcon } from './AppPhoneSvgIcon';
import { AppLineSvgIcon } from './AppLineSvgIcon';
import { AppTalkSvgIcon } from './AppTalkSvgIcon';
import useIsMobile from '../hooks/useIsMobile';

type ConnectWithUsCardProps = {
  onPress?: ((event: GestureResponderEvent) => void) | undefined;
}

export function ConnectWithUsCard(props: ConnectWithUsCardProps) {
  const isMobile = useIsMobile();
  return (
    <>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <View style={{ borderRadius: 50, backgroundColor: Colors.white, width: 50, height: 50, justifyContent: 'center', alignItems: 'center', borderWidth: isMobile ? 0 : 1, borderColor: isMobile ? '' : Colors.green }}>
          <Pressable onPress={() => Linking.openURL("https://line.me/R/ti/p/@244vhrco")}>
            <AppLineSvgIcon></AppLineSvgIcon>
          </Pressable>
        </View>
        <View style={{ borderRadius: 50, backgroundColor: Colors.white, width: 50, height: 50, marginStart: Sizes.large, justifyContent: 'center', alignItems: 'center', borderWidth: isMobile ? 0 : 1, borderColor: isMobile ? '' : Colors.green }}>
          <Pressable onPress={() => Linking.openURL("https://wa.me/14039662412?text=%0A")}>
            <AppPhoneSvgIcon></AppPhoneSvgIcon>
          </Pressable>
        </View>
        <View style={{ borderRadius: 50, backgroundColor: Colors.white, width: 50, height: 50, marginStart: Sizes.large, justifyContent: 'center', alignItems: 'center', borderWidth: isMobile ? 0 : 1, borderColor: isMobile ? '' : Colors.green }}>
          <Pressable onPress={() => Linking.openURL("https://pf.kakao.com/_SWqFC")}>
            <AppTalkSvgIcon></AppTalkSvgIcon>
          </Pressable>
        </View>
      </View>
      <Text style={{ ...TextStyles.subtitle, color: isMobile ? Colors.white : Colors.black, marginTop: Sizes.large }}>Toll Free: <Text style={{ fontFamily: FontFamilys.regular }}>+1 (855) 886 0505</Text></Text>
      <Text style={{ ...TextStyles.subtitle, color: isMobile ? Colors.white : Colors.black }}>Email: <Text style={{ fontFamily: FontFamilys.regular }}>services@gophonebox.com</Text></Text>
    </>
  );
}
