import { Pressable, Text, View } from 'react-native';
import Colors from '../constants/Colors';
import CommonStyles from '../constants/CommonStyles';
import FlexStyles from '../constants/FlexStyles';
import TextStyles from '../constants/TextStyles';
import Sizes from '../constants/Sizes';
import { AppBoldText } from './AppBoldText';
import FontFamilys from '../constants/FontFamilys';
import { AppButton } from './AppButton';
import { AppCard } from './AppCard';
import useIsMobile from '../hooks/useIsMobile';
import { useState } from 'react';
import { FontAwesome } from '@expo/vector-icons';
import { AppTextInput } from './AppTextInput';
import { AppContainer } from './AppContainer';
import { AppRow } from './AppRow';
import { AppColumn } from './AppColumn';
import { AppGap } from './AppGap';
import { AppSwitch } from './AppSwitch';
import { AppAlert } from './AppAlert';
import { profileService } from '../services/ProfileService';
import { extractExpirationDate, getCreditCardType } from '../utilities/utilities';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { setAutoPayment, setPaymentMethodInfo } from '../store/user';
import { RootState } from '../store';
import { CardType } from '../types/enums/CardType';

type AccountAddCreditCardProps = {
}

export function AccountAddCreditCard(props: AccountAddCreditCardProps) {
  const user = useSelector((state: RootState) => state.user);
  const navigation = useNavigation();
  const isMobile = useIsMobile();
  const [editingcard, setEditingCard] = useState(false);
  const [cardError, setCardError] = useState('');
  const [savingCard, setSavingCard] = useState(false);
  const dispatch = useDispatch();
  const [card, setCard] = useState({ number: '', name: '', expirationDate: '', cvv: '', internationalCalling: false, cardType: CardType.VISA });

  const handleCardNumber = (text: string) => {
    const cardType = getCreditCardType(text);
    setCard({ ...card, number: text, cardType: cardType })
    console.log(cardType.toString());
  };
  
  const addCard = async () => {

    setCardError('');
    setSavingCard(true);
    const expiry = extractExpirationDate(card.expirationDate);
    var response = await profileService.addCard({
      data: {
        customer_id: user.selectedAccount.customer_info.customer_id,
        cvv: card.cvv,
        expiration_month: expiry.expirationMonth,
        expiration_year: expiry.expirationYear,
        international_calling: card.internationalCalling,
        name: card.name,
        number: card.number,
        card_type: card.cardType,
      }
    });
    if (!response || !response.success) {
      setCardError(response.error.message);
      setSavingCard(false);
      console.error(response.error);
      return;
    }

    dispatch(setAutoPayment(true));
    dispatch(setPaymentMethodInfo({ card_holder_name: card.name, card_type: card.cardType, expiry_month: expiry.expirationMonth, expiry_year: expiry.expirationYear, masked_card_number: '************' + card.number.slice(-4) }))
    setEditingCard(false);
    setSavingCard(false);
    setEditingCard(false);
  }

  return (
    <>
      {!isMobile &&
        <AppCard>
          <View style={CommonStyles.cardBody}>
            {editingcard &&
              <>
                <Pressable onPress={() => { setEditingCard(false); setCardError(''); }} style={{ position: 'absolute', right: 0, top: 0, marginTop: 10, marginRight: 10 }}>
                  <View style={{ width: 20, height: 20, backgroundColor: Colors.white, borderRadius: 20, borderColor: Colors.grey, borderWidth: 1, justifyContent: 'center', alignItems: 'center' }} >
                    <FontAwesome name="close" size={12} color={Colors.grey}></FontAwesome>
                  </View>
                </Pressable>
                <AppContainer>
                  <AppRow>
                    <AppColumn>
                      <Text style={{ ...TextStyles.paragraph, color: Colors.black, marginBottom: Sizes.small }}>Card Holder Name</Text>
                      <AppTextInput placeholder="Card Holder Name" value={card.name} onChangeText={(text) => setCard({ ...card, name: text.trim() })}></AppTextInput>
                    </AppColumn>
                    <AppGap size={20}></AppGap>
                    <AppColumn>
                      <Text style={{ ...TextStyles.paragraph, color: Colors.black, marginBottom: Sizes.small }}>Card Number</Text>
                      <AppTextInput placeholder="Card Number" value={card.number} onChangeText={handleCardNumber} keyboardType="numeric" maxLength={16} numbersOnly></AppTextInput>
                    </AppColumn>
                  </AppRow>
                  <AppGap direction="vertical" size={20}></AppGap>
                  <AppRow>
                    <AppColumn>
                      <Text style={{ ...TextStyles.paragraph, color: Colors.black, marginBottom: Sizes.small }}>Expiration Date</Text>
                      <AppTextInput value={card.expirationDate} onChangeText={(text) => setCard({ ...card, expirationDate: text })} maxLength={5} placeholder="MM/YY" numbersOnly expiryDate></AppTextInput>
                    </AppColumn>
                    <AppGap size={20}></AppGap>
                    <AppColumn>
                      <Text style={{ ...TextStyles.paragraph, color: Colors.black, marginBottom: Sizes.small }}>CVV</Text>
                      <AppTextInput placeholder="CVV" value={card.cvv} onChangeText={(text) => setCard({ ...card, cvv: text })} maxLength={4} numbersOnly></AppTextInput>
                    </AppColumn>
                  </AppRow>
                  <AppGap direction="vertical" size={20}></AppGap>
                  {/* <AppRow>
                    <AppColumn>
                      <AppSwitch onToggle={(toggled) => setCard({ ...card, internationalCalling: toggled })} text="Add on Auto International"></AppSwitch>
                    </AppColumn>
                  </AppRow> */}
                </AppContainer>
                <View style={{ ...FlexStyles.rowCenter }}>
                  <AppButton text="Save" onPress={addCard} color={Colors.blue} loading={savingCard}></AppButton>
                </View>
                {cardError.length > 0 &&
                  <View style={{ marginTop: Sizes.medium }}>
                    <AppAlert text={cardError}></AppAlert>
                  </View>
                }
              </>
            }
            {!editingcard &&
              <>
                <View style={{ ...FlexStyles.rowCenter, marginBottom: Sizes.large }}>
                  <Text style={{ ...TextStyles.subtitle, fontFamily: FontFamilys.regular, color: Colors.black, textAlign: 'center' }}>Please add a Credit Card for <AppBoldText>future payment or auto payment</AppBoldText></Text>
                </View>
                <View style={{ ...FlexStyles.rowCenter }}>
                  <AppButton text="Add Your Card" onPress={() => setEditingCard(true)}></AppButton>
                </View>
              </>
            }
          </View>
        </AppCard>
      }

      {isMobile &&
        <AppCard>
          <View style={CommonStyles.cardBody}>
            <View style={{ ...FlexStyles.rowCenter, marginBottom: Sizes.large }}>
              <Text style={{ ...TextStyles.subtitle, fontFamily: FontFamilys.regular, color: Colors.black, textAlign: 'center' }}>Please add a Credit Card for <AppBoldText>future payment or auto payment</AppBoldText></Text>
            </View>
            <View style={{ ...FlexStyles.rowCenter }}>
              <AppButton text="Add Your Card" onPress={() => navigation.navigate('AddNewCard')}></AppButton>
            </View>
          </View>
        </AppCard>
      }
    </>
  );
}
