import { StyleSheet } from 'react-native';
import Colors from './Colors';

const styles = StyleSheet.create({
    rowVerticalCenter: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    rowHorizontalCenter: {
        flexDirection: 'row',
        justifyContent: 'center'
    },
    rowCenter: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
});

export default styles;