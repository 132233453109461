import { BaseSyntheticEvent, useState } from 'react';
import { View, Text, Pressable, Linking, ScrollView, Platform, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { useDispatch } from 'react-redux';
import { AppAlert } from '../components/AppAlert';
import { AppButton } from '../components/AppButton';
import { AppColumn } from '../components/AppColumn';
import { AppContainer } from '../components/AppContainer';
import { AppFooter } from '../components/AppFooter';
import { AppGap } from '../components/AppGap';
import { AppHeader } from '../components/AppHeader';
import { AppLink } from '../components/AppLink';
import { AppRow } from '../components/AppRow';
import { AppTextInput } from '../components/AppTextInput';
import Colors from '../constants/Colors';
import CommonStyles from '../constants/CommonStyles';
import FontFamilys from '../constants/FontFamilys';
import Heights from '../constants/Heights';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import useHorizontalContainerMargin from '../hooks/useHorizontalContainerMargin';
import useIsMobile from '../hooks/useIsMobile';
import { authService } from '../services/AuthService';
import { tokenService } from '../services/TokenService';
import { AppDispatch, RootState } from '../store';
import { setDeactivated, setLoggedIn } from '../store/user';
import { RootStackScreenProps } from '../types';
import { MarginType } from '../types/enums/MarginType';
import { useSelector } from 'react-redux';

export default function LandingScreen({ navigation }: RootStackScreenProps<'Landing'>) {
  const isMobile = useIsMobile();
  const user = useSelector((state: RootState) => state.user);
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [loggingIn, setLoggingIn] = useState(false);
  const [logInError, setLogInError] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const marginHorizontal = useHorizontalContainerMargin(MarginType.Login);
  const handleLogin = async () => {
    setLogInError('');
    setLoggingIn(true);
    dispatch(setDeactivated(false));
    var response = await authService.signIn({ data: { email: emailAddress, password: password } });

    if (!response || !response.success) {
      setLoggingIn(false);
      setLogInError(response.error.message);
      console.error(response.error);
      return;
    }

    if (response && response.success) {
      await tokenService.setAccessTokenAsync(response.data.access_token);
      await tokenService.setAccessTokenExpiryAsync(new Date(response.data.access_token_expiry));
      await tokenService.setRefreshTokenAsync(response.data.refresh_token);
      await tokenService.setRefreshTokenExpiryAsync(new Date(response.data.refresh_token_expiry));
      dispatch(setLoggedIn(true));
      setLoggingIn(false);
      setLogInError('');
    }
  }
  const handleAccountSwitchClick = () => {
    if (Platform.OS === "web") {
      window.open("https://myaccount.gophonebox.com/login", "_self")
    } else {
      Linking.openURL("https://myaccount.gophonebox.com/login")
    }
  }

  const handlePressEnter = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    if (e.nativeEvent.key === "Enter") {
      handleLogin();
    }
  }

  return (
    <>
      {!isMobile &&
        <>
          <AppHeader onlyLogo={true} ></AppHeader>
          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(130, 195, 109, 0.2)', paddingVertical: 5, zIndex: -1 }}>
            <Text style={{ fontFamily: FontFamilys.regular, color: Colors.green }}>You are now logging in to US Self Serve. </Text>
            <AppLink onPress={handleAccountSwitchClick} text="Click here to switch to Canadian Self Serve" color={Colors.green}></AppLink>
          </View>
          <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.scrollViewPaddingTop }}>
            <View style={{ ...PageLayoutStyles.container }}>
              <View style={{ ...CommonStyles.card, padding: 0, borderColor: Colors.green, borderWidth: 2, marginHorizontal: marginHorizontal }}>
                <AppContainer>
                  <AppRow>
                    <AppColumn>
                      <View style={{ alignItems: 'center', justifyContent: 'space-between', padding: Sizes.extraLarge, flex: 1 }}>
                        <Text style={{ color: Colors.black, fontFamily: FontFamilys.regular, fontSize: 30 }}>Sign in to PhoneBox</Text>
                        <View>
                          <AppGap direction="vertical" size={20}></AppGap>
                          <AppTextInput placeholder="Username or Email" onChangeText={(text) => setEmailAddress(text.trim())} onKeyPress={handlePressEnter}></AppTextInput>
                          <AppGap direction="vertical" size={20}></AppGap>
                          <AppTextInput placeholder="Password" onChangeText={(text) => setPassword(text)} secureTextEntry onKeyPress={handlePressEnter}></AppTextInput>
                          <AppGap direction="vertical" size={20}></AppGap>
                          <Pressable onPress={() => navigation.navigate("ForgotPassword")}>
                            <Text style={{ ...TextStyles.semiSubtitle, fontFamily: FontFamilys.regular, textAlign: 'center', color: Colors.grey }}>Forgot your password?</Text>
                          </Pressable>
                          <AppGap direction="vertical" size={20}></AppGap>
                        </View>
                        <AppButton text="Sign In" onPress={handleLogin} loading={loggingIn}></AppButton>
                        {logInError.length > 0 &&
                          <View style={{ marginTop: Sizes.medium }}>
                            <AppAlert text={logInError}></AppAlert>
                          </View>
                        }
                        {user.deactivated &&
                          <View style={{ marginTop: Sizes.medium }}>
                            <AppAlert text="Your account must have been suspended for more than 90 days. As a result, the account has been deactivated."></AppAlert>
                          </View>
                        }
                      </View>
                    </AppColumn>
                    <AppColumn>
                      <View style={{ alignItems: 'center', justifyContent: 'space-between', padding: Sizes.extraLarge, borderTopRightRadius: 7, flex: 1, borderBottomRightRadius: 7, backgroundColor: Colors.green }}>
                        <Text style={{ color: Colors.white, textAlign: 'center', fontFamily: FontFamilys.regular, fontSize: 30 }}>Welcome!</Text>
                        <Text style={{ ...TextStyles.subtitle, color: Colors.white, textAlign: 'center', fontFamily: FontFamilys.regular }}>Don't have an account yet? Set up your PhoneBox account to get access to all your account information.</Text>
                        <AppButton outline color={Colors.white} text="Sign Up" onPress={() => navigation.navigate("SignUp")}></AppButton>
                      </View>
                    </AppColumn>
                  </AppRow>
                </AppContainer>
              </View>
            </View>
          </ScrollView>
        </>
      }
      {isMobile &&
        <View style={{ ...PageLayoutStyles.container, flex: 1, flexDirection: 'column', justifyContent: 'space-around' }}>
          <Text style={{ ...TextStyles.title, color: Colors.white }}>Your journey begins here</Text>
          <Text style={{ fontFamily: FontFamilys.regular, color: Colors.white, fontSize: Sizes.large }}>Managing your PhoneBox account has never been this easy.</Text>
          <View style={{ marginTop: Sizes.large }}>
            <View style={{ marginBottom: Sizes.medium }}>
              <AppButton text='Sign In' onPress={() => navigation.navigate('SignIn')}></AppButton>
            </View>
            <View style={{ marginTop: Sizes.medium }}>
              <AppButton text="Sign Up" onPress={() => navigation.navigate('SignUp')} color={Colors.white} outline></AppButton>
            </View>
            {user.deactivated &&
              <View style={{ marginTop: Sizes.large }}>
                <AppAlert text="Your account must have been suspended for more than 90 days. As a result, the account has been deactivated."></AppAlert>
              </View>
            }
          </View>
          <View>
            <View style={{ marginTop: Sizes.large, alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
              <Text style={{ fontFamily: FontFamilys.regular, color: Colors.white, textAlign: 'center' }}>New to PhoneBox? See our plans </Text>
              <AppLink text="here" onPress={() => Linking.openURL('https://gophonebox.com/plans/?plans=us')}></AppLink>
            </View>
            <View style={{ marginTop: Sizes.large }}>
              <Text style={{ fontFamily: FontFamilys.regular, color: Colors.white, textAlign: 'center' }}>By continuing, you agree to accept our</Text>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
              <AppLink text="Terms and Conditions" onPress={() => navigation.navigate('TermsAndConditions')}></AppLink>
              <Text style={{ color: Colors.white, fontFamily: FontFamilys.regular }}> and our </Text>
              <AppLink text="Privacy Policy" onPress={() => navigation.navigate('PrivacyPolicy')}></AppLink>
            </View>
          </View>
        </View>
      }
    </>
  );
}
