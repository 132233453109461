import { FontAwesome } from '@expo/vector-icons';
import { GestureResponderEvent, Modal, Pressable, StyleSheet, View, Text } from 'react-native';
import Colors from '../constants/Colors';
import TextStyles from '../constants/TextStyles';

type AppModalProps = {
  onClose: ((event: GestureResponderEvent) => void) | undefined;
  visible: boolean;
  children: React.ReactNode;
  headerTitle?: string;
  width?: number | string;
  height?: number | string;
}

export function AppModal(props: AppModalProps) {
  const onClickOutside = (e: any, fn: Function | undefined) => {
    if (e.target === e.currentTarget) {
      fn ? fn() : null;
    }
  }

  return (
    <Modal transparent={true} visible={props.visible} onRequestClose={props.onClose}>
      <Pressable style={styles.centeredView} onPress={e => onClickOutside(e, props.onClose)}>
        <View style={{ ...styles.modalView, width: props.width ? props.width : 'auto', height: props.height ? props.height : 'auto' }}>
          {props.headerTitle &&
            <View style={styles.modalHeader}>
              <Text style={{ ...TextStyles.subtitle, color: Colors.black }}>{props.headerTitle}</Text>
              <Pressable onPress={props.onClose}>
                <FontAwesome name="times" size={20}></FontAwesome>
              </Pressable>
            </View>
          }
          <View style={styles.modalBody}>
            {props.children}
          </View>
        </View>
      </Pressable>
    </Modal >
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(105, 105, 105, 0.5)'
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 30,
  },
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  modalBody: {
    marginTop: 20
  }
});