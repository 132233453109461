import { Platform, Image } from 'react-native';
import Phone from "../assets/images/phone.svg";

type AppPhoneSvgIconProps = {}

export function AppPhoneSvgIcon(props: AppPhoneSvgIconProps) {
  return (
    <>
      {Platform.OS === 'web' && <Image source={require('../assets/images/phone.svg')} style={{ width: 35, height: 35 }} />}
      {Platform.OS !== 'web' && <Phone width={35} height={35} />}
    </>
  );
}