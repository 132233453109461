import { FontAwesome } from '@expo/vector-icons';
import { Pressable, GestureResponderEvent } from 'react-native';

type AppIconButtonProps = {
  name: React.ComponentProps<typeof FontAwesome>['name'];
  onPress?: ((event: GestureResponderEvent) => void) | undefined;
}
export function AppIconButton(props: AppIconButtonProps) {
  return (
    <Pressable onPress={props.onPress}>
      <FontAwesome size={30} name={props.name} color="#fff"></FontAwesome>
    </Pressable>
  );
}