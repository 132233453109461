import { FontAwesome } from '@expo/vector-icons';
import { ScrollView, StyleSheet, View, Text } from 'react-native';
import { AppButton } from '../components/AppButton';
import Colors from '../constants/Colors';
import FontFamilys from '../constants/FontFamilys';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import { RootStackScreenProps, RootTabScreenProps } from '../types';
type BulletPointProps = {
  indented?: boolean;
}
export default function PrivacyPolicyScreen({ navigation }: RootStackScreenProps<'PrivacyPolicy'>) {
  const BulletPoint = (props?: BulletPointProps) => {
    return <View style={{ marginTop: 8 }}>
      <FontAwesome name={props?.indented ? 'circle-o' : 'circle'}></FontAwesome>
    </View>
  };
  return (
    <ScrollView>
      <View style={{ ...PageLayoutStyles.container, paddingTop: Sizes.none }}>

        <Text style={styles.title}>Privacy Policy</Text>
        <Text style={styles.paragraph}>At PhoneBox, the security and integrity of our customers' personal information is important to us. We take every reasonable measure necessary to protect the privacy of our customers while providing the high level communication services they expect. You can count on us to provide you with products and services designed with privacy in mind, while also giving you control over how your information is shared.</Text>
        <Text style={styles.paragraph}>Our Privacy Principles are fundamental to our business, and reflect our commitment to:</Text>
        <View style={{ ...styles.bulletPointContainer, marginBottom: 20 }}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>PhoneBox's privacy practices are in accordance with all federal and provincial laws and regulations. We are compliant with the Personal Information Protection and Electronic Documents Act and where applicable with the privacy rules established by the Federal Communications Commission.</Text>
        </View>
        <Text style={styles.paragraph}>At PhoneBox, we collect customer information for one or more of the following purposes:</Text>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>To provide a positive customer experience, and deliver, bill for, and collect payment for products and services;</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>To understand customer requirements and make information available regarding products and services offered by PhoneBox directly or through its network of distributors, partners and its related companies</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>To manage and develop PhoneBox’s business and operations;</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>To meet legal and regulatory requirements; and</Text>
        </View>
        <View style={{ ...styles.bulletPointContainer, marginBottom: 20 }}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>To obtain credit information or provide it to others.</Text>
        </View>
        <Text style={styles.paragraph}>PhoneBox does not use or disclose personal information for purposes other than those for which it was collected, except with the consent of the individual or as required by law. PhoneBox retains personal information only as long as necessary for the fulfillment of those purposes.</Text>
        <Text style={styles.paragraph}>PhoneBox informs customers of the existence, use and disclosure of their personal information upon request and gives them access to their information.</Text>
        <Text style={styles.paragraph}>Customers that wish to obtain:</Text>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>More information about this Commitment to Privacy;</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Access to, or more information about, their personal information held by PhoneBox; or</Text>
        </View>
        <View style={{ ...styles.bulletPointContainer, marginBottom: 20 }}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Make a privacy-related complaint; may contact a PhoneBox customer service representative.</Text>
        </View>

        <Text style={styles.subtitle}>How does PhoneBox protect my privacy?</Text>
        <Text style={styles.paragraph}>PhoneBox is committed to protecting your privacy and we take all reasonable steps to ensure that your personal information is safe and secure in compliance with applicable privacy laws and regulations.</Text>

        <Text style={styles.subtitle}>Warranties and Limitation of Liability</Text>
        <Text style={styles.paragraph}>Please note that the term “PhoneBox Parties” includes PhoneBox and its affiliates, partners, licensors, dealers, representatives, suppliers and agents (and their respective employees, officers, directors, shareholders and representatives).</Text>

        <Text style={styles.subtitle}>Are there any warranties on the Services?</Text>
        <Text style={styles.paragraph}>The Services that PhoneBox provides may be impacted by factors beyond PhoneBox reasonable control. For this reason, you acknowledge and understand that the Services or access to the Services, including 9-1-1, public alerts or accessibility services, may not function correctly or at all in the following circumstances:</Text>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>If your Equipment fails, is not configured correctly or does not meet PhoneBox’s requirements;</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>If you install certain third party applications on your Equipment;</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>In the event of a network outage or power failure;</Text>
        </View>
        <View style={{ ...styles.bulletPointContainer, marginBottom: 20 }}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Following suspension or cancellation of your Services or account.</Text>
        </View>

        <Text style={styles.subtitle}>Information We Collect</Text>
        <Text style={styles.paragraph}>Unless you provide it to us voluntarily or the information is collected via cookies in the manner described in this policy, PhoneBox does not collect personally identifiable information about you (including, but not limited to your name, address, telephone number, and email address) in connection with your use of this web site.</Text>
        <Text style={styles.paragraph}>PhoneBox collects the information you provide us when you sign up to receive newsletters or advisories or contact us by email. We use this information only to respond to your comments or questions or to provide services related to the specific purpose for which you submitted the information. When applicable, each recurring newsletter or advisory that you voluntarily requested will contain instructions telling you how to be removed from our distribution list.</Text>
        <Text style={styles.paragraph}>In addition, when you visit our website, we may passively collect other kinds of information, including the location from which you visit us, the kind of browser you use, the date and time you visit this web site, and the amount of time you spent viewing the site. Generally, we gather this information for the purpose of analyzing aggregate trends and statistics. In order to gather such information, our web server may automatically use technologies such as the following: Cookies. A cookie is a piece of information stored on your hard drive that tells our server that you have returned to this web site. Cookies do not gather information—instead, they may store information you provide. For instance, if you visit our web site and provide us with your name and email address, a cookie is used to retain this information. If you return to this web site, this cookie allows us to personalize your visit by remembering your name and email address. We may also use cookies to track usage of this web site in order to better understand what areas of our web site interest our users. Although these cookies remain on your hard drive indefinitely, you may choose to delete or disable them by changing your internet browser preferences. You can also set your internet browser to notify you when you receive a cookie, and you can decide whether to accept or reject the cookie.</Text>
        <Text style={styles.paragraph}>Log Files. Internet protocol (“IP”) addresses are used to analyze trends, administer the web site, track users' movements, and gather broad demographic information for aggregate use. IP addresses are not linked to personally identifiable information.</Text>


        <Text style={styles.subtitle}>When We May Disclose Your Information</Text>
        <Text style={styles.paragraph}>When We May Disclose Your Information PhoneBox does not share your information with third parties without your consent except:</Text>
        <Text style={styles.paragraph}>(i) where such disclosure is necessary to comply with the law or protect the safety of our users or others, (ii) with service providers performing services on our behalf. Any service providers employed by our firm will have access to your information solely to the extent necessary to enable them to perform the service on our behalf and will be contractually prohibited from using the information for any other purpose, or (iii) to another law firm in connection with a proposed or actual merger.</Text>

        <Text style={styles.subtitle}>To Opt Out Or Request Changes To Your Information</Text>
        <Text style={styles.paragraph}>You are entitled to request that PhoneBox provide you with a electronic copy of your personal information that it holds correct any errors in that information and update that information as required.</Text>
        <Text style={styles.paragraph}>If you would like to receive a copy of the personal information we have about you submitted to us via this web site, or if you wish to opt out of receiving marketing related communications from us, please send a request to: Services@gophonebox.com, or reach out to us via Toll-free number at 1-855-886-0505.</Text>
        <Text style={styles.paragraph}>If this information is incorrect or incomplete, please let us know, and we will make every reasonable effort to correct or update it promptly unless we require further information from you in order to fulfill your request). You may also ask us to remove your name and other personal information from our databases. In each of the foregoing cases, we will make all reasonable efforts to honor your request promptly, subject to legal and other permissible considerations.</Text>

        <Text style={styles.subtitle}>Security</Text>
        <Text style={styles.paragraph}>This web site employs industry standard security measures designed to be against loss, misuse or alteration of information you provide to us through this web site. However, PhoneBox is not responsible for unauthorized access to information by hackers or others who have obtained such access through illegal means.</Text>

        <Text style={styles.subtitle}>Changes to This Privacy Statement</Text>
        <Text style={styles.paragraph}>We reserve the right to change this Privacy Statement at any time without advance notice. Please check this page periodically for changes. Your continued use of this web site signifies your assent to this Privacy Statement. This Policy was last updated March 2nd, 2023.</Text>

        <Text style={styles.subtitle}>Contacts</Text>
        <Text style={styles.paragraph}>If you have any questions about this website, you may contact us at:</Text>

        <Text style={{ ...styles.subtitle, marginBottom: 0 }}>Address</Text>
        <Text style={{ ...styles.paragraph, marginBottom: 0 }}>PhoneBox</Text>
        <Text style={{ ...styles.paragraph }}>16192 Coastal Hwy, Lewes, DE 19958, USA</Text>
        <Text style={{ ...styles.subtitle, marginBottom: 0 }}>Phone</Text>
        <Text style={{ ...styles.paragraph }}>Toll-Free Number, +1-855-886-0505</Text>
        <Text style={{ ...styles.subtitle, marginBottom: 0 }}>Email</Text>
        <Text style={{ ...styles.paragraph }}>services@gophonebox.com</Text>

        <View style={{ marginTop: Sizes.large }}>
          <AppButton text="I Agree" onPress={() => navigation.goBack()}></AppButton>
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  title: {
    ...TextStyles.title,
    color: Colors.black,
    marginBottom: 20,
  },
  subtitle: {
    ...TextStyles.subtitle,
    color: Colors.black,
    marginBottom: 20,
  },
  paragraph: {
    ...TextStyles.subtitle,
    fontFamily: FontFamilys.regular,
    color: Colors.black,
    marginBottom: 20,
  },
  bulletPointContainer: {
    marginLeft: 20,
    flexDirection: 'row',
  },
  indentedBulletPointContainer: {
    marginLeft: 70,
    flexDirection: 'row',
  },
  bulletPointText: {
    ...TextStyles.subtitle,
    fontFamily: FontFamilys.regular,
    color: Colors.black,
    marginLeft: 15
  }
});