import { FontAwesome } from '@expo/vector-icons';
import { Text, View } from 'react-native';
import Colors from '../constants/Colors';
import CommonStyles from '../constants/CommonStyles';
import FontFamilys from '../constants/FontFamilys';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import { CardType } from '../types/enums/CardType';

type AppCreditCardProps = {
  type: CardType;
  cardHolderName: string;
  expiryMonth: string;
  expiryYear: string;
  maskedCardNumber: string;
  backgroundColor?: string;
  textColor?: string;
}
 
export function AppCreditCard(props: AppCreditCardProps) {
  const textColor = props.textColor ? props.textColor : Colors.white;
  const backgroundColor = props.backgroundColor ? props.backgroundColor : Colors.green;
  return (
    <>
      <View style={{ ...CommonStyles.card, backgroundColor: backgroundColor }}>
        <Text style={{ ...TextStyles.subtitle, color: textColor, fontSize: 22 }}>Credit Card</Text>
        <View style={{ justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', marginTop: Sizes.medium }}>
          <View style={{ justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
            <FontAwesome name="circle" size={12} color={textColor}></FontAwesome>
            <FontAwesome name="circle" size={12} color={textColor} style={{ marginLeft: Sizes.small }}></FontAwesome>
            <FontAwesome name="circle" size={12} color={textColor} style={{ marginLeft: Sizes.small }}></FontAwesome>
            <FontAwesome name="circle" size={12} color={textColor} style={{ marginLeft: Sizes.small }}></FontAwesome>
          </View>
          <View style={{ justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
            <FontAwesome name="circle" size={12} color={textColor}></FontAwesome>
            <FontAwesome name="circle" size={12} color={textColor} style={{ marginLeft: Sizes.small }}></FontAwesome>
            <FontAwesome name="circle" size={12} color={textColor} style={{ marginLeft: Sizes.small }}></FontAwesome>
            <FontAwesome name="circle" size={12} color={textColor} style={{ marginLeft: Sizes.small }}></FontAwesome>
          </View>
          <View style={{ justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
            <FontAwesome name="circle" size={12} color={textColor}></FontAwesome>
            <FontAwesome name="circle" size={12} color={textColor} style={{ marginLeft: Sizes.small }}></FontAwesome>
            <FontAwesome name="circle" size={12} color={textColor} style={{ marginLeft: Sizes.small }}></FontAwesome>
            <FontAwesome name="circle" size={12} color={textColor} style={{ marginLeft: Sizes.small }}></FontAwesome>
          </View>
          <View style={{ justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
            <Text style={{ ...TextStyles.subtitle, color: textColor, fontFamily: FontFamilys.regular }}>{props.maskedCardNumber.slice(-4)}</Text>
          </View>
        </View>
        <View style={{ justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', marginTop: Sizes.large }}>
          {/* <Text style={{ ...TextStyles.subtitle, color: textColor, fontFamily: FontFamilys.bold, fontSize: 15 }}>CARD HOLDER NAME</Text> */}
          <Text style={{ ...TextStyles.subtitle, color: textColor, fontFamily: FontFamilys.bold, fontSize: 15 }}>EXPIRY DATE</Text>
        </View>
        <View style={{ justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
          {/* <Text style={{ ...TextStyles.subtitle, color: textColor, fontFamily: FontFamilys.regular }}>{props.cardHolderName}</Text> */}
          <Text style={{ ...TextStyles.subtitle, color: textColor, fontFamily: FontFamilys.regular }}>{props.expiryMonth}/{props.expiryYear}</Text>
        </View>
      </View>
    </>
  );
}
