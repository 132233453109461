import { useState } from 'react';
import { ScrollView, View, Text } from 'react-native';
import { AppAlert } from '../components/AppAlert';
import { AppButton } from '../components/AppButton';
import { AppColumn } from '../components/AppColumn';
import { AppContainer } from '../components/AppContainer';
import { AppFooter } from '../components/AppFooter';
import { AppGap } from '../components/AppGap';
import { AppHeader } from '../components/AppHeader';
import { AppRow } from '../components/AppRow';
import { AppTextBackButton } from '../components/AppTextBackButton';
import { AppTextInput } from '../components/AppTextInput';
import Colors from '../constants/Colors';
import CommonStyles from '../constants/CommonStyles';
import FontFamilys from '../constants/FontFamilys';
import Heights from '../constants/Heights';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import useIsMobile from '../hooks/useIsMobile';
import { authService } from '../services/AuthService';
import { RootStackScreenProps } from '../types';

export default function ForgotPasswordScreen({ navigation }: RootStackScreenProps<'ForgotPassword'>) {
  const isMobile = useIsMobile();
  const [email, setEmail] = useState('');
  const [forgotPasswordError, setForgotPasswordError] = useState('');
  const [requestingPasswordReset, setRequestingPasswordReset] = useState(false);

  const handleForgotPassword = async () => {
    setForgotPasswordError('');
    setRequestingPasswordReset(true);
    var response = await authService.forgotPassword({ data: { email: email } });

    if (!response || !response.success) {
      setRequestingPasswordReset(false);
      setForgotPasswordError(response.error.message);
      console.error(response.error);
      return;
    }

    setRequestingPasswordReset(false);
    setForgotPasswordError('');
    navigation.navigate('CheckYourEmail')
  }

  return (
    <>
      {!isMobile &&
        <>
          <AppHeader onlyLogo></AppHeader>
          <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.scrollViewPaddingTop }}>
            <View style={{ ...PageLayoutStyles.container }}>
              <View style={{ ...CommonStyles.card, padding: 0, borderColor: Colors.green, borderWidth: 2, maxWidth: '80%', margin: 'auto' }}>
                <AppContainer>
                  <AppRow>
                    <AppColumn>
                      <View style={{ alignItems: 'center', justifyContent: 'space-between', padding: Sizes.extraLarge, flex: 1 }}>
                        <Text style={{ color: Colors.black, fontFamily: FontFamilys.bold, fontSize: 30 }}>Password Recovery</Text>
                        <View>
                          <AppGap direction="vertical" size={20}></AppGap>
                          <AppTextInput value={email} onChangeText={(text) => setEmail(text)} placeholder="Email Address"></AppTextInput>
                          <AppGap direction="vertical" size={20}></AppGap>
                        </View>
                        <AppButton text="Submit" onPress={handleForgotPassword} loading={requestingPasswordReset}></AppButton>
                        <AppTextBackButton onPress={() => navigation.goBack()}></AppTextBackButton>
                        {forgotPasswordError.length > 0 &&
                          <View style={{ marginTop: Sizes.medium }}>
                            <AppAlert text={forgotPasswordError}></AppAlert>
                          </View>
                        }
                      </View>
                    </AppColumn>
                  </AppRow>
                </AppContainer>
              </View>
            </View>
          </ScrollView>
          <AppFooter></AppFooter>
        </>
      }
      {isMobile &&
        <ScrollView>
          <View style={{ ...PageLayoutStyles.container, paddingTop: Sizes.none }}>
            <Text style={{ ...TextStyles.title, color: Colors.white }}>Forgot Password</Text>
            <AppGap direction="vertical" size={20}></AppGap>
            <AppTextInput value={email} onChangeText={(text) => setEmail(text)} placeholder="Email Address"></AppTextInput>
            <AppGap direction="vertical" size={20}></AppGap>
            <AppButton text='Submit' onPress={handleForgotPassword} loading={requestingPasswordReset}></AppButton>
            {forgotPasswordError.length > 0 &&
              <View style={{ marginTop: Sizes.medium }}>
                <AppAlert text={forgotPasswordError}></AppAlert>
              </View>
            }
          </View>
        </ScrollView>
      }
    </>
  );
}
