import { FontAwesome } from '@expo/vector-icons';
import { GestureResponderEvent, Pressable, Text, View } from 'react-native';
import FontFamilys from '../constants/FontFamilys';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';

type AppTextBackButtonProps = {
  onPress?: ((event: GestureResponderEvent) => void) | undefined;
}

export function AppTextBackButton(props: AppTextBackButtonProps) {
  return (
    <Pressable onPress={props.onPress}>
      <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: Sizes.small }}>
        <FontAwesome name="angle-left" size={15} style={{ marginRight: 10 }}></FontAwesome>
        <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold }}>
          Back
        </Text>
      </View>
    </Pressable>
  );
}
