import { ScrollView, StyleSheet, View, Text } from 'react-native';

import Colors from '../constants/Colors';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import { RootStackScreenProps, RootTabScreenProps } from '../types';

export default function NotificationsScreen({ navigation }: RootStackScreenProps<'Notifications'>) {
  return (
    <ScrollView>
      <View style={{ ...PageLayoutStyles.container, paddingTop: Sizes.none }}>
        <Text style={{ ...TextStyles.title, color: Colors.white, marginBottom: Sizes.large }}>Notifications</Text>
        <Text style={{ ...TextStyles.subtitle, color: Colors.white }}>There are no notifications to show.</Text>
      </View>
    </ScrollView>
  );
}