import instance from "../api/axios";
import { ApiRequest } from "../types/requests/ApiRequest";
import { ApiResponse } from "../types/responses/ApiResponse";
import { EstimateTaxesRequest } from "../types/requests/EstimateTaxesRequest";
import { EstimateTaxesResponse } from "../types/responses/EstimateTaxesResponse";
import { MakeTransactionRequest } from "../types/requests/MakeTransactionRequest";
import { MakeTransactionResponse } from "../types/responses/MakeTransactionResponse";

export const customerService = {
  estimateTaxes: async (request: ApiRequest<EstimateTaxesRequest>): Promise<ApiResponse<EstimateTaxesResponse>> => await instance.post<ApiRequest<EstimateTaxesRequest>, ApiResponse<EstimateTaxesResponse>>('/p1/Customer/estimate_taxes', request),
  // special 2nd version of estimateTaxes because of error from PortaOne
  estimateTaxes2: async (request: ApiRequest<EstimateTaxesRequest>): Promise<ApiResponse<EstimateTaxesResponse>> => await instance.post<ApiRequest<EstimateTaxesRequest>, ApiResponse<EstimateTaxesResponse>>('/Checkout/estimate_taxes2', request),

  makeTransaction: async (request: ApiRequest<MakeTransactionRequest>): Promise<ApiResponse<MakeTransactionResponse>> => await instance.post<ApiRequest<MakeTransactionRequest>, ApiResponse<MakeTransactionResponse>>('/p1/Customer/make_transaction', request),
}