import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountInfo } from "../types/models/AccountInfo";
import { PaymentMethodInfo } from "../types/models/PaymentMethodInfo";
import { UserActions } from "../types/models/UserActions";
import { ExtensionType } from "../types/enums/ExtensionType";
import { Plan } from "../types/models/Plan";
import { xdrId } from "../types/models/XdrId";
import { tryToParse } from "../utilities/utilities";

const userSlice = createSlice({
  name: "user",
  initialState: {
    loggedIn: false,
    accounts: [] as AccountInfo[],
    selectedAccount: {} as AccountInfo,
    userActions: {} as UserActions,
    availablePlans: [] as Plan[],
    xdrId: 0 as xdrId,
    deactivated: false,
  },
  reducers: {
    setLoggedIn(state, action: PayloadAction<boolean>) {
      state.loggedIn = action.payload;
    },
    setAccounts(state, action: PayloadAction<AccountInfo[]>) {
      state.accounts = action.payload;
    },
    setSelectedAccount(state, action: PayloadAction<AccountInfo>) {
      state.selectedAccount = action.payload;
    },
    setFirstName(state, action: PayloadAction<string>) {
      state.selectedAccount.first_name = action.payload;
    },
    setLastName(state, action: PayloadAction<string>) {
      state.selectedAccount.last_name = action.payload;
    },
    setEmail(state, action: PayloadAction<string>) {
      state.selectedAccount.email = action.payload;
    },
    setAutoPayment(state, action: PayloadAction<boolean>) {
      state.selectedAccount.customer_info.auto_payment = action.payload;
    },
    setPaymentMethodInfo(
      state,
      action: PayloadAction<PaymentMethodInfo | undefined>
    ) {
      state.selectedAccount.customer_info.payment_method_info = action.payload;
    },
    setPaymentAmount(state, action: PayloadAction<number>) {
      state.userActions.paymentAmount = action.payload;
    },
    setInternationalCallingPaymentAmount(state, action: PayloadAction<number>) {
      state.userActions.internationalCallingPaymentAmount = action.payload;
    },
    setTopUpPaymentAmount(state, action: PayloadAction<number>) {
      state.userActions.topUpPaymentAmount = action.payload;
    },
    setTopUpDataAmount(state, action: PayloadAction<number>) {
      state.userActions.topUpDataAmount = action.payload;
    },
    setAutopayToEnable(state, action: PayloadAction<boolean>) {
      state.userActions.autopayToEnable = action.payload;
    },
    setExtensionDate(state, action: PayloadAction<string>) {
      state.userActions.extensionDate = action.payload;
    },
    setExtensionType(state, action: PayloadAction<ExtensionType>) {
      state.userActions.extensionType = action.payload;
    },
    setPlanChangeName(state, action: PayloadAction<string>) {
      state.userActions.planChangeName = action.payload;
    },
    setPlanChangeServiceIProduct(state, action: PayloadAction<number>) {
      state.userActions.planChangeServiceIProduct = action.payload;
    },
    setPlanChangeRecurringIProduct(state, action: PayloadAction<number>) {
      state.userActions.planChangeRecurringIProduct = action.payload;
    },
    setAvailablePlans(state, action: PayloadAction<Plan[]>) {
      let mappedPlans = action.payload.map((p) => ({
        ...p,
        end_user_description: tryToParse(p.end_user_description),
      }));
      state.availablePlans = mappedPlans;
    },
    setXdrId(state, action: PayloadAction<number>) {
      state.xdrId = action.payload;
    },
    setDeactivated(state, action: PayloadAction<boolean>) {
      state.deactivated = action.payload;
    },
  },
});

export const {
  setSelectedAccount,
  setLoggedIn,
  setAccounts,
  setFirstName,
  setLastName,
  setEmail,
  setAutoPayment,
  setPaymentMethodInfo,
  setPaymentAmount,
  setAutopayToEnable,
  setExtensionDate,
  setExtensionType,
  setInternationalCallingPaymentAmount,
  setTopUpDataAmount,
  setTopUpPaymentAmount,
  setPlanChangeName,
  setPlanChangeServiceIProduct,
  setPlanChangeRecurringIProduct,
  setAvailablePlans,
  setXdrId,
  setDeactivated,
} = userSlice.actions;
export default userSlice.reducer;
