import { FontAwesome } from '@expo/vector-icons';
import { useState } from 'react';
import { Platform, Pressable, Text, View } from 'react-native';
import Colors from '../constants/Colors';
import CommonStyles from '../constants/CommonStyles';
import FontFamilys from '../constants/FontFamilys';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';

type AppCardProps = {
  helpText?: string[];
  helpTitleText?: string;
  children: React.ReactNode;
}

export function AppCard(props: AppCardProps) {
  const [helpVisible, setHelpVisible] = useState(false);
  return (
    <>
      <View style={{ ...CommonStyles.card, padding: 0, borderColor: Colors.green, borderWidth: 1 }}>
        {Platform.OS === 'web' && props.helpText &&
          <Pressable onHoverIn={() => setHelpVisible(true)} onHoverOut={() => setHelpVisible(false)} style={{ position: 'absolute', right: 0, marginTop: -15, marginRight: -15, zIndex: 1 }}>
            <View style={{ width: 30, height: 30, backgroundColor: Colors.green, borderRadius: 30, justifyContent: 'center', alignItems: 'center' }} >
              <FontAwesome name="question" size={20} color={Colors.white}></FontAwesome>
            </View>
          </Pressable>
        }
        {helpVisible && props.helpText && props.helpText.length > 0 &&
          <View style={{ padding: Sizes.large }}>
            {props.helpTitleText &&
              <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold, marginBottom: Sizes.medium }}>{props.helpTitleText}</Text>
            }

            {props.helpText?.map((h, i) => {
              return (<Text key={i} style={{ ...TextStyles.paragraph, marginBottom: i + 1 === props.helpText?.length ? 0 : Sizes.medium }}>{h}</Text>)
            })}
          </View>
        }
        {!helpVisible && <>{props.children}</>}
      </View>
    </>
  );
}
