import { useState } from 'react';
import { ScrollView, View, Text } from 'react-native';
import { AppAlert } from '../components/AppAlert';
import { AppButton } from '../components/AppButton';
import { AppColumn } from '../components/AppColumn';
import { AppContainer } from '../components/AppContainer';
import { AppFooter } from '../components/AppFooter';
import { AppGap } from '../components/AppGap';
import { AppHeader } from '../components/AppHeader';
import { AppRow } from '../components/AppRow';
import { AppTextBackButton } from '../components/AppTextBackButton';
import { AppTextInput } from '../components/AppTextInput';
import Colors from '../constants/Colors';
import CommonStyles from '../constants/CommonStyles';
import FontFamilys from '../constants/FontFamilys';
import Heights from '../constants/Heights';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import useIsMobile from '../hooks/useIsMobile';
import { signUpService } from '../services/SignUpService';
import { RootStackScreenProps } from '../types';

export default function SignUpScreen({ navigation }: RootStackScreenProps<'SignUp'>) {
  const isMobile = useIsMobile();
  const [loading, setLoading] = useState(false);

  // step 1
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  // step 2
  const [password, setPassword] = useState('');
  const [signUpError, setSignUpError] = useState('');

  const [step, setStep] = useState(1);
  const handleStep = async () => {
    setLoading(true);
    switch (step) {
      case 1:
        setEmailError('')
        var response = await signUpService.validateEmail({ data: { email: email } });
        if (!response || !response.success) {
          setEmailError(response.error.message);
          console.error(response.error);
          break;
        }
        setStep(step + 1);
        break;
      case 2:
        setSignUpError('')
        var response = await signUpService.signUp({ data: { email: email, password: password } });
        if (!response || !response.success) {
          setSignUpError(response.error.message);
          console.error(response.error);
          break;
        }
        navigation.navigate("SignupSuccess");
        break;
    }
    setLoading(false);
  }

  return (
    <>
      {!isMobile &&
        <>
          <AppHeader onlyLogo={true} ></AppHeader>
          <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.scrollViewPaddingTop }}>
            <View style={{ ...PageLayoutStyles.container }}>
              <View style={{ ...CommonStyles.card, padding: 0, borderColor: Colors.green, borderWidth: 2, maxWidth: '80%', margin: 'auto' }}>
                <AppContainer>
                  <AppRow>
                    <AppColumn>
                      <View style={{ alignItems: 'center', justifyContent: 'space-between', padding: Sizes.extraLarge, borderTopLeftRadius: 7, flex: 1, borderBottomLeftRadius: 7, backgroundColor: Colors.green }}>
                        <Text style={{ color: Colors.white, textAlign: 'center', fontFamily: FontFamilys.bold, fontSize: 30 }}>Welcome Back!</Text>
                        <Text style={{ ...TextStyles.subtitle, color: Colors.white, textAlign: 'center', fontFamily: FontFamilys.regular }}>To stay connected with us please login with your personal info.</Text>
                        <AppButton outline color={Colors.white} text="Sign In" onPress={() => navigation.popToTop()}></AppButton>
                      </View>
                    </AppColumn>

                    <AppColumn>
                      <View style={{ alignItems: 'center', justifyContent: 'space-between', padding: Sizes.extraLarge, flex: 1 }}>
                        <Text style={{ color: Colors.black, fontFamily: FontFamilys.bold, fontSize: 30 }}>Create an Account</Text>
                        <View>
                          {step === 1 &&
                            <>
                              <Text style={{ ...TextStyles.paragraph, color: Colors.black, marginTop: Sizes.large, marginBottom: Sizes.small }}>1. Enter your email address</Text>
                              <AppTextInput value={email} placeholder="Email address" onChangeText={(text) => setEmail(text)}></AppTextInput>
                              {emailError.length > 0 && (
                                <View style={{ marginVertical: Sizes.medium }}>
                                  <AppAlert text={emailError}></AppAlert>
                                </View>
                              )}
                            </>
                          }
                          {step === 2 &&
                            <>
                              <Text style={{ ...TextStyles.paragraph, color: Colors.black, marginTop: Sizes.large, marginBottom: Sizes.small }}>2. Choose a password</Text>
                              <AppTextInput value={password} placeholder="Password" onChangeText={(text) => setPassword(text)} secureTextEntry></AppTextInput>
                              {signUpError.length > 0 && (
                                <View style={{ marginVertical: Sizes.medium }}>
                                  <AppAlert text={signUpError}></AppAlert>
                                </View>
                              )}
                            </>
                          }
                          <AppGap direction="vertical" size={20}></AppGap>
                        </View>

                        <AppButton text={step === 1 ? "Continue" : "Sign Up"} onPress={handleStep} loading={loading}></AppButton>
                        {step > 1 &&
                          <AppTextBackButton onPress={() => setStep(step - 1)}></AppTextBackButton>
                        }
                      </View>
                    </AppColumn>

                  </AppRow>
                </AppContainer>
              </View>
            </View>
          </ScrollView>
          <AppFooter></AppFooter>
        </>
      }
      {isMobile &&
        <ScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}>
          <View style={{ ...PageLayoutStyles.container, paddingTop: Sizes.none, paddingBottom: Heights.signUpViewPaddingBottom }}>
            <Text style={{ ...TextStyles.title, color: Colors.white }}>Sign Up</Text>
            <Text style={{ ...TextStyles.subtitle, fontFamily: FontFamilys.regular, color: Colors.white }}>Sign into your PhoneBox account and keep track of all your account information.</Text>
            {step === 1 &&
              <>
                <Text style={{ ...TextStyles.subtitle, fontFamily: FontFamilys.regular, color: Colors.white, marginTop: Sizes.large, marginBottom: Sizes.small }}>1. Enter your email address</Text>
                <AppTextInput keyboardType='email-address' placeholder="Email address" onChangeText={(text) => setEmail(text)}></AppTextInput>
                {emailError.length > 0 && (
                  <View style={{ marginVertical: Sizes.medium }}>
                    <AppAlert text={emailError}></AppAlert>
                  </View>
                )}
              </>
            }
            {step === 2 &&
              <>
                <Text style={{ ...TextStyles.subtitle, fontFamily: FontFamilys.regular, color: Colors.white, marginTop: Sizes.large, marginBottom: Sizes.small }}>2. Choose a password</Text>
                <AppTextInput placeholder="Password" onChangeText={(text) => setPassword(text)} secureTextEntry></AppTextInput>
                {signUpError.length > 0 && (
                  <View style={{ marginVertical: Sizes.medium }}>
                    <AppAlert text={signUpError}></AppAlert>
                  </View>
                )}
              </>
            }
            <View style={{ marginTop: Sizes.large }}>
              <AppButton text={step === 1 ? "Continue" : "Sign Up"} onPress={handleStep} color={Colors.white} outline></AppButton>
            </View>
          </View>
        </ScrollView>
      }
    </>
  );
}
