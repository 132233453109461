import { StyleSheet } from 'react-native';
import Colors from './Colors';

const styles = StyleSheet.create({
    container: {
        padding: 35,
    },
    card: {
        backgroundColor: '#fff',
        borderRadius: 10,
        padding: 20,
    },
    cardBody: {
        padding: 20
    }
});

export default styles;