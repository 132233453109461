import { View, ActivityIndicator } from 'react-native';
import Colors from '../constants/Colors';
import useIsMobile from '../hooks/useIsMobile';

export function MiniLoading() {
  const isMobile = useIsMobile();
  return (
    <View style={{ height: 100, width: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: Colors.white }}>
      <ActivityIndicator size="large" color={isMobile ? Colors.white : Colors.blue}></ActivityIndicator>
    </View>
  );
}