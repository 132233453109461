import { StyleSheet, View, Text } from 'react-native';
import FontFamilys from '../constants/FontFamilys';
import TextStyles from '../constants/TextStyles';
import { AppCurrencyPill } from './AppCurrencyPill';

type AppPriceProps = {
  price: number;
  interval: string;
  color: string;
  currency: 'CAD' | 'USD';
  size?: string;
}

export function AppPrice(props: AppPriceProps) {
  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
    },
    currency: {
      fontSize: 18,
      fontFamily: FontFamilys.bold,
      color: props.color,
      marginTop: props.size === 'md' ? 4 : 8
    },
    interval: {
      marginTop: props.size === 'md' ? 8 : 14,
      marginLeft: 3
    }
  });
  return (
    <View style={styles.container}>
      <Text style={styles.currency}>$</Text>
      <Text style={ props.size === 'md' ? { ...TextStyles.superSubtitle, color: props.color } : { ...TextStyles.title, color: props.color }}>{props.price}</Text>
      <View style={styles.interval}>
        <AppCurrencyPill text={props.currency}></AppCurrencyPill>
        <Text style={{ ...TextStyles.subtitle, color: props.color, fontSize: 10 }}>{props.interval}</Text>
      </View>
    </View>
  );
}
