import { FontAwesome } from '@expo/vector-icons';
import { ScrollView, StyleSheet, View, Text } from 'react-native';
import { AppButton } from '../components/AppButton';
import Colors from '../constants/Colors';
import FontFamilys from '../constants/FontFamilys';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import { RootStackScreenProps, RootTabScreenProps } from '../types';
type BulletPointProps = {
  indented?: boolean;
}
export default function TermsAndConditionsScreen({ navigation }: RootStackScreenProps<'TermsAndConditions'>) {

  const BulletPoint = (props?: BulletPointProps) => {
    return <View style={{ marginTop: 8 }}>
      <FontAwesome name={props?.indented ? 'circle-o' : 'circle'}></FontAwesome>
    </View>
  };

  return (
    <ScrollView>
      <View style={{ ...PageLayoutStyles.container, paddingTop: Sizes.none }}>
        <Text style={styles.title}>Terms and Conditions</Text>

        <Text style={styles.paragraph}>Thanks for choosing PhoneBox. Please read these Terms & Conditions, which contain important information about your relationship with PhoneBox, including mandatory arbitration of disputes between us, instead of class actions or jury trials. You will become bound by these provisions once you accept these Terms and Conditions.</Text>
        <Text style={styles.paragraph}>Any service ("the Service(s)") offered on this website is a service of PhoneBox, (“PhoneBox"), one the best network providers.</Text>
        <Text style={styles.paragraph}>By visiting and shopping at Gophonebox.com, you accept these Terms and Conditions. These general Terms and Conditions describe the rights and obligations of PhoneBox and its Customers concerning the delivery, performance and use of the Services offered by Gophonebox.com.</Text>
        <Text style={styles.paragraph}>For purposes of these Terms and Conditions, "Customer" means a person or organization that purchases the Services available on PhoneBox’s website for private and personal use, starting from the creation of an Account with us (registration) and the activation and use of our PhoneBox SIM card. We also use the terms "you," "your" or "yours" to refer to customers herein. The terms "we," "us" or "our" refer to PhoneBox and its affiliates.</Text>
        <Text style={styles.paragraph}>By ordering a Service from PhoneBox, you accept these Terms and Conditions, along with all policies, price lists, and other documents referenced herein (collectively, "Service Documents"), as the same may be posted from time to time.</Text>
        <Text style={styles.paragraph}>The use of this website and any products or services offered by PhoneBox is expressly conditioned on your acceptance of all Terms and Conditions stated herein and any other place on this website. DO NOT USE THIS WEBSITE OR THE SERVICES if you do not accept all the terms and conditions stated herein and elsewhere other places on this website, including but not limited to: Frequently Asked Questions, Privacy Policy.</Text>
        <Text style={styles.paragraph}>BY ACCESSING OR USING THE SITE IN ANY MANNER, INCLUDING, BUT NOT LIMITED TO, VISITING OR BROWSING THE SITE OR CONTRIBUTING CONTENT OR OTHER MATERIALS TO THE SITE, YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS.</Text>
        <Text style={styles.paragraph}>PhoneBox services that you subscribe to or purchase under a service agreement or receive from or through PhoneBox, including mobile services (“Services”); and any device or equipment used to access the Services or used with the Services (“Equipment”).</Text>

        <Text style={styles.subtitle}>Changes and Updates</Text>
        <Text style={styles.paragraph}>We may change these Terms and Conditions and/or any Service Documents from time to time. We will keep these Terms and Conditions and Service Documents up to date, and will use commercially reasonable efforts to post changes to the website prior to the effective date of such changes; provided, that we reserve the right to make changes on shorter or no notice. So, please revisit them from time to time. By continuing to use our Service after a change, you have accepted the changes implemented.</Text>
        <Text style={styles.paragraph}>PhoneBox may discontinue, change, or suspend any aspect of the website at any time. PhoneBox may change, discontinue or suspend the availability of any database, content, feature, Service, or product offered on the website, or the terms of offering the same (including, without limitation, pricing), at any time.</Text>
        <Text style={styles.paragraph}>PhoneBox may change different aspects of its Services and features without notice or liability.</Text>
        <Text style={styles.paragraph}>In no event shall PhoneBox, its employees, officers, representatives, service providers, suppliers, licensors, and agents be liable for any direct, special, indirect, incidental, exemplary, punitive or consequential damages, or any other damages of any kind, including but not limited to, loss of use, loss of profits, or loss of data, whether in an action in contract, tort (including but not limited to negligence), or otherwise, arising out of or in any way connected with (i) the use or inability to use the PhoneBox websites or the content, materials, software, information or transactions provided on or through the PhoneBox websites, or (ii) any claim attributable to errors, omissions, or other inaccuracies in the PhoneBox websites or the content, materials, software, information, products, or services on or available through these websites, (iii) the cost of procurement of substitute goods and services resulting from any products, data, information or services purchased or obtained or messages received or transactions entered into through or from our website; (iv) unauthorized access to or alteration of your transmissions or data; (v) statements or conduct of any third party on our website; (vi) the delay or failure in performance resulting from an act of force majeure, including without limitation, acts of god, natural disasters, communications failure, governmental actions, wars, strikes, labor disputes, riots, shortages of labor or materials, vandalism, terrorism, non-performance of third parties or any reasons beyond their reasonable control; or (vii) any other matter relating to our website, even if PhoneBox or its authorized representatives have been advised of the possibility of such damages. Your sole remedy for dissatisfaction with the website and/or PhoneBox Services is to stop using the website and/or those Services, and to terminate your Services as provided for herein.</Text>
        <Text style={styles.paragraph}>By accepting the Services, you acknowledge and agree that the Services are provided by using or interconnecting with the networks and services of third parties, those third parties may change their rates, terms, practices and/or coverage areas, and the terms of your Services will be modified to reflect such changes by third parties. PhoneBox has no control over such third parties, and will not be liable to you for any costs, damages, losses, or impacts of any kind due to the acts or omissions of third parties.</Text>

        <Text style={styles.subtitle}>Use of service</Text>
        <Text style={styles.paragraph}>By using PhoneBox Services, the user accepts the rates, Terms and Conditions and procedures on this website. PhoneBox may change or modify the Terms and Conditions from time to time without notice other than posting amended Terms and Conditions on this Website. PhoneBox reserves the right to change, modify or discontinue, temporarily or permanently, the Website (or any portion thereof), including any and all content contained on the Website, at any time without notice. You agree that PhoneBox shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Website.</Text>

        <Text style={styles.subtitle}>Active phones & phone numbers</Text>
        <Text style={styles.paragraph}>If a Customer enrolls a new SIM on a line with an existing active number, the PhoneBox Service will no longer be active on the previous SIM enrolled on that line and will be available only on the newly enrolled SIM. An active number can only be connected to one SIM, so PhoneBox Customers can only enjoy the PhoneBox Service on one single SIM per line. When a line is no longer active, PhoneBox will re-assign the phone number associated with that line, and that phone number may no longer be available to the previous account holder, unless the Customer has ported the number to a third-party carrier consistent with applicable law and PhoneBox policies.</Text>

        <Text style={styles.subtitle}>Billing policy</Text>
        <Text style={styles.paragraph}>All sessions (data, calls, text (SMS, MMS) are debited directly from your balance (Plan or Pay As You Go) as soon as the records for the sessions are available from our providers. Taking this into account, delays in charges may appear.</Text>
        <Text style={styles.paragraph}>Whatever the delay, charges will be deducted from the available balance (Plan or Pay As You Go (Prepaid)) or, if the balance is not enough, as soon as the account balance gets updated (Plan renewal, Plan purchase or Pay As You Go (Prepaid) Top Up). You acknowledge and agree that the rates for any Service, including Service to or from any country, may change from time to time, and that such changes will be reflected in the charges that are deducted from your account balance.</Text>

        <Text style={styles.subtitle}>Billing Cycle</Text>
        <Text style={styles.paragraph}>You agree to pay all charges we bill you or that were accepted or processed through your Device. All PhoneBox Service plans are measured on 30-day monthly (Prepaid) cycles (including for purposes of determining your data usage levels).</Text>
        <Text style={styles.paragraph}>We may offer you the opportunity to set up automatic payments through the method of payment on your account, which payments will be automatically charged at the end of your applicable payment cycle (“Auto Renew”). When you enroll in Auto Renew, automatic payments for plan fees, or new plan fees occur due to plan changes that you incur will continue until you cancel.</Text>
        <Text style={styles.paragraph}>The recurring charge may vary if you change your plan or we change our prices, following notice to you. You may cancel Auto Renew or review your payment preferences at any time by (i) contacting our customer service department at 1-855-886-0505, or (ii) managing your account preferences on the PhoneBox website or PhoneBox Mobile Application. You must change your Auto Renew preferences or cancel 24 hours prior to the end of your payment cycle in order for the change to go into effect. No minimum purchase is necessary to enroll in Auto Renew.</Text>

        <Text style={styles.subtitle}>9-1-1 Limitations of Service</Text>

        <Text style={styles.paragraph}>Public safety officials advise that when making 911 or other emergency calls, you should always be prepared to provide your location information. Unlike traditional wireline phones, depending on a number of factors (for example, whether your Device is GPS-enabled, where you are, whether local emergency service providers have upgraded their equipment, etc.), 911 operators may not know your phone number, your location, or the location of your Device. In certain circumstances, an emergency call may be routed to a state patrol dispatcher or alternative location set by local emergency service providers. Enhanced 911 service ("E911")—where enabled by local emergency authorities—uses GPS technology to provide location information. Even when available, however, E911 does not always provide accurate location information. If your Device is indoors or for some other reason cannot acquire a satellite signal, you may not be located. Some Devices have a safety feature that prevents use of the keypad after dialing 911—you should follow voice prompts when interacting with emergency service providers employing interactive voice response systems to screen calls.</Text>
        <Text style={styles.paragraph}>1. Relocation of End User Devices. If Customer uses the Service in a location other than the Registered Location for that device, 9-1-1 calls may not be routed to the appropriate Public Safety Answering Point ("PSAP") for the end user’s current physical location.</Text>
        <Text style={styles.paragraph}>2. Use of “Non-Native” Telephone Numbers. If Customer uses the Service with an assigned telephone number that is outside the rate center of the Registered Location, the PSAP for the Registered Location may not recognize the telephone number for call-back or other informational purposes.</Text>
        <Text style={styles.paragraph}>3. Broadband Connection Failures. The Service will not be able to make calls if the Customer loses connectivity to the Internet. Due to Internet congestion and network design issues, 9-1-1 calls placed through the Service may sometimes produce a busy signal, experience unexpected answering wait times, or take longer to answer than 9-1-1 calls placed through traditional telephone networks.</Text>
        <Text style={styles.paragraph}>4. Loss of Electrical Power. The Service will not operate if the Customer has lost electric power for the Service or for other Service-enabling equipment. After a power outage, customers may need to reset or reconfigure enabling equipment or devices before being able to use the Service.</Text>
        <Text style={styles.paragraph}>5. Updating Registered Locations in ALI Databases. If Customer does not correctly identify the physical location of the Service when defining the Registered Location, 9-1-1 calls through the Service may not reach the correct PSAP. At initial activation of the Service, and following any update to Registered Locations, there may be some delay before complete and accurate information is passed to the local emergency service operator.</Text>
        <Text style={styles.paragraph}>6. Registered Locations. PhoneBox relies on the Registered Location of the Service at the time a 9-1-1 call is placed to route the call to the appropriate PSAP within the domestic U.S. and to provide the PSAP with your location. Your device may automatically attempt to determine the Registered Location information based on its location. You should verify and update the Registered Location information regularly and when accessing a Wi-Fi network with the same name (SSID) that may exist in multiple locations. Registered Location information is not automatically updated for a location with the same network name or for other non-cellular devices being used with the Service. If Customer does not update the Registered Location or it is not complete, PhoneBox may attempt to route a 9-1-1 call based on earlier Registered Location information, which may not match Customer’s actual location and may cause a 9-1-1 call to be misrouted and/or provide a PSAP with incorrect location information. Always be prepared to provide your actual location to a call taker.</Text>
        <Text style={styles.paragraph}>7. PSAP Limitations. The PSAP designated to receive 9-1-1 calls for a particular Registered Location through the Service may not have a system configured for all 9-1-1 services. The PSAP may not be able to capture, retain or otherwise determine the phone number, Registered Location, or physical location of the VoIP device placing the 9-1-1 call. Accordingly, customers must be prepared to provide this information to the PSAP. Until and unless Customer does so, the emergency service operator may be unable to call Customer back or to otherwise assist Customer in the event of an emergency.</Text>
        <Text style={styles.paragraph}>8. Customer Choice. Each Customer must carefully evaluate the individual circumstances in deciding whether to rely solely upon the Service for 9-1-1 calling or to make necessary provisions for access to emergency calling services (e.g. maintaining a conventional landline phone as a backup means of completing emergency calls).</Text>
        <Text style={styles.paragraph}>9. Other Limitations. In addition to 9-1-1 limitations, the Service does not support Wireless Emergency Alerts and may not support other wireless products and services. Other non-cellular devices capable of Wi-Fi Calling may not support a 9-1-1 call over a wireless carrier network and may be limited to Wi-Fi Calling capabilities only, if available and connected. The primary device user can deactivate Service on all other associated devices at any time and thereby eliminate Wi-Fi Calling, including 9-1-1 calling, from those devices.</Text>
        <Text style={styles.paragraph}>10. Limitation of Liability and Release. PhoneBox is not liable for any failure to access emergency services when using the Service. Customer hereby releases and discharges PhoneBox, its affiliates and subsidiaries, and their respective officers, directors, employees, agents and vendors affiliated with the Service from any and all claims, losses, damages, fines, penalties, costs, expenses and liability arising out of Customer’s or any third party’s use of the Service, and hereby acknowledges that PhoneBox is not liable for any failure or outage of the Service, including those related to 9-1-1 dialing in the USA and Emergency Services Calling in other countries where the Service is permitted by PhoneBox. This release and waiver extends to all claims of any kind or nature whether foreseen, known or unknown.</Text>

        <Text style={styles.title}>Acceptable use of service</Text>
        <Text style={styles.paragraph}>When joining PhoneBox, you also agree to comply with our usage policy, which means you can use our Service for:</Text>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Voice calls;</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Web browsing;</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Sending and receiving messages;</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Sending and receiving emails;</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Uploading and downloading applications and content;</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Streaming music;</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Sharing your data with other devices pursuant to the allotments of your Data Plan, subject to the limitations set forth below, including PhoneBox’s Network Management Practices:</Text>
        </View>
        <View style={styles.indentedBulletPointContainer}>
          <BulletPoint indented></BulletPoint>
          <Text style={styles.bulletPointText}>We prioritize smartphone and mobile internet (tablet) over Smartphone Mobile HotSpot (tethering) traffic on our network.</Text>
        </View>
        <View style={styles.indentedBulletPointContainer}>
          <BulletPoint indented></BulletPoint>
          <Text style={styles.bulletPointText}>We may limit the number of devices that can be tethered</Text>
        </View>
        <View style={styles.indentedBulletPointContainer}>
          <BulletPoint indented></BulletPoint>
          <Text style={styles.bulletPointText}>The Service can’t be used to establish a continuous, unattended connection</Text>
        </View>
        <View style={{...styles.indentedBulletPointContainer, marginBottom: 20}}>
          <BulletPoint indented></BulletPoint>
          <Text style={styles.bulletPointText}>PhoneBox may take any reasonable action which it deems in its sole discretion to be a potential threat to its network or other users, including limiting or terminating tethering/hotspot</Text>
        </View>

        <Text style={styles.paragraph}>However, there are some specific things that we don’t want you to use PhoneBox service for, including, but not limited to:</Text>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Illegal, abusive, threatening or fraudulent purposes;</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Providing altered, deceptive, or false information about the identity of the sender or the origin of a message or phone call;</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Actions which may be interpreted as harassing, defamatory, slanderous, deceptive, indecent, pornographic, libelous, invasive of another's privacy, or racially, ethnically or otherwise offensive, hateful or abusive, death threats, terroristic threats and any other uses related to terrorism;</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Sending mass unsolicited email (SPAM), or other types of abusive, unsolicited, or other mass automated communications, including, but not limited to: viruses, worms, Trojan horses, rootkits, password crackers, adware, or any other computer programs that may damage, interfere with, secretly intercept or seize any system, program, data, or personal information;</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Uses which consume disproportionate amounts of network capacity, causing harm or adversely affecting the network and PhoneBox customers, who can suffer from degraded service when one user consumes an unreasonable amount of resources;</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Actions generating unusually high usage;</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Using a repeater or signal booster other than one we provide to you;</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Using the Service in a manner that compromises network security or capacity, degrades network performance, uses malicious software or "malware", hinders other Customers’ access to the network, or otherwise adversely impacts network service levels or legitimate data flows;</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Using applications which automatically consume unreasonable amounts of available network capacity;</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Using applications which are designed for unattended use, automatic data feeds, automated machine-to-machine connections, or applications that are used in a way that degrades network capacity or functionality;</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Accessing the accounts of others without authority;</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Using the Service in a way that results in unusually high usage (specifically, more than 20GB (updated periodically) in a month) and the majority of your data usage being Smartphone Mobile HotSpot (tethering) usage for any 3 billing cycles within any 6-month period;</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Reselling the Service, either alone or as part of any other good or service; without prior written agreements between you and PhoneBox</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Tampering with, reprograming, altering, or otherwise modifying your device to circumvent any of our policies or violate anyone’s intellectual property rights;</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Causing harm or adversely affects us, the network, our customers, employees, business, or any other person;</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Uses that conflict with applicable law;</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Uses not in accordance with these Terms & Conditions</Text>
        </View>
        <View style={{...styles.bulletPointContainer, marginBottom: 20}}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Attempting or helping other people in any of the activities mentioned above.</Text>
        </View>

        <Text style={styles.paragraph}>We reserve the right to add new types of uses we consider unacceptable. We also reserve the right to act immediately and without notice to restrict, suspend, or terminate your use of the Service in case you have disregarded the rules above. But the list of permitted uses is so broad and generous, that you don’t need to worry. In fact, the unacceptable uses usually conflict with applicable law, so we are also trying to protect you against legal problems.</Text>
        
        <Text style={styles.subtitle}>Violation of Acceptable Use Policy</Text>
        <Text style={styles.paragraph}>No refunds will be provided if the service is terminated for violation of the acceptable use policy.</Text>
        
        <Text style={styles.subtitle}>Performance with PhoneBox’s "Prepaid" plan</Text>
        <Text style={styles.paragraph}>The Prepaid data option provides call, text, and data usage at a regular monthly rate per line (promotional rates may vary). Customers will receive 4G LTE/5G data speeds for up to certain capacity of data per billing period (varies depending on the plan that our customer chooses during sign up or plan change). Your data speed will be reduced to 256 Kbps speeds once you have used up all of the data (reaches the maximum data capacity) in a billing period. You can downgrade or upgrade your prepaid plan at any time, with no change fees.</Text>
        <Text style={styles.paragraph}>PhoneBox Prepaid Plans with international long-distance service are provided solely for live dialogue between and initiated by, two individuals for personal use and not for non-residential use or resale. The limit for international calls is set at 300 min/30-days billing cycle and this balance is not rolled over. In case of abuse of usage, we reserve the right to suspend, terminate or restrict your International Long Distant (ILD) Services without any prior notice. We further reserve the right to add or remove countries from the list of those for which Prepaid Plans are available, to discontinue Services to any country, or to revise the number of minutes.</Text>

        <Text style={styles.subtitle}>Network Management</Text>
        <Text style={styles.paragraph}>Generally, PhoneBox does not inhibit the ability of our Customers to use mobile data. However, like many service providers, PhoneBox employs reasonable network management practices consistent with industry standards to maintain the reliability of its network and minimize network issues and complications, and these practices are subject to change. Specifically, PhoneBox currently manages its network in the following ways:</Text>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Traffic identified as malicious (such as distributed denial of service attacks) may be automatically identified and blocked</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>In the event of network congestion in which the supporting technology determines that Service is likely to be degraded for Customers, PhoneBox manages its network resources to prioritize certain communications in order to avoid disruption of those Services. Otherwise, network management is non-discriminatory and agnostic to the application.</Text>
        </View>
        <View style={{...styles.bulletPointContainer, marginBottom: 20}}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Certain Services are subject to limitations, detailed herein</Text>
        </View>

        <Text style={styles.subtitle}>Termination of service</Text>
        <Text style={styles.paragraph}>WE RESERVE THE RIGHT TO ISSUE A WARNING OR TO SUSPEND OR EVEN TERMINATE YOUR SERVICE AND ACCESS TO OUR WEBSITE OR APPLICATIONS IF WE DETERMINE YOU HAVE VIOLATED ANY OF THE TERMS OF SERVICE LISTED ON THIS PAGE OR IN ANY SERVICE DOCUMENT, ANY OF THE RULES OR POLICIES APPLICABLE TO THE USE OF THIS SITE, OR FOR ANY OTHER REASON.</Text>
        <Text style={styles.paragraph}>You Agree That We Are Not Responsible For Certain Problems</Text>
        <Text style={styles.paragraph}>You agree that neither we nor our parent, subsidiary, or affiliate companies, nor our vendors, suppliers, or licensors are responsible for any damages, delay, interruption or other failure to perform resulting from: (a) anything done or not done by someone else; (b) providing or failing to provide Services, including, but not limited to, deficiencies or problems with a Device or network coverage (for example, dropped, blocked, interrupted Services, etc.); (c) traffic or other accidents, or any health-related claims relating to our Services; (d) Data Content or information accessed while using our Services; (e) an interruption or failure in accessing or attempting to access emergency services from a Device, including through 911, Enhanced 911 or otherwise; (f) interrupted, failed, or inaccurate location information services; (g) information or communication that is blocked by a spam filter; (h) damage to your Device or any computer or equipment connected to your Device, or damage to or loss of any information stored on your Device, computer, equipment, or PhoneBox storage space from your use of the Services or from viruses, worms, or downloads of malicious content, materials, data, text, images, video, or audio; or (i) things beyond our control, including acts of God (for example, weather-related phenomena, fire, earthquake, hurricane, etc.), riot, strike, war, terrorism, or government orders or acts. You should implement appropriate safeguards to secure your Device, computer, or equipment and to backup your information stored on each.</Text>
        <Text style={styles.paragraph}>You Agree That Our Liability Is Limited - No Consequential Damages</Text>
        <Text style={styles.paragraph}>TO THE EXTENT ALLOWED BY LAW, OUR LIABILITY FOR MONETARY DAMAGES FOR ANY CLAIMS THAT YOU MAY HAVE AGAINST US IS LIMITED TO NO MORE THAN THE PROPORTIONATE AMOUNT OF THE SERVICE CHARGES ATTRIBUTABLE TO THE AFFECTED PERIOD. UNDER NO CIRCUMSTANCES ARE WE LIABLE FOR ANY INCIDENTAL, CONSEQUENTIAL, PUNITIVE, MULTIPLE, OR SPECIAL DAMAGES OF ANY NATURE WHATSOEVER ARISING OUT OF OR RELATED TO PROVIDING OR FAILING TO PROVIDE SERVICES IN CONNECTION WITH A DEVICE, INCLUDING, BUT NOT LIMITED TO, LOST PROFITS, LOSS OF BUSINESS, OR COST OF REPLACEMENT PRODUCTS AND SERVICES.</Text>

        <Text style={styles.subtitle}>PhoneBox SIM</Text>
        <Text style={styles.paragraph}>Unless specifically stated otherwise on our website, the Service is available only for persons located within the United States including (Puerto Rico and U.S. Virgin Islands).</Text>
        <Text style={styles.paragraph}>Roaming is not available, and our Service can not be used outside US territory.</Text>
        <Text style={styles.paragraph}>Standard delivery period: when ordering a PhoneBox SIM (except eSIM) may take 3-5 business days, but no later than 7 business days. No delivery will be made on weekends or national holidays.</Text>
        <Text style={styles.paragraph}>PhoneBox SIMs, as well as eSIM, purchased on Gophonebox.com are strongly recommended to be activated by the Customer in the Gophonebox.com online account by the time of purchase, otherwise the SIM may expire and it will be unusable for future activations. </Text>
        <Text style={styles.paragraph}>No refund of the SIM price will be granted in case of such expiry, defective or any other reason that prevents users from using the SIM card or eSIM.</Text>
        <Text style={styles.paragraph}>SIM format is delivered as specified by the Customer during checkout (micro or nano). If another format is needed upon delivery, additional charges may apply.</Text>
        <Text style={styles.paragraph}>PhoneBox Mobile cannot be held liable for the processing and fulfillment of SIM orders placed on third-party websites (such as Amazon.com).</Text>

        <Text style={styles.subtitle}>Pay As You Go (Prepaid) Service Agreement</Text>
        <Text style={styles.paragraph}>Pay As You Go (Prepaid) account activation is available for a period of 30 days since Customer's last Pay As You Go (Prepaid) order. If no order was placed or extended in a period of 30 days, Pay As You Go (Prepaid) account will expire and the PhoneBox Service will be disconnected.</Text>
        <Text style={styles.paragraph}>All sales are final. Balances once paid are non-refundable, non-transferable and non-exchangeable.</Text>
        <Text style={styles.paragraph}>Call details provided by Customer Support agents regarding the usage of a prepaid credit are final and will be considered authentic record/proof of the usage of the Pay As You Go (Prepaid) balance.</Text>
        <Text style={styles.paragraph}>Pay As You Go (Prepaid) credit can be used for data usage, plan extension and international calls and texts.</Text>
        <Text style={styles.paragraph}>Our final rates may be updated at any time and will be listed on our Pay As You Go - Rates (Prepaid) page. Customers are fully responsible for all charges related to Auto Recharge which includes but are not limited to Auto-Payment, Auto-Extension, Auto-Data Top Up. Once this feature is activated, Customers agree to the charges that will apply on the credit/debit card when their Pay As You Go (Prepaid) balance drops below the set amount.</Text>

        <Text style={styles.subtitle}>Termination and Reactivation of service</Text>
        <Text style={styles.paragraph}>Once the line is terminated, the client may retrieve their Account, with the same Phone number within 30 days from the Term Expiry Date with no additional charges. If the reactivation (process of retrieving an expired Prepaid account) exceeds 30 days to 90 days, additional Reactivation Service Fee will apply with a cost of USD $10. Please note that PhoneBox reserves the right to make changes to the Reactivation Service Fee at any point in time without prior notice. Please visit our website's terms and conditions for new updates. </Text>
        <Text style={styles.paragraph}>After the 90 days period, counting from the Prepaid term expiry date, will no longer be retrievable. PhoneBox will reuse the phone number and apply to a new Customer’s account. No refund is available under a Prepaid account.</Text>
        <Text style={styles.paragraph}>Please refer to the Plans Service Agreement below for more details.</Text>

        <Text style={styles.subtitle}>Plans Service Agreement</Text>
        <Text style={styles.paragraph}>Plans are subscriptions purely designed for residential usage.</Text>
        <Text style={styles.paragraph}>If you subscribe to one of our plans, we will provide the Service solely to you for normal residential, non-commercial use. You and only those of your immediate family members who reside in your personal residence can use the Service. National Plans are provided primarily for continuous live dialog between two individuals. Unusual call patterns, excessive conferencing or call forwarding, excessive numbers and/or consistent excessive usage will be considered indicators of non-residential use. Any other indicators of impermissible usage may trigger an account review or further action by us.</Text>
        <Text style={styles.paragraph}>By subscribing to PhoneBox plans, you agree to be charged monthly (Prepaid Basis which indicates a total of 30 days). If your account is under an Auto Renewal (Auto-extension), to cancel the subscription you must cancel the Auto Renewal (Auto-Extension) Service from your account. Regardless of the number of minutes, data used you will be charged monthly (Prepaid) for your subscription. The monthly (Prepaid) fee is non-refundable.</Text>
        <Text style={styles.paragraph}>Minutes included in a plan are available for the United States only. Any call made outside of the United States will be charged from the Customer's plan balance.</Text>
        <Text style={styles.paragraph}>Plans rates are subject to change without any prior notice.</Text>
        <Text style={styles.paragraph}>Plans will be automatically renewed every 30 days, unless a Customer decides to change or renew a plan during the billing cycle, which is possible. The Customer will be charged on the spot, which initiates a new billing cycle.</Text>
        <Text style={styles.paragraph}>If the automatic billing fails, a new attempt will take place on the next day (on the 31st day of your plan). If the billing succeeds, your billing date will remain the same as before. If automatic billing fails to pay the charges on your account in full on the second attempt, your Services may be suspended or terminated, at PhoneBox’s sole discretion and your plan may be deleted.</Text>
        <Text style={styles.paragraph}>If a plan is changed or renewed during the billing cycle, any remaining balance including but are not limited to, data, international calling minutes, will roll over for the next 30 days. The billing date will not be renewed each time Customer changes a Prepaid plan.</Text>
        <Text style={styles.paragraph}>Any unused balance of the plan will expire once the usage period is over.</Text>
        <Text style={styles.paragraph}>Any change made during the billing period is effective in the next Prepaid cycle (30 days) after the request has been processed by the system.</Text>
        <Text style={styles.paragraph}>The Service can be canceled at any time from the account. Once the subscription is canceled you will no longer be charged. No refund is available under a Prepaid account.</Text>
        <Text style={styles.paragraph}>PhoneBox reserves the right to expire numbers that no longer have an active plan. Customers will receive a notification via SMS or email prior to the expiry. Your specific phone number will be reallocated to another Customer and will not be available after expiry.</Text>
        
        <Text style={styles.subtitle}>Offers and Promotions</Text>
        <Text style={styles.paragraph}>All offers, promotions, discounts or bonuses are subject to certain Terms and Conditions as listed on several locations including but are not limited to presentation page, landing page, banner on Gophonebox.com. Offers and promotions can be canceled anytime without any prior notice as stipulated in these Terms and Conditions.</Text>
        <Text style={styles.paragraph}>In case of goods, promotions will be honored while stock lasts. No refunds can be imputed to PhoneBox in case of offers no longer available or stopped without any prior notice.</Text>
        
        <Text style={styles.subtitle}>Account protection</Text>
        <Text style={styles.paragraph}>Please select a password that would not be obvious to someone trying to guess your password, and change it regularly as an added precaution. Furthermore, we recommend that you use a different password from your email account as an additional security measure. You are responsible for maintaining the confidentiality of the password and account, and you are fully responsible for all activities that occur under your password or account identification. Please note that PhoneBox password requirement or recommendations does not provide you with a guarantee or full protection towards your online account. You should make every effort to safeguard your account data. PhoneBox is not responsible for lost or stolen account data or unauthorized use. You agree to immediately notify PhoneBox of any unauthorized use of your password or account or any other breach of security. Credit/debit card fraud is a criminal offense.</Text>
        <Text style={styles.paragraph}>A security PIN can be found on all PhoneBox accounts once logged in, for better security. The security PIN gives the Customer access to request certain account changes, such as: device change, port out, account deactivation, etc.</Text>
        <Text style={styles.paragraph}>Also, for security reasons, any requests that require an action taken by our Support Team should come either from the email address registered to the account or from the PhoneBox number. Customers must be properly authenticated by Customer Support personnel prior to the release of any Customer data via phone or email.</Text>
        
        <Text style={styles.subtitle}>Transaction authorization</Text>
        <Text style={styles.paragraph}>At PhoneBox we use automated and manual systems to confirm proper credit card usage and transaction authorization. We also track every transaction. Information related to fraudulent transactions, including but not limited to IP addresses, detailed transaction records and email addresses is collected. The information will be provided to appropriate law enforcement officials consistent with applicable law when required to assist in the prosecution of any persons attempting to commit fraud on PhoneBox’s website.</Text>
        
        <Text style={styles.subtitle}>Taxes, Surcharges & Fees</Text>
        <Text style={styles.paragraph}>By visiting and shopping on this site, Customers agree to pay all applicable federal, state, local taxes, any wireless communication fees or any other surcharges or fees required billed by GophoneBox.com to its Customers. Additionally, the Customer acknowledges that international services may be subject to value-added taxes or other taxes or fees imposed by the countries in which calls originate or terminate. The customer is responsible for, and agrees to pay, all such taxes and fees imposed upon the Customer’s use of the Services.</Text>
        <Text style={styles.paragraph}>In addition to the charges billed for PhoneBox services, surcharges, taxes, fees and other charges may be applied to your invoice based on the type of service you have, and your geographical location, among other factors. Certain taxes, fees or surcharges may show up as separate line items on your invoice. Examples include, but are not limited to the following:</Text>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Federal Surcharges</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>State & Local Regulatory Surcharges</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>State & Local Sales and Use Tax</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Communications Services Tax</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Gross Receipts Taxes</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Local Utility Taxes</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Local License Taxes</Text>
        </View>
        <View style={styles.bulletPointContainer}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>E911 Fees</Text>
        </View>
        <View style={{...styles.bulletPointContainer, marginBottom: 20}}>
          <BulletPoint></BulletPoint>
          <Text style={styles.bulletPointText}>Cost Recovery Fee/Surcharge</Text>
        </View>
        
        <Text style={styles.subtitle}>Refund and payment</Text>
        <Text style={styles.paragraph}>PhoneBox is committed to customer satisfaction and offers a 100% money back guarantee. An online purchase of any PhoneBox card requires payment using a credit/debit card, PayPal account or any other form of Online Payments. PhoneBox will refund the amount for any unused product to any Customer who is dissatisfied with its Services subject to certain conditions that will prove that the product does not meet its description or failed to meet Customer's needs due to any error on our part. PhoneBox will not refund customers for the amount paid for orders older than 6 months. Please note that PhoneBox will not provide refunds in case the service has already been started and/or was not reported prior to the service starting date.</Text>
        <Text style={styles.paragraph}>If an account and phone number is no longer active, any remaining balance (Pay As You Go (Prepaid) credit or any Plan balance) in that account will be forfeited which are including but are not limited to Balance Credit, Top Up Balance, Extension balance, Plan change adjustment balance.</Text>
        <Text style={styles.paragraph}>SIM Card - PhoneBox will not offer any compensation for a SIM purchased through its website. Moreover, the SIM is not returnable and non-refundable. This includes all forms of SIM which include but are not limited to eSIM.</Text>
        <Text style={styles.paragraph}>Shipping - Priority Shipping (Express shipping) or all forms of Shipping cost are not refundable.</Text>
        
        <Text style={styles.subtitle}>Delivery Policy</Text>
        <Text style={styles.paragraph}>Any Devices or SIM card will be delivered without any shipping costs by Local Shipping Courier, mainly used via Post Office. Usually, it takes no longer than 7 - 10  business days to deliver a device or SIM card to the address specified. The shipping duration may change based upon Client’s Address, form of SIM card, quantities of SIM card. PhoneBox offers Free shipping worldwide with no cost. This form of Regular (Free) shipping does not come with a Tracking Number. For Express (Expedite) shipping, Customers will receive a tracking number and can always track an order’s shipping status in the associate shipping courier website. Delays caused by national holidays, weather conditions or transportation issues can occur, but PhoneBox will not be liable for any such delays.</Text>
        <Text style={styles.paragraph}>If for any reason a Customer decides to cancel an order or request a refund once the shipping process has already begun, Customer must contact the Customer Support Team. In the unlikely event that the items were not dispatched yet, customers should contact our Customer Support team as soon as they decide to cancel a purchase.</Text>
        <Text style={styles.paragraph}>All items are shipped to the address specified. Customers are fully responsible for any losses or delays incurred by entering incorrect or incomplete address details.</Text>
        
        <Text style={styles.subtitle}>Disputes</Text>
        <Text style={styles.paragraph}>Purchases will appear on your credit/debit card statement as "PHONEBOX NETWORK LTD”, for PayPal, the statements will be shown as “The PhoneBox LLC”. You agree not to file a credit card or debit card chargeback with regards to any purchase made on the website but instead try to resolve the issue with PhoneBox. If you do not remember making the purchase - call the number on your statement next to the charge or reach out to our customer support team Toll-free number at +1 855-886-0505.</Text>
        <Text style={styles.paragraph}>In the event that you breach this agreement and file a chargeback, upon a resolution in our favor of the chargeback by either the credit card issuing bank, the credit/debit card processor or by the credit card company, you agree to reimburse us for any costs incurred in researching and responding to such chargeback, including without limitation, our actual costs paid to the credit/debit card processor or our banks and the reasonable value of the time of our employees spent on the matter, as determined in our discretion in good faith.</Text>
        <Text style={styles.paragraph}>In the event that a chargeback is placed or threatened on a purchase, we also reserve the right to report the incident for inclusion in the chargeback abuser database(s). We use various credit reporting agencies and will send a default notice to them upon receiving a fraudulent order and/or chargeback. This in turn will seriously affect your credit rating. We are under no obligation to mark the debt as paid, even after we receive further payment to rectify the situation.</Text>
        <Text style={styles.paragraph}>These Terms and Conditions may be available in multiple languages; in the case of a conflict between any other language version and the English language version of these Terms and Conditions, the English language version shall always control.</Text>
        
        <Text style={styles.title}>Service Accessibility</Text>
        <Text style={styles.subtitle}>Accessibility Records</Text>
        <Text style={styles.paragraph}>PhoneBox keeps records of any interaction established between the company and any impaired individual who contacted the company’s customer support team through any of the following methods: chat, email, phone, or social media channel including but are not limited to Twitter, Facebook (Meta), What’sApp, Instagram, TikTok message, LINE, KakaoTalk, WeChat. Records are registered and saved for an undefined period of time; they are confidential and will be used only to determine statistics, general trends, or recurrent requests of the customers. Records can also be used to analyze, improve & develop features or options to further meet customers needs in regards to service accessibility and usability.</Text>

        <Text style={styles.subtitle}>Accessibility features</Text>
        <Text style={styles.paragraph}>As a website owner, PhoneBox is responsible to adjust its features and services so that its website content is compatible with the majority of the browsers available on the market. Moreover, Gophonebox.com can be used with any of the rendering devices used by visually impaired individuals. The content is not encrypted and can be accessed by audio rendering programs.</Text>
        <Text style={styles.paragraph}>Any visually impaired individual can use his/her regular phone or special device to make use of our Service & applications, to the extent to which he/she is able to use his/her device and dial regular numbers such as: access numbers, PIN numbers, or numbers saved in their phone’s Contacts list.</Text>
        <Text style={styles.paragraph}>We’re committed to offering accessibility support to our Customers with vision, hearing, mobility, and speech limitations. Therefore, any question regarding the use or accessibility of our Services can be solved over the phone or email. Our Customer support team is prepared and able to accept calls that use audio rendering devices or any other special devices used by people with disabilities. Still, the Services offered by PhoneBox can be purchased online through the paying platforms available on Gophonebox.com.</Text>

        <Text style={styles.subtitle}>More Resources</Text>
        <Text style={styles.paragraph}>Mobile Speak and Mobile Magnifier are useful innovative applications designed for people with low vision. Both applications, such as many others available on the market can be used when accessing our website from a smartphone. Mobile Speak and Mobile Magnifier are third-party applications and require a separate license from the developer. PhoneBox is not responsible for the performance of third-party applications or services.</Text>

        <Text style={styles.subtitle}>Hearing Aid Compatibility</Text>
        <Text style={styles.paragraph}>PhoneBox does not offer devices including but are not limited to Mobile Phone devices under all forms (Touch screen or dial pad). Customers are required and responsible to make sure that any devices purchased with other companies/vendors support and provide Hearing Aid Compatibility. PhoneBox will not be responsible for any device incompatibility which prevents the client from the usage of our service. Customers are fully responsible for correctly using their hearing aid devices and for establishing the connection between the phone purchased and the hearing devices they own.</Text>

        <Text style={styles.subtitle}>Visual Aid Devices</Text>
        <Text style={styles.paragraph}>There are a number of devices able to help Customers with vision disabilities. Please note that PhoneBox does not offer devices including but are not limited to Mobile Phone devices under all forms (Touch screen or dial pad). If clients purchase devices from other vendors or companies, must be responsible and ensure that the device is compatible in relation to Visual Aid. Options most commonly used on these devices are: Voice Command, Menu Readout, Voice Over or Voice Command. Customers can check the specifications of each phone for such features.</Text>
        <Text style={styles.paragraph}>Besides these, all iOS devices offer Siri, that can be used to send messages, place calls, etc. The majority of Android devices offer TalkBack, SoundBack, and KickBack, free applications designed to increase the accessibility of Android devices. These applications can add spoken, audible, and vibration functionality to the device. Please note that above information can be used solely as a recommendation or reference purposes, but shall not be a determining factor for device purchase. PhoneBox will not be responsible for any device incompatibility which prevents the client from the usage of our service.</Text>

        <Text style={styles.subtitle}>Video Relay Service</Text>
        <Text style={styles.paragraph}>The Video Relay Service (VRS) is intended to assist individuals who are deaf, hard of hearing, and speech-impaired (D-HOH-SI) to communicate with hearing individuals via a sign language interpreter. VRS services work as a video call which offers the chance to the disabled individual to see a sign language interpreter. The interpreter can then communicate with the hearing individual.</Text>
        <Text style={{...styles.paragraph, marginBottom: 0}}>Accessibility Customer Support</Text>
        <Text style={{...styles.paragraph, marginBottom: 0}}>Available from 9am to 5pm EST Monday through Friday</Text>
        <Text style={{...styles.paragraph, marginBottom: 0}}>Phone: 1-855-886-0505</Text>
        <Text style={{...styles.paragraph}}>Email: services@gophonebox.com</Text>

        <Text style={styles.title}>Data Transparency</Text>
        <Text style={styles.subtitle}>CPNI</Text>
        <Text style={styles.paragraph}>As an MVNOs, we collect and maintain Customer Proprietary Network Information, or "CPNI.” CPNI is information generated by the telecommunications services we provide to our customers – currently, this is limited to voice calling. This includes features of your voice calling service (e.g., international calling), usage information (like call logs—including date, time, phone numbers called, and duration of calls), and quantitative data like minutes used. CPNI does not include customers’ names, addresses, or phone numbers—we do, nonetheless, treat that information with care.</Text>
        <Text style={styles.paragraph}>Under federal law, you have a right, and we have a duty, to protect the confidentiality of CPNI. We’ve implemented policies and procedures to help ensure our compliance with the CPNI rules adopted by the Federal Communications Commission (FCC), and we continually review our compliance. We also certify our compliance to the FCC annually.</Text>
        <Text style={styles.paragraph}>The bottom line is that we will not intentionally use, access, or disclose your CPNI to third parties without your permission, except as allowed under the FCC’s rules or other applicable law. Where applicable—based on brand and account type—if you are the primary account holder you may designate other "authorized users" to access and manage your account information. In that case, your authorized users will also be provided access to all the CPNI associated with your account—not just the line they are using.</Text>
        <Text style={styles.paragraph}>Carriers are prohibited from releasing call details during customer-initiated telephone contacts, except when the customer has previously established a password for their account. Otherwise, carriers can only release call details by sending it to an address of record or by calling the customer at the telephone number of record.</Text>
        <Text style={styles.paragraph}>Carriers must provide mandatory password protection for online account access.</Text>
        <Text style={styles.paragraph}>We provide online account access to CPNI only with a password that is initially established through use of a randomly generated PIN delivered to the customer via Email Address. Where applicable (based on brand and account type), for multi-line accounts the customer may designate himself/herself as the primary account holder, which gives that person access to online account information for all the devices/lines on the account. Other users may access detailed online account information related only to their respective device/line. For example, if you provide a phone to a family member, they may access online information about that device/line—including CPNI. The primary account holder, however, may designate additional or more limited online access rights for other users.</Text>
        <Text style={styles.paragraph}>Carriers may provide CPNI to customers in a retail location with a valid government issued photo ID.</Text>
        <Text style={styles.paragraph}>For Prepaid plans, we require a valid government-issued photo ID matching the customer or authorized user’s account information prior to disclosing CPNI during a visit to a retail store.</Text>
        <Text style={styles.paragraph}>Carriers must notify their customers when a password, address, and certain other account changes occur.</Text>
        <Text style={styles.paragraph}>We provide notice whenever, among other changes, a password, username, other changes online account, or address of record is changed. The notices do not include or reveal the changed information. These notices are sent to the customer’s registered email address or through text message.</Text>
        <Text style={styles.paragraph}>Carriers must establish a notification process for both law enforcement and customers in the event of a CPNI breach. Specifically, carriers must notify the United States Secret Service (“USSS”) and the Federal Bureau of Investigation (“FBI”) after discovering a breach of CPNI.</Text>
        <Text style={styles.paragraph}>We are committed to protecting our customers’ CPNI and complying with the FCC’s CPNI rules. Questions and/or concerns may be directed to Services@gophonebox.com.</Text>

        <View style={{ marginTop: Sizes.large }}>
          <AppButton text="I Agree" onPress={() => navigation.goBack()}></AppButton>
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  title: {
    ...TextStyles.title,
    color: Colors.black,
    marginBottom: 20,
  },
  subtitle: {
    ...TextStyles.subtitle,
    color: Colors.black,
    marginBottom: 20,
  },
  paragraph: {
    ...TextStyles.subtitle,
    fontFamily: FontFamilys.regular,
    color: Colors.black,
    marginBottom: 20,
  },
  bulletPointContainer: {
    marginLeft: 20,
    flexDirection: 'row',
  },
  indentedBulletPointContainer: {
    marginLeft: 70,
    flexDirection: 'row',
  },
  bulletPointText: {
    ...TextStyles.subtitle,
    fontFamily: FontFamilys.regular,
    color: Colors.black,
    marginLeft: 15
  }
});