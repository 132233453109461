// // Can still subscribe to the store
// store.subscribe(() => console.log(store.getState()))

// // Still pass action objects to `dispatch`, but they're created for us
// store.dispatch(incremented())
// // {value: 1}
// store.dispatch(incremented())
// // {value: 2}
// store.dispatch(decremented())
// // {value: 1}

// Persist (Not needed yet): https://blog.logrocket.com/persist-state-redux-persist-redux-toolkit-react/#:~:text=Persist%20state%20with%20Redux%20Persist%20using%20Redux%20Toolkit,of%20traditional%20React%20error%20reporting%20with%20LogRocket%20
// https://hybridheroes.de/blog/2021-01-08-redux-toolkit-react-native/
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user';
import usersReducer from './users';
import appReducer from './app';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage: AsyncStorage
}

const persistedReducer = persistReducer(persistConfig, userReducer);

export const store = configureStore({
  reducer: {
    user: persistedReducer,
    users: usersReducer,
    app: appReducer
  },
  middleware: [thunk]
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;