import { Text } from 'react-native';
import FontFamilys from '../constants/FontFamilys';

type AppBoldTextProps = {
  children: React.ReactNode;
}

export function AppBoldText(props: AppBoldTextProps) {
  return (<Text style={{ fontFamily: FontFamilys.bold }}>{props.children}</Text>);
}
