import { Platform } from "react-native"
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';

const TOKEN_KEY = 'PhoneBoxAccessToken';
const TOKEN_KEY_EXPIRY = 'PhoneBoxAccessTokenExpiry';
const REFRESH_TOKEN_KEY = 'PhoneBoxRefreshToken';
const REFRESH_TOKEN_KEY_EXPIRY = 'PhoneBoxRefreshTokenExpiry';

export const tokenService = {
    setAccessTokenAsync: async (value: string) => {
        if (Platform.OS !== 'web') {
            await SecureStore.setItemAsync(TOKEN_KEY, value);
            return;
        }
        await AsyncStorage.setItem(TOKEN_KEY, value);
    },
    setAccessTokenExpiryAsync: async (value: Date) => {
        if (Platform.OS !== 'web') {
            await SecureStore.setItemAsync(TOKEN_KEY_EXPIRY, value.toISOString());
            return;
        }
        await AsyncStorage.setItem(TOKEN_KEY_EXPIRY, value.toISOString());
    },
    setRefreshTokenAsync: async (value: string) => {
        if (!value) {
          return;
        }
        if (Platform.OS !== 'web') {
            await SecureStore.setItemAsync(REFRESH_TOKEN_KEY, value);
            return
        }
        await AsyncStorage.setItem(REFRESH_TOKEN_KEY, value);
    },
    setRefreshTokenExpiryAsync: async (value: Date) => {
        if (!value) {
          return;
        }
        if (Platform.OS !== 'web') {
            await SecureStore.setItemAsync(REFRESH_TOKEN_KEY_EXPIRY, value.toISOString());
            return
        }
        await AsyncStorage.setItem(REFRESH_TOKEN_KEY_EXPIRY, value.toISOString());
    },
    getAccessTokenAsync: async () => {
        if (Platform.OS !== 'web') {
            return await SecureStore.getItemAsync(TOKEN_KEY);
        }
        return await AsyncStorage.getItem(TOKEN_KEY);
    },
    getAccessTokenExpiryAsync: async () => {
        if (Platform.OS !== 'web') {
            return await SecureStore.getItemAsync(TOKEN_KEY_EXPIRY);
        }
        return await AsyncStorage.getItem(TOKEN_KEY_EXPIRY);
    },
    getRefreshTokenAsync: async () => {
        if (Platform.OS !== 'web') {
            return await SecureStore.getItemAsync(REFRESH_TOKEN_KEY);
        }
        return await AsyncStorage.getItem(REFRESH_TOKEN_KEY);
    },
    getRefreshTokenExpiryAsync: async () => {
        if (Platform.OS !== 'web') {
            return await SecureStore.getItemAsync(REFRESH_TOKEN_KEY_EXPIRY);
        }
        return await AsyncStorage.getItem(REFRESH_TOKEN_KEY_EXPIRY);
    },
    deleteAccessTokenAsync: async () => {
        if (Platform.OS !== 'web') {
            SecureStore.deleteItemAsync(TOKEN_KEY);
            return;
        }
        await AsyncStorage.removeItem(TOKEN_KEY);
    },
    deleteAccessTokenExpiryAsync: async () => {
        if (Platform.OS !== 'web') {
            SecureStore.deleteItemAsync(TOKEN_KEY_EXPIRY);
            return;
        }
        await AsyncStorage.removeItem(TOKEN_KEY_EXPIRY);
    },
    deleteRefreshTokenAsync: async () => {
        if (Platform.OS !== 'web') {
            await SecureStore.deleteItemAsync(REFRESH_TOKEN_KEY);
            return;
        }
        await AsyncStorage.removeItem(REFRESH_TOKEN_KEY);
    },
    deleteRefreshTokenExpiryAsync: async () => {
        if (Platform.OS !== 'web') {
            await SecureStore.deleteItemAsync(REFRESH_TOKEN_KEY_EXPIRY);
            return;
        }
        await AsyncStorage.removeItem(REFRESH_TOKEN_KEY_EXPIRY);
    }
};