import { FontAwesome } from '@expo/vector-icons';
import { ScrollView, StyleSheet, View, Text, Pressable } from 'react-native';
import { AppBoldText } from '../components/AppBoldText';
import { AppButton } from '../components/AppButton';

import Colors from '../constants/Colors';
import CommonStyles from '../constants/CommonStyles';
import FontFamilys from '../constants/FontFamilys';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import { RootStackScreenProps, RootTabScreenProps } from '../types';
import { ExtensionType } from '../types/enums/ExtensionType';
import { useDispatch, useSelector } from 'react-redux';
import { setAutopayToEnable, setExtensionDate, setExtensionType, setInternationalCallingPaymentAmount } from '../store/user';
import { useEffect, useState } from 'react';
import { AppDispatch, RootState } from '../store';
import FlexStyles from '../constants/FlexStyles';
import { AppModal } from '../components/AppModal';
import { AppContainer } from '../components/AppContainer';
import { AppRow } from '../components/AppRow';
import { AppColumn } from '../components/AppColumn';
import { customerService } from '../services/CustomerService';

export default function InternationalCallingScreen({ navigation }: RootStackScreenProps<'InternationalCalling'>) {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [taxes, setTaxes] = useState(0);
  const fee = 10;
  const total = taxes + fee;

  const endDate = user.selectedAccount.plan.end_date.split('T')[0];

  useEffect(() => {
    const estimateTaxes = async () => {
      setLoading(true);
      const taxResponse = await customerService.estimateTaxes({
        data: {
          calc_taxes: 1,
          i_customer: user.selectedAccount.customer_info.customer_id,
          xdr_list: [
            {
              charged_amount: 10,
              i_dest: 18,
              i_service: 4,
              i_subscription: 9
            }
          ]
        }
      })

      if (!taxResponse.data || !taxResponse.success) {
        console.log("Something went wrong with estimate taxes");
        setLoading(false);
      }

      if (taxResponse && taxResponse.success) {
        let taxAcc = 0;
        if (taxResponse.data.taxes_list) {
          taxResponse.data.taxes_list.forEach(e => {
            taxAcc += e.amount;
          })
        }
        // add missing tax
        taxAcc += 0.60;
        setTaxes(taxAcc);
        setLoading(false);
      }
    }
    estimateTaxes().catch(err => console.error(err));
  }, []);

  const onClickConfirm = () => {
    dispatch(setExtensionType(ExtensionType.InternationalCalling));
    dispatch(setExtensionDate(endDate));
    dispatch(setAutopayToEnable(false));
    dispatch(setInternationalCallingPaymentAmount(total));
    navigation.navigate('MakeAPayment')
  }

  return (
    <ScrollView>
      <View style={{ ...PageLayoutStyles.container, paddingTop: Sizes.none }}>
        <Text style={{ ...TextStyles.title, color: Colors.white }}>International Calling</Text>
        <View style={{ ...CommonStyles.card, padding: 0, marginTop: Sizes.large }}>
          <View style={{ paddingHorizontal: CommonStyles.card.padding, paddingBottom: CommonStyles.card.padding, paddingTop: Sizes.medium }}>
            <View style={{ ...FlexStyles.rowCenter, padding: Sizes.medium }}>
              <Text style={{ ...TextStyles.paragraph, marginRight: Sizes.medium }}>Minutes in the top-up:</Text>
              <Text style={{ ...TextStyles.subtitle, color: Colors.green, fontSize: Sizes.large }}>Unlimited</Text>
            </View>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginHorizontal: Sizes.large, borderTopColor: Colors.lightGrey, borderTopWidth: 1, paddingTop: CommonStyles.card.padding }}>
              <Text style={{ ...TextStyles.subtitle, color: Colors.black, textAlign: 'center', fontFamily: FontFamilys.bold, marginBottom: 10 }}>Fee:</Text>
              <Text style={{ ...TextStyles.subtitle, color: Colors.black, textAlign: 'center', fontFamily: FontFamilys.regular, marginBottom: 10 }}>${fee.toFixed(2)}</Text>
            </View>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginHorizontal: Sizes.large }}>
              <Text style={{ ...TextStyles.subtitle, color: Colors.black, textAlign: 'center', fontFamily: FontFamilys.bold, marginBottom: 10 }}>Taxes & Surcharges:</Text>
              <Text style={{ ...TextStyles.subtitle, color: Colors.black, textAlign: 'center', fontFamily: FontFamilys.regular, marginBottom: 10 }}>${taxes.toFixed(2)}</Text>
            </View>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginHorizontal: Sizes.large, borderTopColor: Colors.lightGrey, borderTopWidth: 1, paddingTop: CommonStyles.card.padding }}>
              <Text style={{ ...TextStyles.subtitle, color: Colors.green, textAlign: 'center', fontFamily: FontFamilys.bold }}>Total</Text>
              <Text style={{ ...TextStyles.subtitle, color: Colors.green, textAlign: 'center', fontFamily: FontFamilys.regular }}>${total.toFixed(2)}</Text>
            </View>
          </View>
        </View>
        <View style={{ marginTop: Sizes.large }}>
          <AppButton loading={loading} text="Confirm" onPress={onClickConfirm}></AppButton>
        </View>
        <View style={{ marginTop: Sizes.large }}>
          <AppButton text="See the list of countries included" onPress={() => setModalVisible(true)} outline color={Colors.white}></AppButton>
        </View>
      </View>
      <AppModal onClose={() => setModalVisible(false)} visible={modalVisible} headerTitle="List of Countries" width={''} height={'40%'}>
        <AppContainer>
          <AppRow>
            <AppColumn>
              <Text style={{ ...TextStyles.paragraph }}>BANGLADESH</Text>
              <Text style={{ ...TextStyles.paragraph }}>BRAZIL</Text>
              <Text style={{ ...TextStyles.paragraph }}>CANADA</Text>
              <Text style={{ ...TextStyles.paragraph }}>CHINA</Text>
              <Text style={{ ...TextStyles.paragraph }}>COLOMBIA</Text>
              <Text style={{ ...TextStyles.paragraph }}>FRANCE</Text>
              <Text style={{ ...TextStyles.paragraph }}>GERMANY</Text>
              <Text style={{ ...TextStyles.paragraph }}>HONG KONG</Text>
              <Text style={{ ...TextStyles.paragraph }}>INDIA</Text>
              <Text style={{ ...TextStyles.paragraph }}>INDONESIA</Text>
              <Text style={{ ...TextStyles.paragraph }}>IRAN</Text>
              <Text style={{ ...TextStyles.paragraph }}>JAPAN</Text>
              <Text style={{ ...TextStyles.paragraph }}>KUWAIT</Text>
              <Text style={{ ...TextStyles.paragraph }}>MEXICO</Text>
            </AppColumn>
            <AppColumn>
              <Text style={{ ...TextStyles.paragraph }}>NEPAL</Text>
              <Text style={{ ...TextStyles.paragraph }}>NIGERIA</Text>
              <Text style={{ ...TextStyles.paragraph }}>PAKISTAN</Text>
              <Text style={{ ...TextStyles.paragraph }}>SAUDI ARABIA</Text>
              <Text style={{ ...TextStyles.paragraph }}>SOUTH KOREA</Text>
              <Text style={{ ...TextStyles.paragraph }}>SPAIN</Text>
              <Text style={{ ...TextStyles.paragraph }}>TAIWAN</Text>
              <Text style={{ ...TextStyles.paragraph }}>TURKEY</Text>
              <Text style={{ ...TextStyles.paragraph }}>UNITED KINGDOM</Text>
              <Text style={{ ...TextStyles.paragraph }}>VENEZUELA</Text>
              <Text style={{ ...TextStyles.paragraph }}>VIETNAM</Text>
            </AppColumn>
          </AppRow>
        </AppContainer>
      </AppModal>
    </ScrollView>
  );
}
