import { GestureResponderEvent, Pressable, Text, View } from "react-native";
import Colors from "../constants/Colors";
import CommonStyles from "../constants/CommonStyles";
import FlexStyles from "../constants/FlexStyles";
import TextStyles from "../constants/TextStyles";
import Sizes from "../constants/Sizes";
import { AppBoldText } from "./AppBoldText";
import { AppCircle } from "./AppCircle";
import useIsMobile from "../hooks/useIsMobile";
import { AppCard } from "./AppCard";
import FontFamilys from "../constants/FontFamilys";
import { AppButton } from "./AppButton";
import { useEffect, useState } from "react";
import { FontAwesome } from "@expo/vector-icons";
import { StepType } from "../types/enums/StepType";
import { customerService } from "../services/CustomerService";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import {
  setAutopayToEnable,
  setExtensionType,
  setTopUpDataAmount,
  setTopUpPaymentAmount,
} from "../store/user";
import { useNavigation } from "@react-navigation/native";
import { ExtensionType } from "../types/enums/ExtensionType";
import { AppGap } from "./AppGap";

type PlanDataUsageCardProps = {
  dataUsed: number;
  dataUsedUnit: string;
  totalData: number;
  totalDataUnit: string;
  resetDate: Date;
  dataUsedPercentage: number;
  dataRemaining: number;
  onPress?: ((event: GestureResponderEvent) => void) | undefined;
  // is7daysPlan: boolean;
  isLessThan30DaysPlan: boolean;
};

export function PlanDataUsageCard(props: PlanDataUsageCardProps) {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch<AppDispatch>();
  const navigation = useNavigation();
  const isMobile = useIsMobile();
  const dataOverLimit = props.dataUsedPercentage >= 100;
  const [showAddData, setShowAddData] = useState(false);
  const [step, setStep] = useState(StepType.First);
  const [dataAmount, setDataAmount] = useState(1);
  const [total, setTotal] = useState(10);
  const [taxes, setTaxes] = useState(0);
  const topUpFee = 10 * dataAmount;
  const [loading, setLoading] = useState(false);

  const onClickCancel = () => {
    setStep(StepType.First);
    setShowAddData(false);
  };

  const onClickNext = () => {
    setStep(StepType.Second);
  };

  const onClickConfirm = () => {
    setStep(StepType.First);
    setShowAddData(false);
    dispatch(setExtensionType(ExtensionType.TopUp));
    dispatch(setTopUpDataAmount(dataAmount));
    dispatch(setAutopayToEnable(false));
    navigation.navigate("MakeAPayment");
  };

  const changeDataAmount = (newValue: number) => {
    if (newValue < 1 || newValue > 3) {
      return;
    }
    setDataAmount(newValue);
  };

  const handleTaxes = async () => {
    setLoading(true);
    let topUpISubscription = 0;

    if (dataAmount === 1) {
      topUpISubscription = 5;
    } else if (dataAmount === 2) {
      topUpISubscription = 47;
    } else if (dataAmount === 3) {
      topUpISubscription = 48;
    }

    var response = await customerService.estimateTaxes({
      data: {
        calc_taxes: 1,
        i_customer: user.selectedAccount.customer_info.customer_id,
        xdr_list: [
          {
            charged_amount: topUpFee,
            i_dest: 18,
            i_service: 4,
            i_subscription: topUpISubscription,
          },
        ],
      },
    });
    if (!response || !response.success) {
      console.error(response.error);
      setLoading(false);
      return;
    }
    if (response && response.success) {
      let cumulativeTax = 0;
      if (response.data.taxes_list) {
        response.data.taxes_list.forEach((entry) => {
          cumulativeTax = cumulativeTax + entry.amount;
        });
      }
      // known glitch with taxes, look for better option
      // taxes always equal 0.6 when they should equal 0
      if (cumulativeTax === 0.6) {
        cumulativeTax = 0;
      }

      // TODO: remove this
      // due to glitches with taxes, set tax to zero for Plan Data Usage.
      cumulativeTax = 0;
      setTaxes(cumulativeTax);
      const newTotal = +(cumulativeTax + topUpFee).toFixed(2);
      setTotal(newTotal);
      dispatch(setTopUpPaymentAmount(newTotal));
      setTimeout(() => setLoading(false), 500);
    }
  };

  useEffect(() => {
    handleTaxes().catch((err) => console.error(err));
  }, [dataAmount]);

  return (
    <>
      {!isMobile && (
        <AppCard
          helpText={[
            "The data shown is the total remaining data which can be used during your prepaid term cycle. The total data remaining includes an accumulation of your available data, plus any data add-on (top up).",
          ]}
          helpTitleText="Additional Data Usage Information"
        >
          <View style={CommonStyles.cardBody}>
            {!props.isLessThan30DaysPlan && (
              <Text
                style={{ ...TextStyles.paragraph, marginBottom: Sizes.large }}
              >
                Your data will be reset on:{" "}
                <AppBoldText>
                  {props.resetDate.toLocaleDateString("en-US")}
                </AppBoldText>
              </Text>
            )}
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <AppCircle
                backgroundColor={
                  dataOverLimit ? Colors.dimRed : Colors.dimGreen
                }
                size={100}
                text={`${props.dataUsedPercentage.toFixed(0)}%`}
                fontSize={30}
                fontColor={dataOverLimit ? Colors.red : Colors.green}
              ></AppCircle>
              <View>
                <Text
                  style={{
                    ...TextStyles.subtitle,
                    fontFamily: FontFamilys.regular,
                    color: Colors.black,
                  }}
                >
                  Data Remaining:
                </Text>
                <Text
                  style={{
                    ...TextStyles.subtitle,
                    color: Colors.black,
                    fontSize: Sizes.large,
                  }}
                >
                  {props.dataRemaining.toFixed(2)}GB left
                </Text>
              </View>
            </View>
            <Text
              style={{
                ...TextStyles.semiSubtitle,
                color: Colors.green,
                textAlign: "center",
                paddingTop: Sizes.small,
              }}
            >
              <AppBoldText>Data Top Up Rate: USD 10/1GB</AppBoldText>
            </Text>
            {!showAddData && (
              <View style={{ ...FlexStyles.rowCenter, marginTop: Sizes.large }}>
                <AppButton
                  text="Add Data"
                  onPress={() => setShowAddData(true)}
                ></AppButton>
              </View>
            )}
            {showAddData && step === StepType.First && (
              <View>
                <View
                  style={{
                    flexDirection: "row",
                    marginHorizontal: "auto",
                    alignItems: "center",
                    marginTop: Sizes.large,
                  }}
                >
                  {dataAmount > 1 ? (
                    <Pressable
                      style={{
                        backgroundColor: Colors.dimGreen,
                        borderRadius: 20,
                        width: 20,
                        height: 20,
                      }}
                    >
                      <Text
                        style={{
                          ...TextStyles.semiSubtitle,
                          color: Colors.green,
                          textAlign: "center",
                        }}
                        onPress={() => changeDataAmount(dataAmount - 1)}
                      >
                        -
                      </Text>
                    </Pressable>
                  ) : (
                    <AppGap direction="horizontal" size={Sizes.large}></AppGap>
                  )}
                  <Text
                    style={{
                      ...TextStyles.title,
                      color: Colors.green,
                      paddingHorizontal: Sizes.medium,
                    }}
                  >
                    {dataAmount}
                  </Text>
                  {dataAmount < 3 ? (
                    <Pressable
                      style={{
                        backgroundColor: Colors.dimGreen,
                        borderRadius: Sizes.large,
                        width: Sizes.large,
                        height: Sizes.large,
                      }}
                      onPress={() => changeDataAmount(dataAmount + 1)}
                    >
                      <Text
                        style={{
                          ...TextStyles.semiSubtitle,
                          color: Colors.green,
                          textAlign: "center",
                        }}
                      >
                        +
                      </Text>
                    </Pressable>
                  ) : (
                    <AppGap direction="horizontal" size={Sizes.large}></AppGap>
                  )}
                </View>
                <Text
                  style={{
                    ...TextStyles.semiSubtitle,
                    textAlign: "center",
                    color: Colors.green,
                  }}
                >
                  GB
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: Sizes.large,
                  }}
                >
                  <Pressable
                    style={{ paddingRight: 8 }}
                    onPress={onClickCancel}
                  >
                    <FontAwesome
                      style={{ color: Colors.darkGrey }}
                      size={30}
                      name={"chevron-circle-left"}
                    ></FontAwesome>
                  </Pressable>
                  <AppButton text="Next" onPress={onClickNext}></AppButton>
                </View>
              </View>
            )}
            {showAddData && step === StepType.Second && (
              <View style={{ alignItems: "center" }}>
                <View style={{ marginTop: Sizes.medium }}>
                  <Text style={{ ...TextStyles.subtitle, color: Colors.green }}>
                    {dataAmount + " GB"}
                  </Text>
                </View>
                <View style={{ flex: 1, flexDirection: "row" }}>
                  <Text>Data Top Up:</Text>
                  <Text style={{ ...TextStyles.semiSubtitle, paddingLeft: 4 }}>
                    ${topUpFee.toFixed(2)}
                  </Text>
                </View>
                <View style={{ flex: 1, flexDirection: "row" }}>
                  <Text>Taxes & Surcharges:</Text>
                  <Text style={{ ...TextStyles.semiSubtitle, paddingLeft: 4 }}>
                    ${taxes.toFixed(2)}
                  </Text>
                </View>
                {!loading && (
                  <Text
                    style={{
                      ...TextStyles.subtitle,
                      color: Colors.green,
                      marginTop: Sizes.medium,
                    }}
                  >
                    Total: ${total.toFixed(2)} USD
                  </Text>
                )}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: Sizes.large,
                  }}
                >
                  <Pressable
                    style={{ paddingRight: 8 }}
                    onPress={onClickCancel}
                  >
                    <FontAwesome
                      style={{ color: Colors.darkGrey }}
                      size={30}
                      name={"chevron-circle-left"}
                    ></FontAwesome>
                  </Pressable>
                  <AppButton
                    text="Confirm"
                    loading={loading}
                    onPress={onClickConfirm}
                  ></AppButton>
                </View>
              </View>
            )}
          </View>
        </AppCard>
      )}
      {isMobile && (
        <View style={{ ...CommonStyles.card }}>
          {!props.isLessThan30DaysPlan && (
            <Text
              style={{ ...TextStyles.paragraph, marginBottom: Sizes.large }}
            >
              Your data will be reset on:{" "}
              <AppBoldText>
                {props.resetDate.toLocaleDateString("en-US")}
              </AppBoldText>
            </Text>
          )}
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <AppCircle
              backgroundColor={dataOverLimit ? Colors.dimRed : Colors.dimGreen}
              size={100}
              text={`${props.dataUsedPercentage.toFixed(0)}%`}
              fontSize={30}
              fontColor={dataOverLimit ? Colors.red : Colors.green}
            ></AppCircle>
            <View>
              <Text
                style={{
                  ...TextStyles.subtitle,
                  fontFamily: FontFamilys.regular,
                  color: Colors.black,
                }}
              >
                Data Remaining:
              </Text>
              <Text
                style={{
                  ...TextStyles.subtitle,
                  color: Colors.black,
                  fontSize: Sizes.large,
                }}
              >
                {props.dataRemaining.toFixed(2)}GB left
              </Text>
            </View>
          </View>
          <Text
            style={{
              ...TextStyles.semiSubtitle,
              color: Colors.green,
              textAlign: "center",
            }}
          >
            <AppBoldText>Data Top Up Rate: USD 10/1GB</AppBoldText>
          </Text>
          <View style={{ ...FlexStyles.rowCenter, marginTop: Sizes.large }}>
            <AppButton text="Add Data" onPress={props.onPress}></AppButton>
          </View>
        </View>
      )}
    </>
  );
}
