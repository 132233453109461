import { useRoute } from '@react-navigation/native';
import { useEffect, useState } from 'react';
import { ScrollView, View, Text } from 'react-native';
import { AppAlert } from '../components/AppAlert';
import { AppButton } from '../components/AppButton';
import { AppColumn } from '../components/AppColumn';
import { AppContainer } from '../components/AppContainer';
import { AppFooter } from '../components/AppFooter';
import { AppGap } from '../components/AppGap';
import { AppHeader } from '../components/AppHeader';
import { AppRow } from '../components/AppRow';
import { AppTextInput } from '../components/AppTextInput';
import { Loading } from '../components/Loading';
import Colors from '../constants/Colors';
import CommonStyles from '../constants/CommonStyles';
import FontFamilys from '../constants/FontFamilys';
import Heights from '../constants/Heights';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import useIsMobile from '../hooks/useIsMobile';
import { authService } from '../services/AuthService';
import { RootStackScreenProps } from '../types';
import { ApiErrorCodes } from '../types/ApiErrorCodes';

export default function ChangePasswordScreen({ navigation }: RootStackScreenProps<'ChangePassword'>) {
  const isMobile = useIsMobile();
  const route = useRoute();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newConfirmPassword, setNewConfirmPassword] = useState('');
  const [resetPasswordError, setResetPasswordError] = useState('');
  const [requestingPasswordReset, setRequestingPasswordReset] = useState(false);
  const [passwordResetTokenExpired, setPasswordResetTokenExpired] = useState(false);
  const [passwordResetTokenExpiredError, setPasswordResetTokenExpiredError] = useState('');

  useEffect(() => {
    const validatePasswordRecoveryToken = async () => {
      const params = route.params as any;
      if (!params || !params.token) {
        setTimeout(() => {
          navigation.replace('Landing');
        });
        return;
      }

      const response = await authService.validatePasswordResetToken({ data: { token: params.token } });
      if (!response.success) {
        if (+response.error.code === ApiErrorCodes.PasswordResetTokenExpired) {
          setPasswordResetTokenExpired(true);
          setPasswordResetTokenExpiredError(response.error.message);
        } else {
          setTimeout(() => {
            navigation.replace('Landing');
          });
          return;
        }
      }

      setToken(params.token);
      setLoading(false);
    }

    validatePasswordRecoveryToken().catch(err => err.console);
  }, []);

  const handleResetPassword = async () => {
    setResetPasswordError('');
    setRequestingPasswordReset(true);
    var response = await authService.resetPassword({ data: { token: token, new_password: newPassword, new_confirm_password: newConfirmPassword } });
    if (!response || !response.success) {
      setRequestingPasswordReset(false);
      setResetPasswordError(response.error.message);
      console.error(response.error);
      return;
    }

    setRequestingPasswordReset(false);
    setResetPasswordError('');
    navigation.replace('Landing')
  }

  if (loading) {
    return <Loading></Loading>
  }
  return (
    <>
      {!isMobile &&
        <>
          <AppHeader onlyLogo></AppHeader>
          <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.scrollViewPaddingTop }}>
            <View style={{ ...PageLayoutStyles.container }}>
              <View style={{ ...CommonStyles.card, padding: 0, borderColor: Colors.green, borderWidth: 2, maxWidth: 350, margin: 'auto' }}>
                <AppContainer>
                  <AppRow>
                    <AppColumn>
                      <View style={{ alignItems: 'center', justifyContent: 'space-between', padding: Sizes.extraLarge, flex: 1 }}>
                        <Text style={{ color: Colors.black, fontFamily: FontFamilys.bold, fontSize: 30 }}>Change Password</Text>
                        {!passwordResetTokenExpired &&
                          <>
                            <AppGap direction="vertical" size={20}></AppGap>
                            <AppTextInput value={newPassword} onChangeText={(text) => setNewPassword(text)} placeholder="New Password" secureTextEntry></AppTextInput>
                            <AppGap direction="vertical" size={20}></AppGap>
                            <AppTextInput value={newConfirmPassword} onChangeText={(text) => setNewConfirmPassword(text)} placeholder="Confirm Password" secureTextEntry></AppTextInput>
                            <AppGap direction="vertical" size={20}></AppGap>
                            <AppButton text="Submit" onPress={handleResetPassword} loading={requestingPasswordReset}></AppButton>
                            <AppGap direction="vertical" size={10}></AppGap>
                            <AppButton text="Cancel" onPress={() => navigation.replace('Landing')} color={Colors.blue}></AppButton>
                            {resetPasswordError.length > 0 &&
                              <View style={{ marginTop: Sizes.medium }}>
                                <AppAlert text={resetPasswordError}></AppAlert>
                              </View>
                            }
                          </>
                        }
                        {passwordResetTokenExpired &&
                          <>
                            <AppGap direction="vertical" size={15}></AppGap>
                            <Text style={{ ...TextStyles.paragraph, color: Colors.black, textAlign: 'center' }}>{passwordResetTokenExpiredError}</Text>
                            <AppGap direction="vertical" size={15}></AppGap>
                            <AppButton text="Go to Sign In" onPress={() => navigation.replace('Landing')} color={Colors.blue}></AppButton>
                          </>
                        }

                      </View>
                    </AppColumn>
                  </AppRow>
                </AppContainer>
              </View>
            </View>
          </ScrollView>
          <AppFooter></AppFooter>
        </>
      }
      {isMobile &&
        <ScrollView>
          <View style={{ ...PageLayoutStyles.container }}>
            <Text style={{ ...TextStyles.title, color: Colors.white }}>Change Password</Text>
            {!passwordResetTokenExpired &&
              <>
                <AppGap direction="vertical" size={20}></AppGap>
                <AppTextInput value={newPassword} onChangeText={(text) => setNewPassword(text)} placeholder="New Password" secureTextEntry></AppTextInput>
                <AppGap direction="vertical" size={20}></AppGap>
                <AppTextInput value={newConfirmPassword} onChangeText={(text) => setNewConfirmPassword(text)} placeholder="Confirm Password" secureTextEntry></AppTextInput>
                <AppGap direction="vertical" size={20}></AppGap>
                <AppButton text='Submit' onPress={handleResetPassword} loading={requestingPasswordReset}></AppButton>
                <AppGap direction="vertical" size={10}></AppGap>
                <AppButton text="Cancel" onPress={() => navigation.replace('Landing')} color={Colors.white} outline></AppButton>
                {resetPasswordError.length > 0 &&
                  <View style={{ marginTop: Sizes.medium }}>
                    <AppAlert text={resetPasswordError}></AppAlert>
                  </View>
                }
              </>
            }
            {passwordResetTokenExpired &&
              <>
                <AppGap direction="vertical" size={15}></AppGap>
                <Text style={{ ...TextStyles.paragraph, color: Colors.white, textAlign: 'center' }}>{passwordResetTokenExpiredError}</Text>
                <AppGap direction="vertical" size={15}></AppGap>
                <AppButton text="Go to Sign In" onPress={() => navigation.replace('Landing')} color={Colors.white} outline></AppButton>
              </>
            }
          </View>
        </ScrollView>
      }
    </>
  );
}