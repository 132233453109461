import { StyleSheet } from 'react-native';
import Colors from './Colors';
import FontFamilys from './FontFamilys';
import FontSizes from './FontSizes';

const styles = StyleSheet.create({
    container: {
        padding: 35,
    },
    card: {
        backgroundColor: Colors.white,
        borderRadius: 10,
        padding: 20,
    }
});

export default styles;