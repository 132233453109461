/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      Root: {
        path: 'dashboard',
        screens: {
          Home: {
            screens: {
              HomeScreen: 'home',
            },
          },
          Profile: {
            screens: {
              ProfileScreen: 'profile',
            },
          },

        },
      },
      AddData: 'add-data',
      Notifications: 'notifications',
      MakeAPayment: 'make-a-payment',
      ExtendYourPlan: 'extend-your-plan',
      ChangeYourPlan: 'change-your-plan',
      PlanChanged: 'plan-changed',
      AddNewCard: 'add-new-card',
      CheckYourEmail: 'check-your-email',
      ForgotPassword: 'forgot-password',
      Landing: '',
      PrivacyPolicy: 'privacy-policy',
      SignIn: 'sign-in',
      SignUp: 'sign-up',
      TermsAndConditions: 'terms-and-conditions',
      ProfileUpdateFirstName: 'profile/update-first-name',
      ProfileUpdateLastName: 'profile/update-last-name',
      ProfileUpdateEmail: 'profile/update-email',
      ProfileUpdatePassword: 'profile/update-password',
      Modal: 'modal',
      ChangePassword: 'change-password',
      VerifyAccount: 'verify-account',
      MoreInfo: 'more-info',
      NotFound: '*',
      InternationalCalling: 'international-calling',
      SignupSuccess: 'signup-success',
      PaymentResult: 'payment-result',
    },
  },
};

export default linking;
