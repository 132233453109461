import { useState } from 'react';
import { ScrollView, View, Text } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { AppAlert } from '../components/AppAlert';
import { AppButton } from '../components/AppButton';
import { AppTextInput } from '../components/AppTextInput';
import Colors from '../constants/Colors';
import FlexStyles from '../constants/FlexStyles';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import { profileService } from '../services/ProfileService';
import { RootState, AppDispatch } from '../store';
import { setLastName } from '../store/user';
import { RootStackScreenProps } from '../types';

export default function ProfileUpdateLastNameScreen({ navigation }: RootStackScreenProps<'ProfileUpdateLastName'>) {

  const user = useSelector((state: RootState) => state.user);
  const [last, setLast] = useState(user.selectedAccount.last_name);
  const [lastNameError, setLastNameError] = useState('');
  const [savingLastName, setSavingLastName] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const updateLastName = async () => {
    setLastNameError('');
    setSavingLastName(true);
    var response = await profileService.updateLastName({ data: { last_name: last } });
    if (!response || !response.success) {
      setLastNameError(response.error.message);
      setSavingLastName(false);
      console.error(response.error);
      return;
    }
    setLastNameError('');
    setSavingLastName(false);
    dispatch(setLastName(last));
    navigation.goBack();
  }

  return (
    <ScrollView>
      <View style={{ ...PageLayoutStyles.container, paddingTop: Sizes.none }}>
        <Text style={{ ...TextStyles.title, color: Colors.white }}>Profile</Text>
        <Text style={{ ...TextStyles.subtitle, color: Colors.white, marginTop: Sizes.large, marginBottom: Sizes.small }}>Last Name</Text>
        <AppTextInput value={last} placeholder="Last Name" onChangeText={(text) => setLast(text.trim())}></AppTextInput>
        <View style={{ ...FlexStyles.rowCenter, marginTop: Sizes.large }}>
          <AppButton text="Update Last Name" onPress={updateLastName} color={Colors.white} outline loading={savingLastName}></AppButton>
        </View>
        {lastNameError.length > 0 &&
          <View style={{ marginTop: Sizes.medium }}>
            <AppAlert text={lastNameError}></AppAlert>
          </View>
        }
      </View>
    </ScrollView>
  );
}
