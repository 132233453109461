import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { Pressable, View } from 'react-native';
import { useDispatch } from 'react-redux';
import Colors from '../constants/Colors';
import { AppDispatch } from '../store';
import { setMoreInfo } from '../store/app';

type AppMoreInfo = {
  info: string[];
}

export function AppMoreInfo(props: AppMoreInfo) {
  var navigation = useNavigation();
  const dispatch = useDispatch<AppDispatch>();
  const onNavigate = () => {
    dispatch(setMoreInfo(props.info));
    navigation.navigate('MoreInfo')
  };
  return (
    <Pressable onPress={onNavigate} >
      <View style={{ width: 30, height: 30, backgroundColor: 'transparent', borderRadius: 30, borderWidth: 1, borderColor: Colors.white, justifyContent: 'center', alignItems: 'center' }} >
        <FontAwesome name="question" size={20} color={Colors.white}></FontAwesome>
      </View>
    </Pressable>
  );
}
