import instance from "../api/axios";
import { ApiRequest } from "../types/requests/ApiRequest";
import { ApiResponse } from "../types/responses/ApiResponse";
import { CreditCardRequest } from "../types/requests/CreditCardRequest";
import { CreditCardResponse } from "../types/responses/CreditCardResponse";
import { PayPalRequest } from "../types/requests/PayPalRequest";
import { PayPalResponse } from "../types/responses/PayPalResponse";

export const transactionService = {
  creditCard: async (request: ApiRequest<CreditCardRequest>): Promise<ApiResponse<CreditCardResponse>> => await instance.post<ApiRequest<CreditCardRequest>, ApiResponse<CreditCardResponse>>('/Transaction/credit_card', request),
  payPal: async (request: ApiRequest<PayPalRequest>): Promise<ApiResponse<PayPalResponse>> => await instance.post<ApiRequest<PayPalRequest>, ApiResponse<PayPalResponse>>('/Transaction/pay_pal', request),
}