import { useState } from 'react';
import { ScrollView, View, Text } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { AppAlert } from '../components/AppAlert';
import { AppButton } from '../components/AppButton';
import { AppGap } from '../components/AppGap';
import { AppSwitch } from '../components/AppSwitch';
import { AppTextInput } from '../components/AppTextInput';
import Colors from '../constants/Colors';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import { profileService } from '../services/ProfileService';
import { RootState } from '../store';
import { setAutoPayment, setPaymentMethodInfo } from '../store/user';
import { RootStackScreenProps } from '../types';
import { CardType } from '../types/enums/CardType';
import { extractExpirationDate, getCreditCardType, numbersOnly } from '../utilities/utilities';

export default function AddNewCardScreen({ navigation }: RootStackScreenProps<'AddNewCard'>) {
  const user = useSelector((state: RootState) => state.user);
  const [cardError, setCardError] = useState('');
  const [savingCard, setSavingCard] = useState(false);
  const [card, setCard] = useState({ number: '', name: '', expirationDate: '', cvv: '', internationalCalling: false, cardType: CardType.VISA });
  const dispatch = useDispatch()

  const handleCardNumber = (text: string) => {
    const cardType = getCreditCardType(text);
    setCard({ ...card, number: text, cardType: cardType })
  };

  const addCard = async () => {

    setCardError('');
    setSavingCard(true);
    const expiry = extractExpirationDate(card.expirationDate);
    var response = await profileService.addCard({
      data: {
        customer_id: user.selectedAccount.customer_info.customer_id,
        cvv: card.cvv,
        expiration_month: expiry.expirationMonth,
        expiration_year: expiry.expirationYear,
        international_calling: card.internationalCalling,
        name: card.name,
        number: card.number,
        card_type: card.cardType,
      }
    });

    if (!response || !response.success) {
      setCardError(response.error.message);
      setSavingCard(false);
      console.error(response.error);
      return;
    }
    dispatch(setPaymentMethodInfo({ card_holder_name: card.name, card_type: card.cardType, expiry_month: expiry.expirationMonth, expiry_year: expiry.expirationYear, masked_card_number: '************' + card.number.slice(-4) }))
    dispatch(setAutoPayment(true));
    setSavingCard(false);
    navigation.goBack()
  }

  return (
    <ScrollView>
      <View style={{ ...PageLayoutStyles.container, paddingTop: Sizes.none }}>
        <Text style={{ ...TextStyles.title, color: Colors.white }}>Add New Card</Text>
        <Text style={{ ...TextStyles.subtitle, color: Colors.white, marginTop: Sizes.large, marginBottom: Sizes.small }}>Card Holder Name</Text>
        <AppTextInput value={card.name} placeholder="Card Holder Name" onChangeText={(text) => setCard({ ...card, name: text })}></AppTextInput>
        <Text style={{ ...TextStyles.subtitle, color: Colors.white, marginTop: Sizes.large, marginBottom: Sizes.small }}>Card Number</Text>
        <AppTextInput value={card.number} placeholder="Card Number" onChangeText={handleCardNumber} maxLength={16} numbersOnly></AppTextInput>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ flex: 1, marginEnd: Sizes.small }}>
            <Text style={{ ...TextStyles.subtitle, color: Colors.white, marginTop: Sizes.large, marginBottom: Sizes.small }}>Expiry Date</Text>
            <AppTextInput value={card.expirationDate} placeholder="MM/YY" onChangeText={(text) => setCard({ ...card, expirationDate: text })} maxLength={5} numbersOnly expiryDate></AppTextInput>
          </View>
          <View style={{ flex: 1, marginStart: Sizes.small }}>
            <Text style={{ ...TextStyles.subtitle, color: Colors.white, marginTop: Sizes.large, marginBottom: Sizes.small }}>CVV</Text>
            <AppTextInput value={card.cvv} placeholder="XXXX" onChangeText={(text) => setCard({ ...card, cvv: text })} maxLength={4} numbersOnly></AppTextInput>
          </View>
        </View>
        <AppGap direction="vertical" size={10}></AppGap>
        <AppButton text="Save" onPress={addCard} color={Colors.white} outline loading={savingCard}></AppButton>
        {cardError.length > 0 &&
          <View style={{ marginTop: Sizes.medium }}>
            <AppAlert text={cardError}></AppAlert>
          </View>
        }
      </View>
    </ScrollView>
  );
}
