import { FontAwesome } from '@expo/vector-icons';
import { ScrollView, View, Text } from 'react-native';
import { AppButton } from '../components/AppButton';
import { AppColumn } from '../components/AppColumn';
import { AppContainer } from '../components/AppContainer';
import { AppFooter } from '../components/AppFooter';
import { AppGap } from '../components/AppGap';
import { AppHeader } from '../components/AppHeader';
import { AppRow } from '../components/AppRow';
import Colors from '../constants/Colors';
import CommonStyles from '../constants/CommonStyles';
import FontFamilys from '../constants/FontFamilys';
import Heights from '../constants/Heights';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import useIsMobile from '../hooks/useIsMobile';
import { RootStackScreenProps } from '../types';

export default function CheckYourEmailScreen({ navigation }: RootStackScreenProps<'CheckYourEmail'>) {
  const isMobile = useIsMobile();
  return (
    <>
      {!isMobile &&
        <>
          <AppHeader onlyLogo></AppHeader>
          <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.scrollViewPaddingTop }}>
            <View style={{ ...PageLayoutStyles.container }}>
              <View style={{ ...CommonStyles.card, padding: 0, borderColor: Colors.green, borderWidth: 2, maxWidth: '80%', margin: 'auto' }}>
                <AppContainer>
                  <AppRow>
                    <AppColumn>
                      <View style={{ alignItems: 'center', justifyContent: 'space-between', padding: Sizes.extraLarge, flex: 1 }}>
                        <Text style={{ color: Colors.black, fontFamily: FontFamilys.bold, fontSize: 30 }}>Check Your Email</Text>
                        <AppGap direction="vertical" size={20}></AppGap>
                        <Text style={{ ...TextStyles.paragraph }}>We have sent you instructions on how to reset your password.</Text>
                        <AppGap direction="vertical" size={20}></AppGap>
                        <AppButton text='Back' onPress={() => navigation.popToTop()}></AppButton>
                      </View>
                    </AppColumn>
                  </AppRow>
                </AppContainer>
              </View>
            </View>
          </ScrollView>
          <AppFooter></AppFooter>
        </>
      }
      {isMobile &&
        <ScrollView>
          <View style={{ ...PageLayoutStyles.container, justifyContent: 'center', alignItems: 'center' }}>
            <AppGap direction="vertical" size={40}></AppGap>
            <View style={{ justifyContent: 'center', alignItems: 'center', paddingTop: 5, paddingEnd: 30, paddingBottom: 10, paddingStart: 30, backgroundColor: 'rgba(255,255,255,0.15)', borderRadius: Sizes.medium }}>
              <FontAwesome name="envelope" color={Colors.white} size={100}></FontAwesome>
            </View>
            <AppGap direction="vertical" size={20}></AppGap>
            <Text style={{ ...TextStyles.title, color: Colors.white, textAlign: 'center' }}>Check Your Email</Text>
            <AppGap direction="vertical" size={20}></AppGap>
            <Text style={{ fontFamily: FontFamilys.regular, color: Colors.white, fontSize: 20, textAlign: 'center' }}>We have sent you instructions on how to reset your password.</Text>
            <AppGap direction="vertical" size={20}></AppGap>
            <AppButton text='Back' onPress={() => navigation.popToTop()}></AppButton>
          </View>
        </ScrollView>
      }

    </>
  );
}
