import { View, ActivityIndicator, Text } from 'react-native';
import Colors from '../constants/Colors';
import TextStyles from '../constants/TextStyles';
import useIsMobile from '../hooks/useIsMobile';
import { AppGap } from './AppGap';
import { AppLogoSvgIcon } from './AppLogoSvgIcon';

type LoadingProps = {}

export function Loading(props: LoadingProps) {
  const isMobile = useIsMobile();
  return (
    <View style={{ height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: isMobile ? Colors.green : Colors.offwhite }}>
      <AppLogoSvgIcon></AppLogoSvgIcon>
      <AppGap direction="vertical" size={20}></AppGap>
      <ActivityIndicator size="large" color={isMobile ? Colors.white : Colors.blue}></ActivityIndicator>
      <Text style={{ ...TextStyles.paragraph, color: isMobile ? Colors.white : Colors.black, textAlign: 'center', marginTop: 20 }}>Hang tight.. We're getting everything ready for you.</Text>
    </View>
  );
}
