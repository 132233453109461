import { useState } from 'react';
import { KeyboardTypeOptions, NativeSyntheticEvent, StyleSheet, TextInput, TextInputKeyPressEventData } from 'react-native';
import Colors from '../constants/Colors';
import FontFamilys from '../constants/FontFamilys';
import useIsMobile from '../hooks/useIsMobile';
import { numbersOnly } from '../utilities/utilities';

type AppTextInputProps = {
  placeholder?: string;
  secureTextEntry?: boolean;
  value?: string;
  keyboardType?: KeyboardTypeOptions;
  maxLength?: number | undefined;
  numbersOnly?: boolean;
  expiryDate?: boolean;
  onKeyPress?: ((e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void);
  onChangeText: ((text: string) => void) | undefined;
}

export function AppTextInput(props: AppTextInputProps) {
  const [text, setText] = useState(props.value ? props.value : '');
  const isMobile = useIsMobile();
  const styles = StyleSheet.create({
    textInput: {
      height: isMobile ? 50 : 40,
      backgroundColor: isMobile ? Colors.white : Colors.lightGrey,
      width: '100%',
      borderRadius: 10,
      fontSize: isMobile ? 15 : 12,
      fontFamily: FontFamilys.regular,
      paddingHorizontal: 16
    },
  });

  const onChangeText = (text: string) => {
    // Handles numbers only
    if (props.numbersOnly) {
      text = numbersOnly(text);
    }

    // Handles expiry dates for MM/YY
    if (props.expiryDate) {
      if (text.length > 2) {
        const month = text.substring(0, 2);
        const year = text.substring(2)
        text = `${month}/${year}`;
      } else {
        text = text.replace('/', '')
      }
    }

    setText(text);
    if (props.onChangeText) {
      props.onChangeText(text);
    }
  }

  return (<TextInput placeholderTextColor={isMobile ? '#c9c9c9' : '#b9b9b9'} keyboardType={props.keyboardType} value={text} style={styles.textInput} onChangeText={onChangeText} placeholder={props.placeholder} secureTextEntry={props.secureTextEntry} maxLength={props.maxLength} onKeyPress={props.onKeyPress}></TextInput>);
}

