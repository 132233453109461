import { EnhancedStore } from "@reduxjs/toolkit";
import { tokenService } from "../services/TokenService";
import { setLoggedIn } from "../store/user";
import { ApiResponse } from "../types/responses/ApiResponse";
import instance from "./axios";

/**
 * Sets up API interceptors for request and responses using the store.
 * If token expired we're auto logging out customers.
 * @param store 
 */
const setupAPIInterceptors = (store: EnhancedStore) => {
    instance.interceptors.request.use(async (config) => {
        try {
            console.info(`REQUEST ${config.url}:`, config.data);
            const token = await tokenService.getAccessTokenAsync();
            config.headers.Authorization = token ? `Bearer ${token}` : '';
            return config;
        } catch (e) {
            console.error(e);
            return config;
        }
    });

    const { dispatch } = store;
    instance.interceptors.response.use((response) => {
        if (response.data.success) {
            console.info(`RESPONSE ${response.config.url}:`, response.data);
        }
        if (!response.data.success) {
            console.warn(`RESPONSE ${response.config.url}:`, response.data);
        }
        return response.data;
    }, async (error) => {
        const response = { data: null, error: { code: 500, message: 'Something went wrong.' } } as ApiResponse<any>;
        if (error.code === 'ERR_NETWORK') {
            console.error(`SERVER UNRESPONSIVE TO '${error.config.url}'`);
            response.error.code = 503;
            response.error.message = 'Sorry for the inconvenience! Our app is currently undergoing maintenance, but we\'ll be back up and running shortly. Please check back in a bit!';
            return response;
        }

        if (error.code === 'ERR_BAD_REQUEST' && error.response && error.response.status === 401) {
            console.error(`UNAUTHORIZED REQUEST TO '${error.config.url}' FORCING LOGOUT.`);
            await tokenService.deleteAccessTokenAsync();
            await tokenService.deleteAccessTokenExpiryAsync();
            await tokenService.deleteRefreshTokenAsync();
            await tokenService.deleteRefreshTokenExpiryAsync();
            dispatch(setLoggedIn(false));
            response.error.code = 401;
            response.error.message = 'Sorry, it looks like you don\'t have permission to make this change. If you think this is a mistake, please reach out to our customer support team for assistance. They\'ll be happy to help you out!';
            return response;
        }
        return response;
    });
};

export default setupAPIInterceptors;