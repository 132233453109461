import { ScrollView, View, Text } from 'react-native';
import { useSelector } from 'react-redux';
import { AppButton } from '../components/AppButton';
import Colors from '../constants/Colors';
import FlexStyles from '../constants/FlexStyles';
import FontFamilys from '../constants/FontFamilys';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import { RootState } from '../store';
import { RootStackScreenProps } from '../types';

export default function MoreInfoScreen({ navigation }: RootStackScreenProps<'MoreInfo'>) {
  const app = useSelector((state: RootState) => state.app);
  const goBack = () => {
    navigation.goBack();
  }


  return (
    <ScrollView>
      <View style={{ ...PageLayoutStyles.container, paddingTop: Sizes.none }}>
        <Text style={{ ...TextStyles.title, color: Colors.white }}>More Info</Text>
        {app.moreInfo.map((s, i) => {
          return <Text key={i} style={{ ...TextStyles.subtitle, fontFamily: FontFamilys.regular, color: Colors.white, marginTop: Sizes.large, marginBottom: Sizes.small }}>{s}</Text>
        })}
        <View style={{ ...FlexStyles.rowCenter, marginTop: Sizes.large }}>
          <AppButton text="Back" onPress={goBack} color={Colors.red}></AppButton>
        </View>
      </View>
    </ScrollView>
  );
}
