import { Pressable, View, Text, Image, Linking } from 'react-native';
import Colors from '../constants/Colors';
import TextStyles from '../constants/TextStyles';
import Sizes from '../constants/Sizes';
import { AppVerticalBar } from './AppVerticalBar';
import FontFamilys from '../constants/FontFamilys';
import { FontAwesome } from '@expo/vector-icons';
import { AppGap } from './AppGap';
import Heights from '../constants/Heights';
import useHorizontalContainerMargin from '../hooks/useHorizontalContainerMargin';

type AppFooterProps = {}

export function AppFooter(props: AppFooterProps) {
    const marginHorizontal = useHorizontalContainerMargin();
    return (
        <View style={{ height: Heights.footer, backgroundColor: Colors.blue, padding: Sizes.large, position: 'absolute', bottom: 0, width: '100%' }}>
            <View style={{ marginHorizontal: marginHorizontal }}>
                <Image source={require('../assets/images/logo_white.png')} style={{ width: 100, height: 17 }} />
                <AppGap direction="vertical" size={15}></AppGap>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={{ ...TextStyles.paragraph, color: Colors.white, fontFamily: FontFamilys.bold }}>Social Media</Text>
                    <AppGap size={10}></AppGap>
                    <AppVerticalBar size={12} color={Colors.white}></AppVerticalBar>
                    <AppGap size={10}></AppGap>
                    <Pressable onPress={() => Linking.openURL('https://www.facebook.com/gophonebox')}>
                        <View style={{ flexDirection: 'row' }}>
                            <FontAwesome name="facebook-official" size={15} color={Colors.white}></FontAwesome>
                            <AppGap size={10}></AppGap>
                            <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>Facebook</Text>
                        </View>
                    </Pressable>
                    <AppGap size={10}></AppGap>
                    <Pressable onPress={() => Linking.openURL('https://twitter.com/gophonebox')}>
                        <View style={{ flexDirection: 'row' }}>
                            <FontAwesome name="twitter-square" size={15} color={Colors.white}></FontAwesome>
                            <AppGap size={10}></AppGap>
                            <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>Twitter</Text>
                        </View>
                    </Pressable>
                    <AppGap size={10}></AppGap>
                    <Pressable onPress={() => Linking.openURL('https://www.instagram.com/gophonebox')}>
                        <View style={{ flexDirection: 'row' }}>
                            <FontAwesome name="instagram" size={15} color={Colors.white}></FontAwesome>
                            <AppGap size={10}></AppGap>
                            <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>Instagram</Text>
                        </View>
                    </Pressable>
                    <AppGap size={60}></AppGap>
                    <Pressable onPress={() => Linking.openURL('https://blog.gophonebox.com')}>
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={{ ...TextStyles.paragraph, color: Colors.white, fontFamily: FontFamilys.bold }}>Blog</Text>
                            <AppGap size={10}></AppGap>
                            <AppVerticalBar size={12} color={Colors.white}></AppVerticalBar>
                            <AppGap size={10}></AppGap>
                            <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>All PhoneBox activities in one site</Text>
                        </View>
                    </Pressable>
                </View>
                <AppGap direction="vertical" size={10}></AppGap>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <FontAwesome name="copyright" size={10} color={Colors.white}></FontAwesome>
                    <AppGap size={5}></AppGap>
                    <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>{new Date().getFullYear()} PhoneBox. All Rights Reserved.</Text>
                </View>
            </View>
        </View>
    );
}