import { StyleSheet, Pressable, View, GestureResponderEvent, Text, ActivityIndicator } from 'react-native';
import Colors from '../constants/Colors';
import FontFamilys from '../constants/FontFamilys';
import FontSizes from '../constants/FontSizes';
import { AppGap } from './AppGap';

type AppButtonProps = {
  text: string;
  color?: string;
  loading?: boolean;
  outline?: boolean;
  onPress?: ((event: GestureResponderEvent) => void) | undefined;
}

export function AppButton(props: AppButtonProps) {
  const color = props.color ? props.color : Colors.red;
  const styles = StyleSheet.create({
    button: {
      height: 50,
      backgroundColor: props.outline ? 'transparent' : color,
      borderRadius: 100,
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      padding: 10,
      paddingHorizontal: 40,
      width: 'auto',
      opacity: props.loading ? 0.75 : 1,
      borderColor: props.outline ? props.color : '',
      borderWidth: props.outline ? 1 : 0
    },
    buttonText: {
      color: props.outline ? props.color : Colors.white,
      fontSize: FontSizes.medium,
      fontFamily: FontFamilys.bold
    }
  });
  return (
    <Pressable style={{ ...styles.button }} onPress={props.onPress} disabled={props.loading}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {props.loading &&
          <>
            <ActivityIndicator color={Colors.white}></ActivityIndicator>
            <AppGap size={10}></AppGap>
          </>
        }
        <Text style={styles.buttonText}>{props.text}</Text>
      </View>
    </Pressable>
  );
}
