import { Platform, Image } from 'react-native';
import Line from "../assets/images/line.svg";

type AppLineSvgIconProps = {}

export function AppLineSvgIcon(props: AppLineSvgIconProps) {
  return (
    <>
      {Platform.OS === 'web' && <Image source={require('../assets/images/line.svg')} style={{ width: 30, height: 35 }} />}
      {Platform.OS !== 'web' && <Line width={30} height={35} />}
    </>
  );
}