import { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import ResponsiveWidths from '../constants/ResponsiveWidths';

export default function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);
  const dimensions = useWindowDimensions();

  useEffect(() => {
    setIsMobile(dimensions.width <= ResponsiveWidths.md)
  }, [dimensions.width]);

  return isMobile;
}
