import { View, Text } from 'react-native';
import Colors from '../constants/Colors';
import FlexStyles from '../constants/FlexStyles';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';

type AppCircleProps = {
  size: number;
  backgroundColor: string;
  text: string;
  fontSize?: number;
  fontColor?: string;
}

export function AppCircle(props: AppCircleProps) {
  return (
    <View style={{ ...FlexStyles.rowCenter, borderRadius: props.size, width: props.size, height: props.size, backgroundColor: props.backgroundColor }}>
      <Text style={{ ...TextStyles.subtitle, color: props.fontColor ? props.fontColor : Colors.green, fontSize: props.fontSize ? props.fontSize : Sizes.large }}>{props.text}</Text>
    </View>
  );
}
