import { useState } from 'react';
import { View, Text, Switch } from 'react-native';
import Colors from '../constants/Colors';
import FlexStyles from '../constants/FlexStyles';
import FontFamilys from '../constants/FontFamilys';
import Sizes from '../constants/Sizes';
import useIsMobile from '../hooks/useIsMobile';

type AppSwitchProps = {
  text: string;
  onToggle: ((event: boolean) => void);
}

export function AppSwitch(props: AppSwitchProps) {
  const isMobile = useIsMobile();
  const [isEnabled, setIsEnabled] = useState(false);
  const toggleSwitch = () => {
    props.onToggle(!isEnabled);
    setIsEnabled(previousState => !previousState)
  };

  return (
    <View style={{ ...FlexStyles.rowVerticalCenter, marginTop: Sizes.small, marginBottom: Sizes.large }}>
      <Switch
        trackColor={{ false: isMobile ? Colors.white : Colors.lightGrey, true: Colors.darkGrey }}
        thumbColor={isEnabled ? Colors.darkGrey : isMobile ? Colors.darkGrey : Colors.green}
        ios_backgroundColor="#3e3e3e"
        onValueChange={toggleSwitch}
        value={isEnabled}
      />
      <Text style={{ fontFamily: FontFamilys.regular, color: isMobile ? Colors.white : Colors.black, marginLeft: Sizes.small, fontSize: isMobile ? undefined : 12 }}>{props.text}</Text>
    </View>
  );
}
