import { Pressable, View, Text, Image, StyleSheet, Linking } from 'react-native';
import Colors from '../constants/Colors';
import { AppButton } from './AppButton';
import TextStyles from '../constants/TextStyles';
import { setLoggedIn } from '../store/user';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { useNavigation, useRoute } from '@react-navigation/native';
import { tokenService } from '../services/TokenService';
import Heights from '../constants/Heights';
import useHorizontalContainerMargin from '../hooks/useHorizontalContainerMargin';

type AppHeaderProps = {
    onlyLogo?: boolean;
}

export function AppHeader(props: AppHeaderProps) {
    const user = useSelector((state: RootState) => state.user);

    const marginHorizontal = useHorizontalContainerMargin();
    const route = useRoute();
    const navigation = useNavigation();
    const dispatch = useDispatch<AppDispatch>();
    const handleLogout = () => {
        const logout = async () => {
            await tokenService.deleteAccessTokenAsync();
            await tokenService.deleteAccessTokenExpiryAsync();
            await tokenService.deleteRefreshTokenAsync();
            await tokenService.deleteRefreshTokenExpiryAsync();
            dispatch(setLoggedIn(false));
        }
        logout().catch(err => console.error(err));
    };

    return (
        <View style={{ shadowColor: Colors.grey, shadowRadius: 10, backgroundColor: Colors.white, }}>
            <View style={{ height: Heights.header, flexDirection: 'row', justifyContent: props.onlyLogo ? 'flex-start' : 'center', marginHorizontal: marginHorizontal }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    {(user && user.loggedIn) ? 
                      <View>
                        <Image source={require('../assets/images/logo.png')} style={{ width: 173, height: 37 }} />
                      </View> 
                      : 
                      <Pressable onPress={() => window.open("https://gophonebox.com/", "_self")}>
                        <Image source={require('../assets/images/logo.png')} style={{ width: 173, height: 37 }} />
                      </Pressable>
                    }
                    {!props.onlyLogo &&
                        <>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: 200 }}>
                                <Pressable onPress={() => navigation.navigate('Root', { screen: "Home" })}>
                                    <Text style={(route.name === 'Home' ? styles.active : TextStyles.navLink)}>Home</Text>
                                </Pressable>
                                <Pressable onPress={() => navigation.navigate('Profile')}>
                                    <Text style={(route.name === 'Profile' ? styles.active : TextStyles.navLink)}>Profile</Text>
                                </Pressable>
                            </View>
                            <AppButton text="Log Out" onPress={handleLogout} color={Colors.blue}></AppButton>
                        </>
                    }
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    active: {
        ...TextStyles.heading,
        color: Colors.blue,
        borderBottomColor: Colors.blue,
        borderBottomWidth: 3,
    }
});