import { ScrollView, StyleSheet, View, Text } from 'react-native';
import { AppButton } from '../components/AppButton';
import Colors from '../constants/Colors';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import { RootStackScreenProps, RootTabScreenProps } from '../types';

export default function PlanChangedScreen({ navigation }: RootStackScreenProps<'PlanChanged'>) {
  return (
    <View style={{ ...PageLayoutStyles.container, paddingTop: Sizes.none }}>
      <Text style={{ ...TextStyles.title, color: Colors.white }}>Plan Changed</Text>
      <Text style={{ ...TextStyles.subtitle, color: Colors.white, marginBottom: Sizes.large }}>You changed your plan</Text>
      <AppButton text="Back" onPress={() => { navigation.pop(2); navigation.navigate("Root"); }} color={Colors.white} outline></AppButton>
    </View>
  );
}
