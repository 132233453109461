import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IntlCallingAddOn } from "../types/models/IntlCallingAddOn";

const appSlice = createSlice({
  name: "app",
  initialState: {
    moreInfo: [] as string[],
    availableIntlCallAddOn: [] as IntlCallingAddOn[],
    selectedAddOn: null as IntlCallingAddOn | null,
  },
  reducers: {
    setMoreInfo(state, action: PayloadAction<string[]>) {
      state.moreInfo = action.payload;
    },
    setAvailableIntlCallAddOn(
      state,
      action: PayloadAction<IntlCallingAddOn[]>
    ) {
      state.availableIntlCallAddOn = action.payload;
    },
    setSelectedAddOn(state, action: PayloadAction<IntlCallingAddOn | null>) {
      state.selectedAddOn = action.payload;
    },
  },
});

export const { setMoreInfo, setAvailableIntlCallAddOn, setSelectedAddOn } =
  appSlice.actions;
export default appSlice.reducer;
