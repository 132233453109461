import { Platform, Image } from 'react-native';
import Talk from "../assets/images/talk.svg";

type AppTalkSvgIconProps = {}

export function AppTalkSvgIcon(props: AppTalkSvgIconProps) {
  return (
    <>
      {Platform.OS === 'web' && <Image source={require('../assets/images/talk.svg')} style={{ width: 35, height: 35 }} />}
      {Platform.OS !== 'web' && <Talk width={35} height={35} />}
    </>
  );
}