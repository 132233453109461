import { Text, View } from 'react-native';
import Colors from '../constants/Colors';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';

type AppTextThumbnailProps = {
  width: number;
  height: number;
  title: string;
  subtitle: string;
  titleFontSize?: number;
}

export function AppTextThumbnail(props: AppTextThumbnailProps) {
  return (
    <View style={{ width: props.width, height: props.height, borderRadius: Sizes.medium, backgroundColor: Colors.dimGreen, paddingHorizontal: Sizes.medium, justifyContent: 'center', alignItems: 'center' }}>
      <Text style={{ ...TextStyles.subtitle, color: Colors.green, fontSize: props.titleFontSize ? props.titleFontSize : 17 }}>{props.title}</Text>
      <Text style={{ ...TextStyles.paragraph, color: Colors.green }}>{props.subtitle}</Text>
    </View>
  );
}
