import { useState } from 'react';
import { ScrollView, View, Text } from 'react-native';
import { AppAlert } from '../components/AppAlert';
import { AppButton } from '../components/AppButton';
import { AppGap } from '../components/AppGap';
import { AppSwitch } from '../components/AppSwitch';
import { AppTextInput } from '../components/AppTextInput';
import Colors from '../constants/Colors';
import FlexStyles from '../constants/FlexStyles';
import FontFamilys from '../constants/FontFamilys';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import { profileService } from '../services/ProfileService';
import { RootStackScreenProps } from '../types';

export default function ProfileUpdatePasswordScreen({ navigation }: RootStackScreenProps<'ProfileUpdatePassword'>) {
  const [showPassword, setShowPassword] = useState(false);
  const [step, setStep] = useState(1);
  const [currentPassword, setCurrentPassword] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [checkingCurrentPassword, setCheckingCurrentPassword] = useState(false);

  const [newPassword, setNewPassword] = useState('');
  const [newConfirmPassword, setNewConfirmPassword] = useState('');
  const [updatePasswordError, setUpdatePasswordErrorError] = useState('');
  const [updatingPassword, setUpdatingPassword] = useState(false);

  const handleStep = async () => {
    switch (step) {
      case 1:
        setCurrentPasswordError('');
        setCheckingCurrentPassword(true);
        var response = await profileService.checkPassword({ data: { current_password: currentPassword } });
        if (!response || !response.success) {
          setCurrentPasswordError(response.error.message);
          setCheckingCurrentPassword(false);
          console.error(response.error);
          break;
        }
        setCurrentPasswordError('');
        setCheckingCurrentPassword(false);
        setStep(2);
        break;
      case 2:
        // TODO: API call to update with new password
        setUpdatePasswordErrorError('');
        setUpdatingPassword(true);
        var response = await profileService.changePassword({ data: { current_password: currentPassword, new_password: newPassword, new_confirm_password: newConfirmPassword } });
        if (!response || !response.success) {
          setUpdatePasswordErrorError(response.error.message);
          setUpdatingPassword(false);
          console.error(response.error);
          break;
        }
        setUpdatePasswordErrorError('');
        setUpdatingPassword(false);
        navigation.goBack()
        break;
    }

  }
  return (
    <ScrollView>
      <View style={{ ...PageLayoutStyles.container, paddingTop: Sizes.none }}>
        <Text style={{ ...TextStyles.title, color: Colors.white }}>Profile</Text>
        {step === 1 &&
          <>
            <Text style={{ ...TextStyles.subtitle, color: Colors.white, marginTop: Sizes.large, marginBottom: Sizes.small }}>Confirm Your Password</Text>
            <AppTextInput placeholder="Current Password" onChangeText={(text) => setCurrentPassword(text)} secureTextEntry={true}></AppTextInput>
            <Text style={{ fontFamily: FontFamilys.regular, color: Colors.white, marginTop: Sizes.medium }}>For security reasons, please input your current password.</Text>
            <View style={{ ...FlexStyles.rowCenter, marginTop: Sizes.large }}>
              <AppButton text="Confirm Password" onPress={handleStep} color={Colors.white} outline loading={checkingCurrentPassword}></AppButton>
            </View>
            {currentPasswordError.length > 0 && (
              <View style={{ marginTop: Sizes.medium }}>
                <AppAlert text={currentPasswordError}></AppAlert>
              </View>
            )}
          </>
        }
        {step === 2 &&
          <>
            <Text style={{ ...TextStyles.subtitle, color: Colors.white, marginTop: Sizes.large, marginBottom: Sizes.small }}>New Password</Text>
            <AppTextInput placeholder="New Password" onChangeText={(text) => setNewPassword(text)} secureTextEntry={!showPassword}></AppTextInput>
            <Text style={{ fontFamily: FontFamilys.regular, color: Colors.white, marginTop: Sizes.medium }}>* Must be at least 8 characters, include a capital, and a number</Text>

            <Text style={{ ...TextStyles.subtitle, color: Colors.white, marginTop: Sizes.large, marginBottom: Sizes.small }}>Confirm New Password</Text>
            <AppTextInput placeholder="Confirm New Password" onChangeText={(text) => setNewConfirmPassword(text)} secureTextEntry={!showPassword}></AppTextInput>
            <AppGap direction="vertical" size={10}></AppGap>
            <AppSwitch onToggle={(toggled) => setShowPassword(toggled)} text="Show password"></AppSwitch>
            <View style={{ ...FlexStyles.rowCenter, marginTop: Sizes.large }}>
              <AppButton text="Save Password" onPress={handleStep} color={Colors.white} outline loading={updatingPassword}></AppButton>
            </View>
            {updatePasswordError.length > 0 && (
              <View style={{ marginTop: Sizes.medium }}>
                <AppAlert text={updatePasswordError}></AppAlert>
              </View>
            )}
          </>
        }
      </View>

    </ScrollView>
  );
}
