import { FontAwesome } from '@expo/vector-icons';
import { ScrollView, StyleSheet, View, Text, Pressable } from 'react-native';
import { AppBoldText } from '../components/AppBoldText';
import { AppButton } from '../components/AppButton';

import Colors from '../constants/Colors';
import CommonStyles from '../constants/CommonStyles';
import FontFamilys from '../constants/FontFamilys';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import { RootStackScreenProps, RootTabScreenProps } from '../types';
import { ExtensionType } from '../types/enums/ExtensionType';
import { useDispatch, useSelector } from 'react-redux';
import { setAutopayToEnable, setExtensionType, setTopUpDataAmount, setTopUpPaymentAmount } from '../store/user';
import { useEffect, useState } from 'react';
import { customerService } from '../services/CustomerService';
import { AppDispatch, RootState } from '../store';

export default function AddDataScreen({ navigation }: RootStackScreenProps<'AddData'>) {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch<AppDispatch>();
  const [dataAmount, setDataAmount] = useState(1);
  const [loading, setLoading] = useState(false);
  const topUpFee = (dataAmount * 10);
  const [total, setTotal] = useState(10);
  const [taxes, setTaxes] = useState(0);

  const onClickConfirm = () => {
    dispatch(setExtensionType(ExtensionType.TopUp));
    dispatch(setTopUpDataAmount(dataAmount));
    dispatch(setAutopayToEnable(false));
    navigation.navigate('MakeAPayment')
  }

  const changeDataAmount = (newValue: number) => {
    if (newValue < 1 || newValue > 3) {
      return
    }
    setDataAmount(newValue);
  }

  const handleTaxes = async () => {
    setLoading(true);
    let topUpISubscription = 0;

    if (dataAmount === 1) {
      topUpISubscription = 5
    } else if (dataAmount === 2) {
      topUpISubscription = 47
    } else if (dataAmount === 3) {
      topUpISubscription = 48
    }

    var response = await customerService.estimateTaxes({
      data: {
        calc_taxes: 1,
        i_customer: user.selectedAccount.customer_info.customer_id,
        xdr_list: [
          {
            charged_amount: topUpFee,
            i_dest: 18,
            i_service: 4,
            i_subscription: topUpISubscription
          }
        ]
      }
    })
    if (!response || !response.success) {
      console.error(response.error);
      setLoading(false);
      return;
    }
    if (response && response.success) {
      let cumulativeTax = 0;
      if (response.data.taxes_list) {
        response.data.taxes_list.forEach((entry) => {
          cumulativeTax = cumulativeTax + entry.amount
        })
      }
      if (cumulativeTax === 0.6) {
        cumulativeTax = 0;
      }
      // TODO: remove this
      // due to glitches with taxes, set tax to zero for Plan Data Usage.
      cumulativeTax = 0;

      setTaxes(cumulativeTax);
      console.log(cumulativeTax);
      console.log(topUpFee);
      const newTotal = +(cumulativeTax + topUpFee).toFixed(2);
      console.log('new total: ', newTotal);
      setTotal(newTotal);
      dispatch(setTopUpPaymentAmount(newTotal));
      setTimeout(() => setLoading(false), 500);
    }
  }

  useEffect(() => {
    handleTaxes().catch(err => console.error(err));
  }, [dataAmount]);

  return (
    <ScrollView>
      <View style={{ ...PageLayoutStyles.container, paddingTop: Sizes.none }}>
        <Text style={{ ...TextStyles.title, color: Colors.white }}>Add Data</Text>
        <View style={{ ...CommonStyles.card, padding: 0, backgroundColor: Colors.black, marginTop: Sizes.large }}>
          <Text style={{ ...TextStyles.paragraph, color: Colors.white, textAlign: 'center', marginVertical: 10 }}>Your data will be reset on: <AppBoldText>{`${new Date(user.selectedAccount.plan.reset_date).toLocaleDateString('en-US')}`}</AppBoldText></Text>
          <View style={{ ...CommonStyles.card, padding: 0 }}>
            <View style={{ padding: CommonStyles.card.padding }}>
              <View style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <Text style={{ ...TextStyles.subtitle, color: Colors.green, textAlign: 'center', fontFamily: FontFamilys.bold, marginBottom: 10 }}>Data Top Up Rate: USD 10/1GB</Text>
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: Sizes.large }}>
                  <Pressable onPress={() => changeDataAmount(dataAmount - 1)}>
                    <View style={{ backgroundColor: dataAmount === 1 ? Colors.lightGrey : Colors.green, width: 40, height: 40, borderRadius: 40, justifyContent: 'center', alignItems: 'center' }}>
                      <FontAwesome name="minus" size={25} color={Colors.white}></FontAwesome>
                    </View>
                  </Pressable>
                  <Text style={{ ...TextStyles.title, color: Colors.green, marginHorizontal: 20 }}>{`${dataAmount} GB`}</Text>
                  <Pressable onPress={() => changeDataAmount(dataAmount + 1)}>
                    <View style={{ backgroundColor: dataAmount === 3 ? Colors.lightGrey : Colors.green, width: 40, height: 40, borderRadius: 40, justifyContent: 'center', alignItems: 'center' }}>
                      <FontAwesome name="plus" size={25} color={Colors.white}></FontAwesome>
                    </View>
                  </Pressable>
                </View>
              </View>
              <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginHorizontal: 20 }}>
                <Text style={{ ...TextStyles.subtitle, color: Colors.black, textAlign: 'center', fontFamily: FontFamilys.bold, marginBottom: 10 }}>Data Top Up:</Text>
                <Text style={{ ...TextStyles.subtitle, color: Colors.black, textAlign: 'center', fontFamily: FontFamilys.regular, marginBottom: 10 }}>${topUpFee.toFixed(2)}</Text>
              </View>
              <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginHorizontal: 20 }}>
                <Text style={{ ...TextStyles.subtitle, color: Colors.black, textAlign: 'center', fontFamily: FontFamilys.bold, marginBottom: 10 }}>Taxes & Surcharges:</Text>
                <Text style={{ ...TextStyles.subtitle, color: Colors.black, textAlign: 'center', fontFamily: FontFamilys.regular, marginBottom: 10 }}>${taxes.toFixed(2)}</Text>
              </View>
              {/* <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginHorizontal: 30 }}>
                <Text style={{ ...TextStyles.subtitle, color: Colors.black, textAlign: 'center', fontFamily: FontFamilys.bold, marginBottom: 10 }}>Current Balance:</Text>
                <Text style={{ ...TextStyles.subtitle, color: Colors.black, textAlign: 'center', fontFamily: FontFamilys.regular, marginBottom: 10 }}>$-34.80</Text>
              </View> */}
              {!loading &&
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', borderTopColor: Colors.lightGrey, borderTopWidth: 1, paddingTop: CommonStyles.card.padding, paddingHorizontal: CommonStyles.card.padding }}>
                  <Text style={{ ...TextStyles.subtitle, color: Colors.green, textAlign: 'center', fontFamily: FontFamilys.bold }}>Total</Text>
                  <Text style={{ ...TextStyles.subtitle, color: Colors.green, textAlign: 'center', fontFamily: FontFamilys.regular }}>${total.toFixed(2)}</Text>
                </View>
              }
            </View>
          </View>
        </View>
        <View style={{ marginTop: Sizes.large }}>
          <AppButton loading={loading} text="Confirm" onPress={onClickConfirm}></AppButton>
        </View>
      </View>
    </ScrollView>
  );
}
