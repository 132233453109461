import { useState } from 'react';
import { ScrollView, View, Text } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { AppButton } from '../components/AppButton';
import { AppTextInput } from '../components/AppTextInput';
import Colors from '../constants/Colors';
import FlexStyles from '../constants/FlexStyles';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import { AppDispatch, RootState } from '../store';
import { setEmail } from '../store/user';
import { RootStackScreenProps } from '../types';

export default function ProfileUpdateEmailScreen({ navigation }: RootStackScreenProps<'ProfileUpdateEmail'>) {
  const user = useSelector((state: RootState) => state.user);
  const [emailAddress, setEmailAddress] = useState(user.selectedAccount.email);
  const dispatch = useDispatch<AppDispatch>();
  const updateEmail = () => {
    // TODO: API call to update
    dispatch(setEmail(emailAddress));
    navigation.goBack();
  }

  return (
    <ScrollView>
      <View style={{ ...PageLayoutStyles.container, paddingTop: Sizes.none }}>
        <Text style={{ ...TextStyles.title, color: Colors.white }}>Profile</Text>
        <Text style={{ ...TextStyles.subtitle, color: Colors.white, marginTop: Sizes.large, marginBottom: Sizes.small }}>Email</Text>
        <AppTextInput value={emailAddress} placeholder="Email" onChangeText={(text) => setEmailAddress(text)}></AppTextInput>
        <View style={{ ...FlexStyles.rowCenter, marginTop: Sizes.large }}>
          <AppButton text="Update Email" onPress={updateEmail} color={Colors.white} outline></AppButton>
        </View>
      </View>
    </ScrollView>
  );
}
