import { GestureResponderEvent, Pressable, Text } from 'react-native';
import Colors from '../constants/Colors';
import FontFamilys from '../constants/FontFamilys';

type AppLinkProps = {
  text: string;
  center?: boolean;
  color?: string;
  onPress: ((event: GestureResponderEvent) => void) | undefined;
}

export function AppLink(props: AppLinkProps) {
  return (
    <Pressable onPress={props.onPress}>
      <Text style={{ color: props.color ? props.color : Colors.white, fontFamily: FontFamilys.regular, textDecorationLine: 'underline', textAlign: props.center ? 'center' : 'auto' }}>{props.text}</Text>
    </Pressable>
  );
}
