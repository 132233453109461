import { GestureResponderEvent, Text, View, Pressable } from "react-native";
import Colors from "../constants/Colors";
import CommonStyles from "../constants/CommonStyles";
import TextStyles from "../constants/TextStyles";
import Sizes from "../constants/Sizes";
import { AppBoldText } from "./AppBoldText";
import { AppTextThumbnail } from "./AppTextThumbnail";
import { AppGap } from "./AppGap";
import { useEffect, useState } from "react";
import useIsMobile from "../hooks/useIsMobile";
import { AppCard } from "./AppCard";
import FontFamilys from "../constants/FontFamilys";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../store";
import { AppButton } from "./AppButton";
import { useNavigation } from "@react-navigation/native";
import { FontAwesome } from "@expo/vector-icons";
import { customerService } from "../services/CustomerService";
import { productService } from "../services/ProductService";
import { ExtensionType } from "../types/enums/ExtensionType";
import {
  setAutopayToEnable,
  setPaymentAmount,
  setExtensionDate,
  setExtensionType,
  setPlanChangeName,
  setPlanChangeRecurringIProduct,
  setPlanChangeServiceIProduct,
} from "../store/user";
import { AppPrice } from "./AppPrice";
import { useWindowDimensions } from "react-native";
import { StepType } from "../types/enums/StepType";
import { MiniLoading } from "../components/MiniLoading";

type PlanExtensionCardProps = {
  endDate: Date;
  termEndDate: Date;
  price: number;
  onPress?: ((event: GestureResponderEvent) => void) | undefined;
  allowExtension: boolean;
  allowPlanChange: boolean;
  extendDetailsVisible?: boolean;
  // is7daysPlan: boolean;
  isLessThan30DaysPlan: boolean;
};

export function PlanExtensionCard(props: PlanExtensionCardProps) {
  const dispatch = useDispatch<AppDispatch>();
  const navigation = useNavigation();
  const dimensions = useWindowDimensions();
  const planExpired = new Date() > new Date(props.endDate);
  const isMobile = useIsMobile();
  const user = useSelector((state: RootState) => state.user);
  let testProp = props.extendDetailsVisible
    ? props.extendDetailsVisible
    : false;
  const [extendDetailsVisible, setExtendDetailsVisible] = useState(testProp);
  const [planChangeDetailsVisible, setPlanChangeDetailsVisible] =
    useState(false);
  const [taxes, setTaxes] = useState(0);
  const [total, setTotal] = useState(0);
  const [planChangeTaxes, setPlanChangeTaxes] = useState(0);
  const [planChangeTotal, setPlanChangeTotal] = useState(0);
  const [checked, setChecked] = useState(
    user.selectedAccount.customer_info.auto_payment
  );
  const [smallWidths, setSmallWidths] = useState(false);
  const [selectedPlanISubscription, setSelectedPlanISubscription] = useState<
    number | undefined
  >(undefined);
  const [selectedPlanPrice, setSelectedPlanPrice] = useState(0);
  const [step, setStep] = useState(StepType.First);
  const [isLoading, setIsLoading] = useState(false);
  const selectedPlan = user.availablePlans.find(
    (plan) => plan.i_subscription === selectedPlanISubscription
  );
  const planHasBeenChanged =
    user.selectedAccount.future_plan?.end_date !== null &&
    user.selectedAccount.future_plan?.start_date !== null;
  const futurePlanName = (
    <Text style={{ fontWeight: "600" }}>
      {user.selectedAccount.future_plan?.data}{" "}
      {user.selectedAccount.future_plan?.data_unit} at $
      {user.selectedAccount.future_plan?.price}
      {user.selectedAccount.future_plan?.interval}
    </Text>
  );
  const futurePlanStartDate = (
    <Text style={{ fontWeight: "600" }}>
      {new Date(
        user.selectedAccount.future_plan?.start_date
      ).toLocaleDateString("en-US")}
    </Text>
  );
  const endDateForDisplay = planHasBeenChanged
    ? new Date(user.selectedAccount.future_plan?.start_date).toLocaleDateString(
        "en-US"
      )
    : new Date(props.termEndDate).toLocaleDateString("en-US");

  const getDaysRemaining = (start: Date, end: Date) => {
    const differenceInTime = end.getTime() - start.getTime();
    return differenceInTime / (1000 * 3600 * 24);
  };

  // extra fee of $10 if user has been deactivated for over 30 days.
  const extraFee = getDaysRemaining(new Date(), props.endDate) < -31 ? 10 : 0;

  // extension and plan change date setting
  let newEndDate = new Date(props.endDate.toLocaleDateString("en-US"));
  if (planExpired) {
    newEndDate = new Date();
  }
  newEndDate.setDate(newEndDate.getDate() + 30);
  const formattedDateString = !isNaN(newEndDate.getTime())
    ? newEndDate.toISOString().split("T")[0]
    : null;

  // restoration date setting
  let restoreDate = new Date();
  restoreDate.setDate(restoreDate.getDate() + 30);
  const formattedRestoreDate = !isNaN(restoreDate.getTime())
    ? restoreDate.toISOString().split("T")[0]
    : null;

  const handleTaxes = async (
    price: number,
    subscription: number,
    extensionType: ExtensionType
  ) => {
    setIsLoading(true);
    var response = await customerService.estimateTaxes({
      data: {
        calc_taxes: 1,
        i_customer: user.selectedAccount.customer_info.customer_id,
        xdr_list: [
          {
            charged_amount: price,
            i_dest: 17,
            i_service: 4,
            i_subscription: subscription,
          },
        ],
      },
    });
    if (!response || !response.success) {
      console.error(response.error);
      setIsLoading(false);
      return;
    }
    if (response && response.success) {
      let cumulativeTax = 0;

      if (response.data.taxes_list) {
        response.data.taxes_list.forEach((entry) => {
          cumulativeTax = cumulativeTax + entry.amount;
        });
      }
      if (extensionType === ExtensionType.PlanChange) {
        setPlanChangeTaxes(cumulativeTax);
        const newPlanChangeTotal = +(cumulativeTax + price + extraFee).toFixed(
          2
        );
        setPlanChangeTotal(newPlanChangeTotal);
        dispatch(setPaymentAmount(newPlanChangeTotal));
        setTimeout(() => {
          setIsLoading(false);
        }, 400);
        return;
      }
      setTaxes(cumulativeTax);
      const newTotal = +(cumulativeTax + price + extraFee).toFixed(2);
      setTotal(newTotal);
      dispatch(setPaymentAmount(newTotal));
    }
  };

  const onPressConfirm = async () => {
    if (!planExpired && formattedDateString) {
      dispatch(setExtensionDate(formattedDateString));
      dispatch(setExtensionType(ExtensionType.Extension));
    } else if (formattedRestoreDate) {
      dispatch(setExtensionDate(formattedRestoreDate));
      dispatch(setExtensionType(ExtensionType.Restoration));
    } else {
      return;
    }

    setExtendDetailsVisible(false);
    if (checked) {
      dispatch(setAutopayToEnable(true));
    } else {
      dispatch(setAutopayToEnable(false));
    }
    navigation.navigate("MakeAPayment");
  };

  useEffect(() => {
    setSmallWidths(dimensions.width <= 1540);
  }, [dimensions.width]);

  useEffect(() => {
    if (step === StepType.First) {
      handleTaxes(
        props.price,
        user.selectedAccount.subscription,
        ExtensionType.Extension
      ).catch((err) => console.error(err));
    }
    if (
      step === StepType.Second &&
      selectedPlanISubscription &&
      selectedPlanPrice
    ) {
      handleTaxes(
        selectedPlanPrice,
        selectedPlanISubscription,
        ExtensionType.PlanChange
      ).catch((err) => console.error(err));
    }
  }, [step]);

  useEffect(() => {
    const getProductList = async (selectedPlanName: string) => {
      var response = await productService.getProductList({
        data: {
          search: selectedPlanName,
        },
      });

      if (!response || !response.success) {
        console.error(response.error);
      }
      if (response && response.success) {
        // should only have one item
        if (
          response.data.product_list &&
          response.data.product_list[0].i_product
        ) {
          dispatch(
            setPlanChangeServiceIProduct(
              response.data.product_list[0].i_product
            )
          );
        }
      }
    };
    if (selectedPlan) {
      // convert plan name from 're' to 'se' to find the 'service_i_product' parameter.
      let searchName =
        selectedPlan.name.substring(0, selectedPlan.name.length - 2) + "se";
      getProductList(searchName).catch((err) => console.error(err));
      // the current selectedPlan contains the 'recurring_i_product' paramter.
      dispatch(setPlanChangeRecurringIProduct(selectedPlan.i_product));
    }
  }, [selectedPlan]);

  const onPressChangePlan = () => {
    setPlanChangeDetailsVisible(true);
  };

  const PlanChangeDetails = () => {
    const onPressBack = () => {
      switch (step) {
        case StepType.First:
          setPlanChangeDetailsVisible(false);
          break;
        case StepType.Second:
          setStep(StepType.First);
      }
    };

    const onPressNext = () => {
      if (selectedPlan && selectedPlan.end_user_name) {
        let planPrice = parseInt(selectedPlan.end_user_name.split("_")[0]);
        let planName = `${selectedPlan.end_user_name.split("_")[2]} ${
          selectedPlan.end_user_name.split("_")[3]
        }`;
        setSelectedPlanPrice(planPrice);
        dispatch(setPlanChangeName(planName));
        setStep(StepType.Second);
      }
    };

    const onPressConfirmPlanChange = () => {
      if (formattedDateString) {
        dispatch(setExtensionDate(formattedDateString));
        dispatch(setExtensionType(ExtensionType.PlanChange));
        setPlanChangeDetailsVisible(false);
        // setStep(StepType.First);
        navigation.navigate("MakeAPayment");
      }
    };

    return (
      <View>
        {step === StepType.First && (
          <>
            <View>
              {user.availablePlans
                .filter(
                  (plan) =>
                    plan.i_subscription !== user.selectedAccount.subscription &&
                    plan.i_product !== 55 &&
                    plan.end_user_description?.TermDays == 30
                )
                .map((plan, i) => {
                  let parsedPlan = plan.end_user_name.split("_");
                  let dataAmt = `${parsedPlan[2]} ${parsedPlan[3]}`;
                  let cost = +parsedPlan[0];
                  return (
                    <Pressable
                      key={i}
                      onPress={() =>
                        setSelectedPlanISubscription(plan.i_subscription)
                      }
                      style={{
                        flex: 1,
                        flexDirection: smallWidths ? "column" : "row",
                        alignItems: smallWidths ? "flex-start" : "center",
                        borderColor: Colors.green,
                        borderWidth: 1,
                        borderRadius: 10,
                        padding: Sizes.medium,
                        marginTop: Sizes.medium,
                        backgroundColor:
                          selectedPlanISubscription === plan.i_subscription
                            ? Colors.dimGreen
                            : Colors.white,
                      }}
                    >
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          justifyContent: smallWidths
                            ? "space-between"
                            : "flex-start",
                          paddingRight: Sizes.medium,
                          width: "100%",
                        }}
                      >
                        <Text
                          style={{
                            ...TextStyles.superSubtitle,
                            color: Colors.green,
                            width: 100,
                          }}
                        >
                          {dataAmt}
                        </Text>
                        <AppPrice
                          color={Colors.black}
                          price={cost}
                          currency={"USD"}
                          interval={"/mo"}
                          size={"md"}
                        ></AppPrice>
                      </View>
                      <View style={{ padding: Sizes.small, width: 220 }}>
                        <Text
                          style={{
                            fontSize: 10,
                            fontFamily: FontFamilys.regular,
                            color: Colors.black,
                          }}
                        >
                          {plan.description}
                        </Text>
                      </View>
                    </Pressable>
                  );
                })}
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: Sizes.large,
              }}
            >
              <Pressable
                style={{ paddingRight: 8 }}
                onPress={() => onPressBack()}
              >
                <FontAwesome
                  style={{ color: Colors.darkGrey }}
                  size={30}
                  name={"chevron-circle-left"}
                ></FontAwesome>
              </Pressable>
              <AppButton text="Next" onPress={() => onPressNext()}></AppButton>
            </View>
          </>
        )}
        {step === StepType.Second &&
          selectedPlan &&
          (function () {
            let parsedPlan = selectedPlan.end_user_name.split("_");
            let dataAmt = `${parsedPlan[2]} ${parsedPlan[3]}`;
            let cost = +parsedPlan[0];
            return (
              <View>
                <View
                  style={{
                    flex: 1,
                    flexDirection: smallWidths ? "column" : "row",
                    alignItems: smallWidths ? "flex-start" : "center",
                    borderColor: Colors.green,
                    borderWidth: 1,
                    borderRadius: 10,
                    padding: Sizes.medium,
                    marginTop: Sizes.medium,
                    backgroundColor: Colors.dimGreen,
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: smallWidths
                        ? "space-between"
                        : "flex-start",
                      paddingRight: Sizes.medium,
                      width: "100%",
                    }}
                  >
                    <Text
                      style={{
                        ...TextStyles.superSubtitle,
                        color: Colors.green,
                        width: 100,
                      }}
                    >
                      {dataAmt}
                    </Text>
                    <AppPrice
                      color={Colors.black}
                      price={cost}
                      currency={"USD"}
                      interval={"/mo"}
                      size={"md"}
                    ></AppPrice>
                  </View>
                  <View style={{ padding: Sizes.small, width: 250 }}>
                    <Text
                      style={{
                        fontSize: 10,
                        fontFamily: FontFamilys.regular,
                        color: Colors.black,
                      }}
                    >
                      {selectedPlan.description}
                    </Text>
                  </View>
                </View>
                {isLoading && <MiniLoading />}
                {!isLoading && (
                  <View
                    style={{ alignItems: "center", marginTop: Sizes.medium }}
                  >
                    <Text
                      style={{
                        ...TextStyles.subtitle,
                        color: Colors.green,
                        paddingBottom: 10,
                      }}
                    >
                      New Expiration Date:{" "}
                      {newEndDate.toLocaleDateString("en-US")}
                    </Text>
                    <View style={{ flex: 1, flexDirection: "row" }}>
                      <Text>Plan Fee:</Text>
                      <Text
                        style={{ ...TextStyles.semiSubtitle, paddingLeft: 4 }}
                      >
                        ${cost}
                      </Text>
                    </View>
                    <View style={{ flex: 1, flexDirection: "row" }}>
                      <Text>Taxes & Surcharges:</Text>
                      <Text
                        style={{ ...TextStyles.semiSubtitle, paddingLeft: 4 }}
                      >
                        ${planChangeTaxes.toFixed(2)}
                      </Text>
                    </View>
                    <Text
                      style={{
                        ...TextStyles.subtitle,
                        color: Colors.green,
                        paddingTop: 10,
                      }}
                    >
                      Total: ${planChangeTotal.toFixed(2)} USD
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: Sizes.large,
                      }}
                    >
                      <Pressable
                        style={{ paddingRight: 8 }}
                        onPress={() => onPressBack()}
                      >
                        <FontAwesome
                          style={{ color: Colors.darkGrey }}
                          size={30}
                          name={"chevron-circle-left"}
                        ></FontAwesome>
                      </Pressable>
                      <AppButton
                        text="Confirm"
                        onPress={() => onPressConfirmPlanChange()}
                      ></AppButton>
                    </View>
                  </View>
                )}
              </View>
            );
          })()}
      </View>
    );
  };

  return (
    <>
      {!isMobile && (
        <AppCard>
          <View
            style={{
              backgroundColor: planExpired ? Colors.red : Colors.green,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              padding: 8,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
            }}
          >
            <Text
              style={{
                fontSize: 16,
                fontFamily: FontFamilys.regular,
                color: "white",
              }}
            >
              {planExpired ? "Plan Expired | " : "Expiration Date: "}
            </Text>
            <Text
              style={{
                fontFamily: FontFamilys.semiBold,
                letterSpacing: 0.28,
                fontSize: 18,
                color: "white",
              }}
            >
              {props.endDate.toLocaleDateString("en-US")}
            </Text>
          </View>
          <View style={CommonStyles.cardBody}>
            {planChangeDetailsVisible && <PlanChangeDetails />}
            {/* PLAN NOT EXPIRED SECTION */}
            {!planExpired && !planChangeDetailsVisible && (
              <>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <AppTextThumbnail
                      width={100}
                      height={80}
                      title={getDaysRemaining(
                        new Date(),
                        props.endDate
                      ).toFixed(0)}
                      subtitle="Days Left"
                      titleFontSize={30}
                    ></AppTextThumbnail>
                    <AppGap size={20}></AppGap>
                    <View>
                      <Text style={{ ...TextStyles.subtitle }}>
                        Current Term End Date:
                      </Text>
                      <Text
                        style={{
                          ...TextStyles.subtitle,
                          fontFamily: FontFamilys.regular,
                        }}
                      >
                        {endDateForDisplay}
                      </Text>
                      {planHasBeenChanged && (
                        <View style={{ marginTop: 10 }}>
                          <Text
                            style={{
                              ...TextStyles.semiSubtitle,
                              color: Colors.blue,
                            }}
                          >
                            You Have Requested a Plan Change
                          </Text>
                          <Text
                            style={{
                              ...TextStyles.paragraph,
                              color: Colors.blue,
                              width: "70%",
                            }}
                          >
                            Your plan will be changed to {futurePlanName} on{" "}
                            {futurePlanStartDate}
                          </Text>
                        </View>
                      )}
                      {user.selectedAccount.customer_info.auto_payment &&
                        !planHasBeenChanged && (
                          <View style={{ width: "70%", marginTop: 10 }}>
                            <Text
                              style={{
                                ...TextStyles.semiSubtitle,
                                color: Colors.blue,
                              }}
                            >
                              You Have Set Up Auto Extension
                            </Text>
                            <Text
                              style={{
                                ...TextStyles.paragraph,
                                color: Colors.blue,
                              }}
                            >
                              You can cancel your auto extension on the profile
                              page.
                            </Text>
                          </View>
                        )}
                      {!user.selectedAccount.customer_info.auto_payment &&
                        !props.allowExtension &&
                        !planHasBeenChanged && (
                          <View style={{ width: "70%", marginTop: 10 }}>
                            <Text
                              style={{
                                ...TextStyles.semiSubtitle,
                                color: Colors.blue,
                              }}
                            >
                              You Have Already Extended Your Plan
                            </Text>
                            <Text
                              style={{
                                ...TextStyles.paragraph,
                                color: Colors.blue,
                              }}
                            >
                              You can only extend your services once per billing
                              cycle.
                            </Text>
                          </View>
                        )}
                    </View>
                  </View>
                </View>
                {!user.selectedAccount.customer_info.auto_payment &&
                  props.allowExtension &&
                  !props.isLessThan30DaysPlan && (
                    <>
                      <View>
                        <Text
                          style={{
                            ...TextStyles.paragraph,
                            marginBottom: Sizes.medium,
                            color: Colors.darkGrey,
                            padding: 8,
                          }}
                        >
                          *You are able to retrieve your phone number up to 90
                          days after your term expiration date (term end date).
                          There is no reactivation fee within the first 30 days
                          after the expiration. After 30 days, the reactivation
                          fee (service charge) will be USD 10 on top of your
                          plan fee.
                        </Text>
                      </View>
                      {extendDetailsVisible && (
                        <>
                          <View style={{ alignItems: "center" }}>
                            <Text
                              style={{
                                ...TextStyles.subtitle,
                                color: Colors.green,
                                paddingBottom: 10,
                              }}
                            >
                              New Expiration Date:{" "}
                              {newEndDate.toLocaleDateString("en-US")}
                            </Text>
                            <View style={{ flex: 1, flexDirection: "row" }}>
                              <Text>Plan Fee:</Text>
                              <Text
                                style={{
                                  ...TextStyles.semiSubtitle,
                                  paddingLeft: 4,
                                }}
                              >
                                ${props.price.toFixed(2)}
                              </Text>
                            </View>
                            <View style={{ flex: 1, flexDirection: "row" }}>
                              <Text>Taxes & Surcharges:</Text>
                              <Text
                                style={{
                                  ...TextStyles.semiSubtitle,
                                  paddingLeft: 4,
                                }}
                              >
                                ${taxes.toFixed(2)}
                              </Text>
                            </View>
                            <Text
                              style={{
                                ...TextStyles.subtitle,
                                color: Colors.green,
                                marginTop: Sizes.small,
                              }}
                            >
                              Total: ${total.toFixed(2)} USD
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "center",
                              marginTop: Sizes.medium,
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Pressable
                                style={{ paddingRight: 8 }}
                                onPress={() => setExtendDetailsVisible(false)}
                              >
                                <FontAwesome
                                  style={{ color: Colors.darkGrey }}
                                  size={30}
                                  name={"chevron-circle-left"}
                                ></FontAwesome>
                              </Pressable>
                              <AppButton
                                text="Confirm"
                                color={Colors.red}
                                onPress={() => onPressConfirm()}
                              ></AppButton>
                              <AppGap size={30}></AppGap>
                            </View>
                          </View>
                        </>
                      )}
                    </>
                  )}
                {!extendDetailsVisible && !props.isLessThan30DaysPlan && (
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "center",
                      marginTop: Sizes.medium,
                    }}
                  >
                    {!user.selectedAccount.customer_info.auto_payment &&
                      props.allowExtension && (
                        <AppButton
                          text="Extend"
                          onPress={() => setExtendDetailsVisible(true)}
                          color={Colors.red}
                        ></AppButton>
                      )}
                    {props.allowPlanChange &&
                      props.allowExtension &&
                      !user.selectedAccount.customer_info.auto_payment && (
                        <AppGap size={20}></AppGap>
                      )}
                    {props.allowPlanChange && props.allowExtension && (
                      <AppButton
                        text="Change Plan"
                        onPress={() => onPressChangePlan()}
                        color={Colors.blue}
                      ></AppButton>
                    )}
                  </View>
                )}
              </>
            )}
            {/* PLAN EXPIRED SECTION */}
            {planExpired && !planChangeDetailsVisible && (
              <>
                <View>
                  {extendDetailsVisible && (
                    <Text
                      style={{
                        ...TextStyles.paragraph,
                        marginBottom: Sizes.medium,
                        fontFamily: FontFamilys.bold,
                      }}
                    >
                      Your plan will be reactivated immediately after the
                      successful payment.
                    </Text>
                  )}
                  <Text
                    style={{
                      ...TextStyles.paragraph,
                      marginBottom: Sizes.medium,
                    }}
                  >
                    You are able to retrieve your phone number up to 90 days
                    after your term expiration date (term end date).
                  </Text>
                  <Text
                    style={{
                      ...TextStyles.paragraph,
                      marginBottom: Sizes.medium,
                    }}
                  >
                    There is no reactivation fee within the first 30 days after
                    the expiration.
                  </Text>
                  <Text
                    style={{
                      ...TextStyles.paragraph,
                      marginBottom: Sizes.medium,
                    }}
                  >
                    After 30 days, the reactivation fee will be USD 10 on top of
                    your plan fee.
                  </Text>
                </View>
                {extendDetailsVisible && (
                  <View style={{ alignItems: "center" }}>
                    <Text
                      style={{
                        ...TextStyles.subtitle,
                        color: Colors.green,
                        paddingBottom: 10,
                      }}
                    >
                      New Expiration Date:{" "}
                      {newEndDate.toLocaleDateString("en-US")}
                    </Text>
                    <View style={{ flex: 1, flexDirection: "row" }}>
                      <Text>Plan Fee:</Text>
                      <Text
                        style={{ ...TextStyles.semiSubtitle, paddingLeft: 4 }}
                      >
                        ${props.price.toFixed(2)}
                      </Text>
                    </View>
                    {extraFee > 0 && (
                      <View style={{ flex: 1, flexDirection: "row" }}>
                        <Text>Reactivation Fee:</Text>
                        <Text
                          style={{ ...TextStyles.semiSubtitle, paddingLeft: 4 }}
                        >
                          ${extraFee.toFixed(2)}
                        </Text>
                      </View>
                    )}
                    <View style={{ flex: 1, flexDirection: "row" }}>
                      <Text>Taxes & Surcharges:</Text>
                      <Text
                        style={{ ...TextStyles.semiSubtitle, paddingLeft: 4 }}
                      >
                        ${taxes.toFixed(2)}
                      </Text>
                    </View>
                    <Text
                      style={{
                        ...TextStyles.subtitle,
                        color: Colors.green,
                        paddingTop: 10,
                      }}
                    >
                      Total: ${total.toFixed(2)} USD
                    </Text>
                  </View>
                )}
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      marginTop: Sizes.medium,
                    }}
                  >
                    {extendDetailsVisible ? (
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Pressable
                          style={{ paddingRight: 8 }}
                          onPress={() => setExtendDetailsVisible(false)}
                        >
                          <FontAwesome
                            style={{ color: Colors.darkGrey }}
                            size={30}
                            name={"chevron-circle-left"}
                          ></FontAwesome>
                        </Pressable>
                        <AppButton
                          text="Confirm"
                          color={Colors.red}
                          onPress={() => onPressConfirm()}
                        ></AppButton>
                        <AppGap size={30}></AppGap>
                      </View>
                    ) : (
                      <View>
                        <AppButton
                          text="Extend"
                          onPress={() => setExtendDetailsVisible(true)}
                          color={Colors.red}
                        ></AppButton>
                      </View>
                    )}
                  </View>
                </View>
              </>
            )}
          </View>
        </AppCard>
      )}
      {isMobile && (
        <AppCard>
          {planExpired && (
            <View
              style={{
                backgroundColor: Colors.red,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                padding: 8,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
              }}
            >
              <Text
                style={{
                  fontSize: 16,
                  fontFamily: FontFamilys.regular,
                  color: "white",
                  paddingRight: 4,
                }}
              >
                Plan Expired |
              </Text>
              <Text
                style={{
                  fontFamily: FontFamilys.semiBold,
                  letterSpacing: 0.28,
                  fontSize: 18,
                  color: "white",
                }}
              >
                {props.endDate.toLocaleDateString("en-US")}
              </Text>
            </View>
          )}
          {!planExpired && (
            <View
              style={{
                backgroundColor: Colors.black,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                padding: 8,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
              }}
            >
              <Text
                style={{
                  ...TextStyles.paragraph,
                  color: "white",
                  paddingRight: 4,
                }}
              >
                Expiration Date:
              </Text>
              <Text style={{ ...TextStyles.semiSubtitle, color: "white" }}>
                {props.endDate.toLocaleDateString("en-US")}
              </Text>
            </View>
          )}
          {planExpired &&
          !extendDetailsVisible &&
          !props.isLessThan30DaysPlan ? (
            <View style={{ ...CommonStyles.card }}>
              <Text
                style={{ ...TextStyles.paragraph, marginBottom: Sizes.medium }}
              >
                You are able to retrieve your phone number up to 90 days after
                your term expiration date.
              </Text>
              <Text
                style={{ ...TextStyles.paragraph, marginBottom: Sizes.medium }}
              >
                There is no reactivation fee within the first 30 days after the
                expiration.
              </Text>
              <Text
                style={{ ...TextStyles.paragraph, marginBottom: Sizes.medium }}
              >
                After 30 days, the reactivation fee will be USD 10 on top of
                your plan fee.
              </Text>
            </View>
          ) : (
            <View style={{ ...CommonStyles.card }}>
              {planExpired && (
                <Text
                  style={{
                    ...TextStyles.paragraph,
                    marginBottom: Sizes.medium,
                    fontFamily: FontFamilys.bold,
                  }}
                >
                  Your plan will be reactivated immediately after the successful
                  payment.
                </Text>
              )}
              {!planExpired && (
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <View style={{ flex: 1 }}>
                    <AppTextThumbnail
                      width={82}
                      height={60}
                      title={getDaysRemaining(
                        new Date(),
                        props.endDate
                      ).toFixed(0)}
                      subtitle="Days Left"
                    ></AppTextThumbnail>
                  </View>
                  <View style={{ flexShrink: 1, flex: 2 }}>
                    <Text style={{ ...TextStyles.paragraph }}>
                      Current Term End Date:
                    </Text>
                    <Text style={{ ...TextStyles.paragraph }}>
                      <AppBoldText>{endDateForDisplay}</AppBoldText>
                    </Text>
                    {/* <View style={{ flexDirection: 'row' }}>
                        <Text style={{ fontFamily: FontFamilys.bold }}>Auto Extension: </Text>
                        <Text style={{ fontFamily: FontFamilys.regular, color: user.selectedAccount.customer_info.auto_payment ? Colors.green : Colors.red }}>{user.selectedAccount.customer_info.auto_payment ? 'enabled' : 'disabled'}</Text>
                      </View> */}
                    {planHasBeenChanged && (
                      <View
                        style={{ marginTop: 10, marginBottom: Sizes.medium }}
                      >
                        <Text
                          style={{
                            ...TextStyles.semiSubtitle,
                            color: Colors.blue,
                          }}
                        >
                          You Have Requested a Plan Change
                        </Text>
                        <Text
                          style={{
                            ...TextStyles.paragraph,
                            color: Colors.blue,
                          }}
                        >
                          Your plan will be changed to {futurePlanName} on{" "}
                          <Text style={{ fontWeight: "600" }}>
                            {endDateForDisplay}
                          </Text>
                        </Text>
                      </View>
                    )}
                    {user.selectedAccount.customer_info.auto_payment &&
                      !planHasBeenChanged && (
                        <View
                          style={{ marginTop: 10, marginBottom: Sizes.medium }}
                        >
                          <Text
                            style={{
                              ...TextStyles.semiSubtitle,
                              color: Colors.blue,
                            }}
                          >
                            You Have Set Up Auto Extension
                          </Text>
                          <Text
                            style={{
                              ...TextStyles.paragraph,
                              color: Colors.blue,
                            }}
                          >
                            You can cancel your auto extension on the profile
                            page.
                          </Text>
                        </View>
                      )}
                    {!user.selectedAccount.customer_info.auto_payment &&
                      !props.allowExtension &&
                      !planHasBeenChanged && (
                        <View style={{ marginTop: 10 }}>
                          <Text
                            style={{
                              ...TextStyles.semiSubtitle,
                              color: Colors.blue,
                            }}
                          >
                            You Have Already Extended Your Plan
                          </Text>
                          <Text
                            style={{
                              ...TextStyles.paragraph,
                              color: Colors.blue,
                            }}
                          >
                            You can only extend your services once per billing
                            cycle.
                          </Text>
                        </View>
                      )}
                  </View>
                </View>
              )}
              {!user.selectedAccount.customer_info.auto_payment &&
                props.allowExtension &&
                !props.isLessThan30DaysPlan && (
                  <>
                    <View>
                      <Text
                        style={{
                          ...TextStyles.paragraph,
                          color: Colors.darkGrey,
                          padding: 8,
                        }}
                      >
                        *You are able to retrieve your phone number up to 90
                        days after your term expiration date (term end date).
                        There is no reactivation fee within the first 30 days
                        after the expiration. After 30 days, the reactivation
                        fee (service charge) will be USD 10 on top of your plan
                        fee.
                      </Text>
                    </View>
                    {extendDetailsVisible && (
                      <View style={{ alignItems: "center" }}>
                        <Text
                          style={{
                            ...TextStyles.subtitle,
                            color: Colors.green,
                            paddingBottom: 10,
                          }}
                        >
                          New Expiration Date:{" "}
                          {newEndDate.toLocaleDateString("en-US")}
                        </Text>
                        <View style={{ flex: 1, flexDirection: "row" }}>
                          <Text>Plan Fee:</Text>
                          <Text
                            style={{
                              ...TextStyles.semiSubtitle,
                              paddingLeft: 4,
                            }}
                          >
                            ${props.price.toFixed(2)}
                          </Text>
                        </View>
                        {extraFee > 0 && (
                          <View style={{ flex: 1, flexDirection: "row" }}>
                            <Text>Reactivation Fee:</Text>
                            <Text
                              style={{
                                ...TextStyles.semiSubtitle,
                                paddingLeft: 4,
                              }}
                            >
                              ${extraFee.toFixed(2)}
                            </Text>
                          </View>
                        )}
                        <View style={{ flex: 1, flexDirection: "row" }}>
                          <Text>Taxes & Surcharges:</Text>
                          <Text
                            style={{
                              ...TextStyles.semiSubtitle,
                              paddingLeft: 4,
                            }}
                          >
                            ${taxes.toFixed(2)}
                          </Text>
                        </View>
                        <Text
                          style={{
                            ...TextStyles.subtitle,
                            color: Colors.green,
                            paddingTop: 10,
                          }}
                        >
                          Total: ${total.toFixed(2)} USD
                        </Text>
                      </View>
                    )}
                  </>
                )}
            </View>
          )}
        </AppCard>
      )}
    </>
  );
}
