import { useState } from 'react';
import { ScrollView, View, Text } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { AppAlert } from '../components/AppAlert';
import { AppButton } from '../components/AppButton';
import Colors from '../constants/Colors';
import FontFamilys from '../constants/FontFamilys';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import { profileService } from '../services/ProfileService';
import { tokenService } from '../services/TokenService';
import { AppDispatch, RootState } from '../store';
import { setLoggedIn } from '../store/user';
import { RootStackScreenProps } from '../types';

export default function DeleteMyAccountScreen({ }: RootStackScreenProps<'DeleteMyAccount'>) {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch<AppDispatch>();
  const handleLogout = () => {
    const logout = async () => {
      await tokenService.deleteAccessTokenAsync();
      await tokenService.deleteAccessTokenExpiryAsync();
      await tokenService.deleteRefreshTokenAsync();
      await tokenService.deleteRefreshTokenExpiryAsync();
      dispatch(setLoggedIn(false));
    }
    logout().catch(err => console.error(err));
  };

  const [deleteAccountError, setDeleteAccountError] = useState('');
  const [deletingAccount, setDeletingAccount] = useState(false);

  const deleteAccount = async () => {
    setDeleteAccountError('');
    setDeletingAccount(true);
    var response = await profileService.deleteAccount({ data: { customer_id: user.selectedAccount.customer_info.customer_id } });
    if (!response || !response.success) {
      setDeleteAccountError(response.error.message);
      setDeletingAccount(false);
      console.error(response.error);
      return;
    }
    handleLogout();
    setDeleteAccountError('');
    setDeletingAccount(false);
  };

  return (
    <ScrollView>
      <View style={{ ...PageLayoutStyles.container, paddingTop: Sizes.none }}>
        <Text style={{ ...TextStyles.title, color: Colors.white }}>Delete My Account</Text>
        <Text style={{ ...TextStyles.subtitle, fontFamily: FontFamilys.regular, color: Colors.white, marginTop: Sizes.large }}>Are you sure you want to delete your account?</Text>
        <Text style={{ ...TextStyles.subtitle, fontFamily: FontFamilys.regular, color: Colors.white, marginTop: Sizes.large }}>This action will also cancel any auto payment you have set up and remove any saved credit card information. You will need to set up a new account if you wish to use our services in the future.</Text>
        <Text style={{ ...TextStyles.subtitle, fontFamily: FontFamilys.regular, color: Colors.white, marginTop: Sizes.large }}>Please note that removing your account will not refund any unused balances or payments. If you have any concerns or questions, please contact our customer support team for assistance.</Text>
        <Text style={{ ...TextStyles.subtitle, fontFamily: FontFamilys.regular, color: Colors.white, marginTop: Sizes.large }}>This action is irreversible and all of your data will be deleted from our system.</Text>
        <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: Sizes.large }}>
          <AppButton text="Confirm" color={Colors.red} onPress={deleteAccount} loading={deletingAccount}></AppButton>
        </View>
        {deleteAccountError.length > 0 &&
          <View style={{ marginTop: Sizes.medium }}>
            <AppAlert text={deleteAccountError}></AppAlert>
          </View>
        }
      </View>
    </ScrollView>
  );
}
