import { useRoute } from '@react-navigation/native';
import { useEffect, useState } from 'react';
import { ScrollView, View, Text } from 'react-native';
import { AppButton } from '../components/AppButton';
import { AppColumn } from '../components/AppColumn';
import { AppContainer } from '../components/AppContainer';
import { AppFooter } from '../components/AppFooter';
import { AppGap } from '../components/AppGap';
import { AppHeader } from '../components/AppHeader';
import { AppRow } from '../components/AppRow';
import { Loading } from '../components/Loading';
import Colors from '../constants/Colors';
import CommonStyles from '../constants/CommonStyles';
import FontFamilys from '../constants/FontFamilys';
import Heights from '../constants/Heights';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import useIsMobile from '../hooks/useIsMobile';
import { signUpService } from '../services/SignUpService';
import { RootStackScreenProps } from '../types';
import { ApiErrorCodes } from '../types/ApiErrorCodes';

export default function VerifyAccountScreen({ navigation }: RootStackScreenProps<'VerifyAccount'>) {
  const isMobile = useIsMobile();
  const route = useRoute();
  const [loading, setLoading] = useState(true);
  const [accountVerificationTokenExpired, setAccountVerificationTokenExpired] = useState(false);
  const [accountVerificationTokenExpiredError, setAccountVerificationTokenExpiredError] = useState('');

  useEffect(() => {
    const verifyAccount = async () => {
      const params = route.params as any;
      if (!params || !params.token) {
        setTimeout(() => {
          navigation.replace('Landing');
        });
        return;
      }

      const response = await signUpService.verifyAccount({ data: { token: params.token } });
      if (!response.success) {
        if (+response.error.code === ApiErrorCodes.AccountVerificationTokenExpired) {
          setAccountVerificationTokenExpired(true);
          setAccountVerificationTokenExpiredError(response.error.message);
        } else {
          setTimeout(() => {
            navigation.replace('Landing');
          });
          return;
        }
      }

      setLoading(false);
    }

    verifyAccount().catch(err => err.console);
  }, []);

  if (loading) {
    return <Loading></Loading>
  }
  return (
    <>
      {!isMobile &&
        <>
          <AppHeader onlyLogo></AppHeader>
          <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.scrollViewPaddingTop }}>
            <View style={{ ...PageLayoutStyles.container }}>
              <View style={{ ...CommonStyles.card, padding: 0, borderColor: Colors.green, borderWidth: 2, maxWidth: 350, margin: 'auto' }}>
                <AppContainer>
                  <AppRow>
                    <AppColumn>
                      <View style={{ alignItems: 'center', justifyContent: 'space-between', padding: Sizes.extraLarge, flex: 1 }}>
                        {!accountVerificationTokenExpired &&
                          <>
                            <Text style={{ color: Colors.black, fontFamily: FontFamilys.bold, fontSize: 30 }}>Account Verified</Text>
                            <AppGap direction="vertical" size={10}></AppGap>
                            <Text style={{ ...TextStyles.paragraph, textAlign: 'center' }}>You can now sign in to your account.</Text>
                            <AppGap direction="vertical" size={20}></AppGap>
                            <AppButton text='Sign In' onPress={() => navigation.replace('Landing')}></AppButton>
                          </>
                        }
                        {accountVerificationTokenExpired &&
                          <>
                            <Text style={{ color: Colors.black, fontFamily: FontFamilys.bold, fontSize: 30 }}>Verify Account</Text>
                            <AppGap direction="vertical" size={15}></AppGap>
                            <Text style={{ ...TextStyles.paragraph, textAlign: 'center' }}>{accountVerificationTokenExpiredError}</Text>
                            <AppGap direction="vertical" size={15}></AppGap>
                            <AppButton text='Go to Sign In' onPress={() => navigation.replace('Landing')}></AppButton>
                          </>
                        }
                      </View>
                    </AppColumn>
                  </AppRow>
                </AppContainer>
              </View>
            </View>
          </ScrollView>
          <AppFooter></AppFooter>
        </>
      }
      {isMobile &&
        <ScrollView>
          <View style={{ ...PageLayoutStyles.container, justifyContent: 'center', alignItems: 'center' }}>
            {!accountVerificationTokenExpired &&
              <>
                <AppGap direction="vertical" size={40}></AppGap>
                <AppGap direction="vertical" size={20}></AppGap>
                <Text style={{ ...TextStyles.title, color: Colors.white, textAlign: 'center' }}>Account Verified</Text>
                <AppGap direction="vertical" size={20}></AppGap>
                <Text style={{ fontFamily: FontFamilys.regular, color: Colors.white, fontSize: 20, textAlign: 'center' }}>You can now sign in to your account.</Text>
                <AppGap direction="vertical" size={20}></AppGap>
                <AppButton text='Sign In' onPress={() => navigation.replace('Landing')}></AppButton>
              </>
            }
            {accountVerificationTokenExpired &&
              <>
                <AppGap direction="vertical" size={40}></AppGap>
                <AppGap direction="vertical" size={20}></AppGap>
                <Text style={{ ...TextStyles.title, color: Colors.white, textAlign: 'center' }}>Verify Account</Text>
                <AppGap direction="vertical" size={20}></AppGap>
                <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.regular, color: Colors.white, fontSize: 17, textAlign: 'center' }}>{accountVerificationTokenExpiredError}</Text>
                <AppGap direction="vertical" size={20}></AppGap>
                <AppButton text='Go to Sign In' onPress={() => navigation.replace('Landing')}></AppButton>
              </>
            }
          </View>
        </ScrollView>
      }
    </>
  );
}
