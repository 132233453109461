import { StyleSheet, View, Text } from 'react-native';
import Colors from '../constants/Colors';
import FontFamilys from '../constants/FontFamilys';

type AppCurrencyPillProps = {
  text: string;
}

export function AppCurrencyPill(props: AppCurrencyPillProps) {
  return (
    <View style={styles.pillContainer}>
      <Text style={styles.pill}>{props.text}</Text>
    </View>
  );
}
const styles = StyleSheet.create({
  pillContainer: {
    backgroundColor: Colors.black,
    textTransform: 'uppercase',
    borderRadius: 30,
    height: 16,
    justifyContent: 'center',
    alignItems: 'center'
  },
  pill: {
    color: Colors.white,
    fontFamily: FontFamilys.semiBold,
    paddingHorizontal: 7,
    paddingVertical: 1,
    fontSize: 8
  }
});