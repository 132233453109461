import instance from "../api/axios";
import { ApiRequest } from "../types/requests/ApiRequest";
import { ApiResponse } from "../types/responses/ApiResponse";
import { SignInRequest } from "../types/requests/SignInRequest";
import { SignInResponse } from "../types/responses/SignInResponse";
import { ForgotPasswordRequest } from "../types/requests/ForgotPasswordRequest";
import { ForgotPasswordResponse } from "../types/responses/ForgotPasswordResponse";
import { ValidatePasswordResetTokenRequest } from "../types/requests/ValidatePasswordResetTokenRequest";
import { ValidatePasswordResetTokenResponse } from "../types/responses/ValidatePasswordResetTokenResponse";
import { ResetPasswordRequest } from "../types/requests/ResetPasswordRequest";
import { ResetPasswordResponse } from "../types/responses/ResetPasswordResponse";
import { RefreshRequest } from "../types/requests/RefreshRequest";
import { RefreshResponse } from "../types/responses/RefreshResponse";

export const authService = {
    signIn: async (request: ApiRequest<SignInRequest>): Promise<ApiResponse<SignInResponse>> => await instance.post<ApiRequest<SignInRequest>, ApiResponse<SignInResponse>>('/auth/signin', request),
    forgotPassword: async (request: ApiRequest<ForgotPasswordRequest>): Promise<ApiResponse<ForgotPasswordResponse>> => await instance.post<ApiRequest<ForgotPasswordRequest>, ApiResponse<ForgotPasswordResponse>>('/auth/forgot_password', request),
    validatePasswordResetToken: async (request: ApiRequest<ValidatePasswordResetTokenRequest>): Promise<ApiResponse<ValidatePasswordResetTokenResponse>> => await instance.post<ApiRequest<ValidatePasswordResetTokenRequest>, ApiResponse<ValidatePasswordResetTokenResponse>>('/auth/validate_password_reset_token', request),
    resetPassword: async (request: ApiRequest<ResetPasswordRequest>): Promise<ApiResponse<ResetPasswordResponse>> => await instance.post<ApiRequest<ResetPasswordRequest>, ApiResponse<ResetPasswordResponse>>('/auth/reset_password', request),
    refresh: async (request: ApiRequest<RefreshRequest>): Promise<ApiResponse<RefreshResponse>> => await instance.post<ApiRequest<RefreshRequest>, ApiResponse<RefreshResponse>>('/auth/refresh', request),
};