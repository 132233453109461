import { FontAwesome } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator, NativeStackNavigationOptions } from '@react-navigation/native-stack';
import { useSelector, useDispatch } from 'react-redux';
import Colors from '../constants/Colors';
import AddDataScreen from '../screens/AddDataScreen';
import AddNewCardScreen from '../screens/AddNewCardScreen';
import ChangeYourPlanScreen from '../screens/ChangeYourPlanScreen';
import CheckYourEmailScreen from '../screens/CheckYourEmailScreen';
import ExtendYourPlanScreen from '../screens/ExtendYourPlanScreen';
import ForgotPasswordScreen from '../screens/ForgotPasswordScreen';
import HomeScreen from '../screens/HomeScreen';
import LandingScreen from '../screens/LandingScreen';
import MakeAPaymentScreen from '../screens/MakeAPaymentScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import NotificationsScreen from '../screens/NotificationsScreen';
import PlanChangedScreen from '../screens/PlanChangedScreen';
import PrivacyPolicyScreen from '../screens/PrivacyPolicyScreen';
import ProfileScreen from '../screens/ProfileScreen';
import ProfileUpdateEmailScreen from '../screens/ProfileUpdateEmailScreen';
import ProfileUpdateFirstNameScreen from '../screens/ProfileUpdateFirstNameScreen';
import ProfileUpdateLastNameScreen from '../screens/ProfileUpdateLastNameScreen';
import ProfileUpdatePasswordScreen from '../screens/ProfileUpdatePasswordScreen';
import SignInScreen from '../screens/SignInScreen';
import SignUpScreen from '../screens/SignUpScreen';
import TermsAndConditionsScreen from '../screens/TermsAndConditionsScreen';
import { tokenService } from '../services/TokenService';
import { authService } from '../services/AuthService';
import { RootState, AppDispatch } from '../store';
import { setAccounts, setLoggedIn, setSelectedAccount, setDeactivated } from '../store/user';
import { RootStackParamList, RootTabParamList } from '../types';
import LinkingConfiguration from './LinkingConfiguration';
import { Loading } from '../components/Loading';
import { useEffect, useState } from 'react';
import { profileService } from '../services/ProfileService';
import ChangePasswordScreen from '../screens/ChangePasswordScreen';
import VerifyAccountScreen from '../screens/VerifyAccountScreen';
import useIsMobile from '../hooks/useIsMobile';
import MoreInfoScreen from '../screens/MoreInfoScreen';
import DeleteMyAccountScreen from '../screens/DeleteMyAccountScreen';
import InternationalCallingScreen from '../screens/InternationalCallingScreen';
import SignupSuccessScreen from '../screens/SignupSuccessScreen';
import PaymentResultScreen from '../screens/PaymentResultScreen';

export default function Navigation() {
  return (
    <NavigationContainer linking={LinkingConfiguration}>
      <RootNavigator />
    </NavigationContainer>
  );
}

const pageOptions = { headerTintColor: Colors.white, headerStyle: { backgroundColor: Colors.green }, headerShadowVisible: false, title: '' } as NativeStackNavigationOptions;

const Stack = createNativeStackNavigator<RootStackParamList>();
function RootNavigator() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(setDeactivated(false));
    const checkAccessToken = async () => {
      const token = await tokenService.getAccessTokenAsync();
      const refreshToken = await tokenService.getRefreshTokenAsync();
      if (!token) {
        console.log('Token not found.');
        setLoading(false);
        return;
      }

      const now = new Date();
      const tokenExpiry = await tokenService.getAccessTokenExpiryAsync();
      const refreshTokenExpiry = await tokenService.getRefreshTokenExpiryAsync();
      
      if (tokenExpiry && now > new Date(tokenExpiry)) {
        console.log(`Token NOT Found.`);
        console.log(`Token Expires At ${tokenExpiry}.`);
        console.log(`Time Now Is: ${now.toISOString()}.`);

        if (refreshTokenExpiry && now < new Date(refreshTokenExpiry)) {
          console.log(`Refreshing...`);
          var response = await authService.refresh({ data: { access_token: token, refresh_token: refreshToken }})
  
          if (!response || !response.success) {
            console.log(`Refresh FAILED.`);
            setLoading(false);
            console.error(response.error);
            await tokenService.deleteAccessTokenAsync();
            await tokenService.deleteAccessTokenExpiryAsync();
            await tokenService.deleteRefreshTokenAsync();
            await tokenService.deleteRefreshTokenExpiryAsync();
            return;
          }
      
          if (response && response.success) {
            await tokenService.setAccessTokenAsync(response.data.access_token);
            await tokenService.setAccessTokenExpiryAsync(new Date(response.data.access_token_expiry));
            console.log(`Refresh succeeded!`);
          }

        } else {
          await tokenService.deleteAccessTokenAsync();
          await tokenService.deleteAccessTokenExpiryAsync();
          await tokenService.deleteRefreshTokenAsync();
          await tokenService.deleteRefreshTokenExpiryAsync();
          setLoading(false);
          return;
        }

        // await tokenService.deleteAccessTokenAsync();
        // await tokenService.deleteAccessTokenExpiryAsync();
        // setLoading(false);
        // return;
      }
      console.log(`Token Found.`);
      console.log(`Token Expires At: ${tokenExpiry}`);
      console.log(`Refresh Token Expires At: ${refreshTokenExpiry}`);
      console.log(`Time Now Is: ${now.toISOString()}`);
      setLoading(false);
      dispatch(setLoggedIn(true));
    }
    // checkAccessToken().catch(err => console.log(err));
    // TODO: Remove delay and uncomment line above before production. Testing loading screen while checking for access token
    checkAccessToken().catch(err => console.log(err));
  }, [])

  const { loggedIn } = useSelector((state: RootState) => state.user);
  const isMobile = useIsMobile();
  const user = useSelector((state: RootState) => state.user);

  if (loading) {
    return <Loading />
  }

  return (
    <Stack.Navigator screenOptions={{ title: isMobile ? '' : 'PhoneBox', contentStyle: { backgroundColor: isMobile ? Colors.green : Colors.offwhite } }}>
      {!loggedIn &&
        <>
          <Stack.Screen name="Landing" component={LandingScreen} options={{ headerShown: false, title: isMobile ? '' : 'PhoneBox - Welcome' }} />
          <Stack.Screen name="SignIn" component={SignInScreen} options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Sign In' }} />
          <Stack.Screen name="SignUp" component={SignUpScreen} options={{ ...pageOptions, headerShown: isMobile, title: isMobile ? '' : 'PhoneBox - Sign Up' }} />
          <Stack.Screen name="ForgotPassword" component={ForgotPasswordScreen} options={{ ...pageOptions, headerShown: isMobile, title: isMobile ? '' : 'PhoneBox - Forgot Password' }} />
          <Stack.Screen name="CheckYourEmail" component={CheckYourEmailScreen} options={{ ...pageOptions, headerShown: false, title: isMobile ? '' : 'PhoneBox - Check Your Email' }} />
          <Stack.Screen name="ChangePassword" component={ChangePasswordScreen} options={{ ...pageOptions, headerShown: false, title: isMobile ? '' : 'PhoneBox - Change Your Password' }} />
          <Stack.Screen name="VerifyAccount" component={VerifyAccountScreen} options={{ ...pageOptions, headerShown: false, title: isMobile ? '' : 'PhoneBox - Verify Your Account' }} />
          <Stack.Screen name="SignupSuccess" component={SignupSuccessScreen} options={{ ...pageOptions, headerShown: false, title: isMobile ? '' : 'PhoneBox - Sign Up Success' }} />
        </>
      }
      {loggedIn &&
        <>
          <Stack.Screen name="Root" component={BottomTabNavigator} options={{ headerShown: false, title: isMobile ? '' : 'PhoneBox - Dashboard' }} />
          <Stack.Screen name="AddData" component={AddDataScreen} options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Add Data' }} />
          <Stack.Screen name="AddNewCard" component={AddNewCardScreen} options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Add New Card' }} />
          <Stack.Screen name="ChangeYourPlan" component={ChangeYourPlanScreen} options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Change Your Plan' }} />
          <Stack.Screen name="ExtendYourPlan" component={ExtendYourPlanScreen} options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Extend Your Plan' }} />
          <Stack.Screen name="MakeAPayment" component={MakeAPaymentScreen} options={{ ...pageOptions, headerShown: isMobile, title: isMobile ? '' : 'PhoneBox - Make A Payment' }} />
          <Stack.Screen name="Notifications" component={NotificationsScreen} options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Notifications' }} />
          <Stack.Screen name="PlanChanged" component={PlanChangedScreen} options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Plan Changed' }} />
          <Stack.Screen name="ProfileUpdateFirstName" component={ProfileUpdateFirstNameScreen} options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Profile' }} />
          <Stack.Screen name="ProfileUpdateLastName" component={ProfileUpdateLastNameScreen} options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Profile' }} />
          <Stack.Screen name="ProfileUpdateEmail" component={ProfileUpdateEmailScreen} options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Profile' }} />
          <Stack.Screen name="ProfileUpdatePassword" component={ProfileUpdatePasswordScreen} options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Profile' }} />
          <Stack.Screen name="MoreInfo" component={MoreInfoScreen} options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - More Info' }} />
          <Stack.Screen name="DeleteMyAccount" component={DeleteMyAccountScreen} options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Delete My Account' }} />
          <Stack.Screen name="InternationalCalling" component={InternationalCallingScreen} options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - International Calling' }} />
          {user.xdrId !== 0 && <Stack.Screen name="PaymentResult" component={PaymentResultScreen} options={{ ...pageOptions, headerShown: isMobile, title: isMobile ? '' : 'PhoneBox - Payment Result' }} />}
        </>
      }
      <Stack.Screen name="TermsAndConditions" component={TermsAndConditionsScreen} options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Terms & Conditions', contentStyle: { backgroundColor: Colors.white }, headerTintColor: Colors.black, headerStyle: { backgroundColor: Colors.white } }} />
      <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicyScreen} options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Privacy Policy', contentStyle: { backgroundColor: Colors.white }, headerTintColor: Colors.black, headerStyle: { backgroundColor: Colors.white } }} />
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ ...pageOptions, headerShown: false, title: isMobile ? '' : 'PhoneBox - Not Found' }} />
    </Stack.Navigator>
  );
}
 
const BottomTab = createBottomTabNavigator<RootTabParamList>();
function BottomTabNavigator() {
  const isMobile = useIsMobile();
  const user = useSelector((state: RootState) => state.user);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    const getAccounts = async () => {
      const accountInfoResponse = await profileService.getAccountInfo({ data: {} })
       if (accountInfoResponse.data === null) {
        await tokenService.deleteAccessTokenAsync();
        await tokenService.deleteAccessTokenExpiryAsync();
        await tokenService.deleteRefreshTokenAsync();
        await tokenService.deleteRefreshTokenExpiryAsync();
        dispatch(setLoggedIn(false));
        dispatch(setDeactivated(true));
        setLoading(false);
        console.error(accountInfoResponse.error);
        return;
      } else if (!accountInfoResponse || !accountInfoResponse.success || accountInfoResponse.data.accounts.length === 0) {
        await tokenService.deleteAccessTokenAsync();
        await tokenService.deleteAccessTokenExpiryAsync();
        await tokenService.deleteRefreshTokenAsync();
        await tokenService.deleteRefreshTokenExpiryAsync();
        dispatch(setLoggedIn(false));
        setLoading(false);
        console.error(accountInfoResponse.error);
        return;
      }

      // TODO: In the future we can support multiple accounts/plans
      // For now just grab the first one
      const account = accountInfoResponse.data.accounts[0];
      dispatch(setAccounts(accountInfoResponse.data.accounts));
      dispatch(setSelectedAccount(account));
      dispatch(setDeactivated(false));
      setLoading(false);
    }
    getAccounts().catch(err => console.error(err));
  }, [])

  if (loading || user.accounts.length === 0 || !user.selectedAccount || !user.selectedAccount.id) {
    return <Loading></Loading>;
  }

  return (
    <BottomTab.Navigator
      initialRouteName="Home"
      screenOptions={{ tabBarActiveTintColor: Colors.green, tabBarShowLabel: true, headerShown: false, }}>
      <BottomTab.Screen name="Home" component={HomeScreen} options={{ tabBarIcon: ({ color }) => <TabBarIcon name="home" color={color} />, tabBarStyle: { display: isMobile ? 'flex' : 'none' }, title: isMobile ? 'Home' : 'PhoneBox - Home' }} />
      <BottomTab.Screen name="Profile" component={ProfileScreen} options={{ tabBarIcon: ({ color }) => <TabBarIcon name="user-circle" color={color} />, tabBarStyle: { display: isMobile ? 'flex' : 'none' }, title: isMobile ? 'Profile' : 'PhoneBox - Profile' }} />
    </BottomTab.Navigator>
  );
}

type TabBarIconProps = {
  name: React.ComponentProps<typeof FontAwesome>['name'];
  color: string;
}

function TabBarIcon(props: TabBarIconProps) {
  return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />;
}
