import { useState } from 'react';
import { ScrollView, View, Text } from 'react-native';
import { AppButton } from '../components/AppButton';
import { AppTextInput } from '../components/AppTextInput';
import Colors from '../constants/Colors';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import Sizes from '../constants/Sizes';
import Heights from '../constants/Heights';
import TextStyles from '../constants/TextStyles';
import { RootStackScreenProps } from '../types';
import React from 'react';
import { authService } from '../services/AuthService';
import { setLoggedIn } from '../store/user';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import { tokenService } from '../services/TokenService';
import { AppAlert } from '../components/AppAlert';
import { AppLink } from '../components/AppLink';


export default function SignInScreen({ navigation }: RootStackScreenProps<'SignIn'>) {
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const [logInError, setLogInError] = useState('');

  const dispatch = useDispatch<AppDispatch>();

  const handleLogin = async () => {
    setLogInError('');
    setLoggingIn(true);
    var response = await authService.signIn({ data: { email: emailAddress, password: password } });

    if (!response || !response.success) {
      setLoggingIn(false);
      setLogInError(response.error.message);
      console.error(response.error);
      return;
    }

    if (response && response.success) {
      console.log(response.data);
      await tokenService.setAccessTokenAsync(response.data.access_token);
      await tokenService.setAccessTokenExpiryAsync(new Date(response.data.access_token_expiry));
      await tokenService.setRefreshTokenAsync(response.data.refresh_token);
      await tokenService.setRefreshTokenExpiryAsync(new Date(response.data.refresh_token_expiry));
      dispatch(setLoggedIn(true));
      setLoggingIn(false);
      setLogInError('');
    }
  }

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}>
      <View style={{ ...PageLayoutStyles.container, paddingTop: Sizes.none, paddingBottom: Heights.signUpViewPaddingBottom }}>
        <Text style={{ ...TextStyles.title, color: Colors.white }}>Sign In</Text>
        <View style={{ marginTop: Sizes.large }}>
          <AppTextInput onChangeText={(text) => setEmailAddress(text.trim())} placeholder="Username or Email"></AppTextInput>
        </View>
        <View style={{ marginTop: Sizes.large }}>
          <AppTextInput onChangeText={(text) => setPassword(text)} placeholder="Password" secureTextEntry></AppTextInput>
        </View>
        {/* <AppSwitch onToggle={(toggled) => setRememberMe(toggled)} text="Remember Me"></AppSwitch> */}
        <View style={{ marginBottom: Sizes.medium, marginTop: Sizes.large }}>
          <AppButton text="Sign In" onPress={handleLogin} loading={loggingIn}></AppButton>
        </View>
        {logInError.length > 0 && (
          <View style={{ marginVertical: Sizes.medium }}>
            <AppAlert text={logInError}></AppAlert>
          </View>
        )}
        <AppLink text="Forgot your password?" onPress={() => navigation.navigate("ForgotPassword")} center></AppLink>
      </View>
    </ScrollView>
  );
}
